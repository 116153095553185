import { Box, SxProps, Theme, Typography } from '@mui/material'
import styles from './styles'
import LandingPageButton from '../LandingPage/LandingPageButton'
import TextInput from '../../components/TextInput'
import { useContactForm, useDisableStates } from './ContactForm.hooks'
import Recorder from '../Recorder'

export interface IContactFormProps {
    sx?: SxProps<Theme>
    withoutEmail?: boolean
    recordable?: boolean
}

const ContactForm = ({ sx, withoutEmail, recordable }: IContactFormProps): JSX.Element => {
    const { buttonStyles, onSubmit, isLoading, onTranscription, email, setEmail, message, setMessage } =
        useContactForm(withoutEmail)
    const { isRecording, setIsRecording, isProcessingAudio, setIsProcessingAudio } = useDisableStates()

    return (
        <Box sx={{ ...styles.container, ...sx } as SxProps<Theme>}>
            {withoutEmail !== true && <Typography>{'Email'}</Typography>}
            {withoutEmail !== true && (
                <TextInput value={email} setvalue={setEmail} sx={styles.input} placeholder={'example@gmail.com'} />
            )}
            <Typography>{'Message'}</Typography>
            <Box sx={styles.messageRow}>
                {recordable && (
                    <Recorder
                        onTranscription={onTranscription}
                        setLoadingState={setIsProcessingAudio}
                        setRecordingState={setIsRecording}
                    />
                )}
                <TextInput
                    value={message}
                    setvalue={setMessage}
                    multiline={true}
                    sx={{ ...styles.input, ...styles.largeInput } as SxProps<Theme>}
                    placeholder={'Your message...'}
                    disabled={isRecording || isProcessingAudio}
                />
            </Box>
            <LandingPageButton
                id={'send-contact-form'}
                text={'Send'}
                loading={isLoading}
                containerOverrides={buttonStyles}
                onClick={onSubmit}
            />
        </Box>
    )
}

export default ContactForm
