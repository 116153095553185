import { useEffect, useMemo, useState } from 'react'
import { useLandingPageContext } from '../../../pages/LandingPage/LandingPage.hooks'
import { scrollThreshold, tabTextGap } from './LandingPageNavbar.data'
import styles from './styles'
import { SxProps, Theme } from '@mui/material'
import useIsMobile from '../../../hooks/useIsMobile'
import { BLUE_GRAY } from '../../../utils/constants'
import { useNavbarStyles } from '../Navbar.hooks'
import useIsSmallDesktop from '../../../hooks/useIsSmallDesktop'

const useLandingPageNavbar = () => {
    const isMobile = useIsMobile()
    const { isSmallDesktop } = useIsSmallDesktop()
    const { navbarRef, currentlySelectedTab, onTabClick, setShowSidebar } = useLandingPageContext()
    const { containerStyle, placeholderStyles } = useNavbarStyles()

    const allowSidebar = useMemo(() => isMobile, [isMobile])

    return {
        navbarRef,
        currentlySelectedTab,
        onTabClick,
        allowSidebar,
        containerStyle: { ...containerStyle, backgroundColor: BLUE_GRAY },
        placeholderStyles: { ...placeholderStyles, backgroundColor: BLUE_GRAY },
        tabContainerStyles: {
            ...styles.tabContainer,
            gap: isSmallDesktop ? tabTextGap / 2 : tabTextGap
        } as SxProps<Theme>,
        setShowSidebar
    }
}

export default useLandingPageNavbar
