import { Box, Typography } from '@mui/material'
import { PracticeGoalOptionProps, borderRadius, getOptionDescription } from './PracticeGoalOption.data'
import styles from './styles'
import { DARK_GREEN, componentShadow } from '../../../utils/constants'

const PracticeGoalOption = ({ name, option, selected, borderType, onClick }: PracticeGoalOptionProps): JSX.Element => {
    const borderRadiusTop = borderType === 'top-rounded' ? borderRadius : 0
    const borderRadiusBottom = borderType === 'bottom-rounded' ? borderRadius : 0
    const borderBottom = borderType === 'bottom-rounded' ? 1 : 0
    const optionDescription = option === undefined ? '' : getOptionDescription(option)
    const boxShadow = borderType === 'bottom-rounded' ? componentShadow : undefined

    return (
        <Box
            sx={{
                ...styles.container,
                backgroundColor: selected ? DARK_GREEN : 'white',
                borderTopLeftRadius: borderRadiusTop,
                borderTopRightRadius: borderRadiusTop,
                borderBottomLeftRadius: borderRadiusBottom,
                borderBottomRightRadius: borderRadiusBottom,
                boxShadow,
                borderBottom
            }}
            onClick={onClick}
        >
            <Typography>{name}</Typography>
            <Typography>{optionDescription}</Typography>
        </Box>
    )
}

export default PracticeGoalOption
