import { Box, Typography } from '@mui/material'
import SpeakableButton from '../SpeakableButton'
import useNotAuthorizedComponent from './NotAuthorizedComponent.hooks'
import styles from './styles'
import Navbar from '../Navbar'
import { NavbarVariant } from '../Navbar/Navbar.data'

const NotAuthorizedComponent = (): JSX.Element => {
    const { onClick } = useNotAuthorizedComponent()

    return (
        <Box sx={styles.container}>
            <Navbar variant={NavbarVariant.LOGO} />
            <Box sx={styles.contentContainer}>
                <Typography
                    sx={styles.text}
                >{`Oops! The page that you're looking for requires you to be signed in.`}</Typography>
                <SpeakableButton
                    id={'not-authorized-redirect'}
                    text={'Return Home'}
                    onClick={onClick}
                    variant={'important'}
                />
            </Box>
        </Box>
    )
}

export default NotAuthorizedComponent
