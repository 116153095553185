import { Tab } from '../../../pages/LandingPage/LandingPage.data'

export type LinkName = Tab | 'Terms of Service' | 'Privacy Policy'

export const allLinks: LinkName[] = [
    'Product',
    'Features',
    'Story',
    'Pricing',
    'Contact',
    'Terms of Service',
    'Privacy Policy'
]
