import { ApiError, get, post } from 'aws-amplify/api'
import { CreateConversationResponse } from '@speakable/speakable-service-contracts/lib/analytics/conversation'
import { AddToSessionRequest, AddToSessionResponse } from '@speakable/speakable-service-contracts/lib/analytics/session'

export const addToSession = async (requestBody: AddToSessionRequest): Promise<AddToSessionResponse> => {
    try {
        const { response: promise } = post({
            apiName: 'analytics',
            path: '/session',
            options: { body: requestBody }
        })

        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as any
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as any
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}

export const createEvent = async (
    {
        eventId,
        eventType = 'click',
        time
    }: {
        eventId: string
        time: number
        eventType?: 'click' | 'load'
    },
    isPrivate = true
): Promise<AddToSessionResponse> => {
    try {
        const { response: promise } = post({
            apiName: 'analytics',
            path: isPrivate ? '/event' : '/event/public',
            options: { body: { eventId, time, eventType } }
        })

        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as any
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as any
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}

export const getSessionTime = async ({ startTime, endTime }: { startTime: number; endTime: number }): Promise<any> => {
    try {
        const { response: promise } = get({
            apiName: 'analytics',
            path: '/session/time',
            options: { queryParams: { startTime: startTime.toString(), endTime: endTime.toString() } }
        })

        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as any
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as any
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}
