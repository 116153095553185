import useIsMobile from '../../hooks/useIsMobile'
import useIsSmallDesktop from '../../hooks/useIsSmallDesktop'
import { desktopLandingPageSectionTopPadding, mobileLandingPageSectionTopPadding } from './data'
import styles from './styles'

const useLandingPageSectionStyling = () => {
    // const isMobile = useIsMobile()
    const { isSmallDesktop } = useIsSmallDesktop()

    const titleTextVariant: 'h2' | 'h3' = isSmallDesktop ? 'h3' : 'h2'
    const paragraphVariant: 'h4' | 'h5' = isSmallDesktop ? 'h5' : 'h4'
    const smallTextVariant: 'h5' | 'h6' = isSmallDesktop ? 'h6' : 'h5'

    const containerStyle = {
        ...styles.container,
        pt: desktopLandingPageSectionTopPadding * 2,
        pb: desktopLandingPageSectionTopPadding * 2,
        mt: desktopLandingPageSectionTopPadding,
        pl: isSmallDesktop ? '5%' : '10%',
        pr: isSmallDesktop ? '5%' : '10%'
    }

    return { titleTextVariant, paragraphVariant, containerStyle, smallTextVariant }
}

export default useLandingPageSectionStyling
