import { SxProps, Theme } from '@mui/material'

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 50,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    background: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'black',
        opacity: 0.5,
        zIndex: 51
    },
    child: {
        zIndex: 52
    }
}

export default styles
