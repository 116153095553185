import { SxProps, Theme } from '@mui/material'
import { DISABLED_GRAY, HOVER_GRAY, LIGHT_GREEN } from '../../utils/constants'

const sideLength = 60
const borderRadius = 2

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        border: 1,
        borderRadius,
        minWidth: sideLength,
        minHeight: sideLength,
        maxWidth: sideLength,
        maxHeight: sideLength,
        backgroundColor: 'white',
        p: 5,
        '&:hover': { cursor: 'pointer', backgroundColor: HOVER_GRAY }
    },
    image: {
        width: '80%',
        aspectRatio: 1,
        userSelect: 'none'
    },
    loading: {
        '&:hover': { cursor: 'default', backgroundColor: DISABLED_GRAY },
        backgroundColor: DISABLED_GRAY
    },
    disabled: {
        '&:hover': { cursor: 'default', backgroundColor: DISABLED_GRAY },
        backgroundColor: DISABLED_GRAY
    },
    popupContainer: {
        position: 'absolute',
        opacity: 0.95,
        '&:hover': { cursor: 'default' },
        width: 250,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bottom: '100%',
        left: '50%',
        translate: '-50% -10px',
        textAlign: 'center',
        backgroundColor: 'white',
        p: 5,
        borderRadius: 2,
        border: 1,
        zIndex: 3,
        userSelect: 'none'
    },
    popupText: {
        color: 'black',
        userSelect: 'none'
    }
}

export default styles
