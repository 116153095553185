import { SxProps, Theme } from '@mui/material'

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        border: 1,
        borderColor: 'lightgray',
        borderRadius: 2,
        p: 20,
        overflow: 'auto',
        width: '100%',
        gap: 20
    }
}

export default styles
