import { Box, Typography } from '@mui/material'
import styles from './styles'
import baseStyles from '../styles'
import GoogleButton from '../GoogleButton'
import useLoginComponent from './LoginComponent.hooks'
import LandingPageButton from '../../LandingPage/LandingPageButton'
import SwitchText from '../SwitchText'
import { SwitchTextType } from '../SwitchText/SwitchText.data'
import Field from '../Field/Field'
import { isValidEmail, isValidPassword } from '../data'

const LoginComponent = (): JSX.Element => {
    const { email, password, isComplete, isLoading, setEmail, setPassword, setIsLoading, onSignIn, onForgotPassword } =
        useLoginComponent()

    return (
        <Box sx={baseStyles.container}>
            <Box sx={baseStyles.titleContainer}>
                <Typography variant={'h3'} sx={baseStyles.titleText}>
                    {'Welcome Back'}
                </Typography>
                <Typography variant={'h6'}>{'Ready for some great practice today?'}</Typography>
            </Box>
            <GoogleButton sx={baseStyles.button} setIsLoading={setIsLoading} />
            <Box sx={baseStyles.dividerRow}>
                <Box sx={baseStyles.divider} />
                <Typography>{'OR'}</Typography>
                <Box sx={baseStyles.divider} />
            </Box>
            <Field
                name={'Email'}
                value={email}
                setValue={setEmail}
                type={'email'}
                validation={{ function: isValidEmail, errorMessage: 'Invalid email.' }}
            />
            <Box sx={styles.passwordWrapper}>
                <Field
                    name={'Password'}
                    value={password}
                    setValue={setPassword}
                    type={'password'}
                    validation={{
                        function: isValidPassword,
                        errorMessage: 'Your password must be at least 6 characters.'
                    }}
                />
                <Typography sx={styles.forgotPasswordText} onClick={onForgotPassword}>
                    {'Forgot your password?'}
                </Typography>
            </Box>
            <LandingPageButton
                id={'sign-in'}
                text={'Sign In'}
                onClick={onSignIn}
                containerOverrides={baseStyles.button}
                disabled={!isComplete}
                loading={isLoading}
            />
            <SwitchText type={SwitchTextType.LOGIN} />
        </Box>
    )
}

export default LoginComponent
