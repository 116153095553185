import ReactDOM from 'react-dom/client'
import { CssBaseline, ThemeProvider } from '@mui/material'
import theme from './utils/theme'
import { Provider as ReduxProvider } from 'react-redux'
import store, { persistor } from './store'
import { configure as configureAmplify } from './config/amplify'
import { configure as configureSentry } from './config/sentry'
import { PersistGate } from 'redux-persist/integration/react'
import { SpeakableRouter } from './router/router'
import ToastController from './components/ToastController'

configureSentry()
configureAmplify()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <PersistGate loading={null} persistor={persistor}>
                <SpeakableRouter />
                <ToastController />
            </PersistGate>
        </ThemeProvider>
    </ReduxProvider>
)
