import { Theme } from '@emotion/react'
import { SxProps } from '@mui/material'

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    }
}

export default styles
