import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState, SliceState } from './data'

const goalsSlice = createSlice({
    name: 'goals',
    initialState,
    reducers: {
        setGoals(state, action: PayloadAction<Partial<SliceState>>) {
            const p = action.payload
            state.sessionTime = p.sessionTime ?? state.sessionTime
            state.practiceGoal = p.practiceGoal ?? state.practiceGoal
            state.shouldEmailReminders = p.shouldEmailReminders ?? state.shouldEmailReminders
        },
        setSessionTime(state, action: PayloadAction<number | undefined>) {
            state.sessionTime = action.payload
        },
        setPracticeGoal(state, action: PayloadAction<number>) {
            state.practiceGoal = action.payload
        },
        setShouldEmailReminders(state, action: PayloadAction<boolean>) {
            state.shouldEmailReminders = action.payload
        }
    }
})

export const { setGoals, setSessionTime, setPracticeGoal, setShouldEmailReminders } = goalsSlice.actions
export default goalsSlice.reducer
