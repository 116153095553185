import { SxProps, Theme } from '@mui/material'
import { BOLD_FONT_WEIGHT, BRIGHT_GREEN } from '../../../../utils/constants'

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10
    },
    number: {
        color: 'white'
    },
    circle: {
        width: 35,
        height: 35,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: BRIGHT_GREEN
    },
    titleText: {
        fontWeight: BOLD_FONT_WEIGHT
    }
}

export default styles
