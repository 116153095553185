import { SxProps, Theme } from '@mui/material'
import useIsSmallDesktop from '../../../../hooks/useIsSmallDesktop'
import styles from './styles'
import useIsMobile from '../../../../hooks/useIsMobile'

const useFeatureInformation = () => {
    const { isSmallDesktop } = useIsSmallDesktop()
    const isMobile = useIsMobile()
    const blurbStyles = { fontSize: isMobile ? 16 : isSmallDesktop ? 12 : 16 } as SxProps<Theme>
    const titleStyles = { ...styles.titleText, fontSize: isMobile ? 20 : isSmallDesktop ? 16 : 20 } as SxProps<Theme>
    const containerStyles = {
        ...styles.container,
        width: isMobile ? '100%' : `calc(50% - 10px)`
    }

    return { blurbStyles, titleStyles, containerStyles }
}

export default useFeatureInformation
