import { post, get, ApiError } from 'aws-amplify/api'
import {
    GetRunRequest,
    GetRunResponse,
    StartRunRequest,
    StartRunResponse,
    GetMessagesRequest,
    GetMessagesResponse,
    CreateThreadRequest,
    CreateThreadResponse,
    AddMessageRequest,
    AddMessageResponse
} from '@speakable/speakable-service-contracts/lib/conversation'

export const createThread = async (requestBody: CreateThreadRequest): Promise<CreateThreadResponse> => {
    try {
        const { response: promise } = post({
            apiName: 'conversation',
            path: '/thread',
            options: { body: { ...(requestBody as FormData), metadata: requestBody.metadata as any } }
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as CreateThreadResponse
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as CreateThreadResponse
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}

export const getMessages = async (queryParams: GetMessagesRequest): Promise<GetMessagesResponse> => {
    try {
        const { response: promise } = get({
            apiName: 'conversation',
            path: '/messages',
            options: { queryParams }
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as GetMessagesResponse
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as GetMessagesResponse
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}

export const getRun = async (queryParams: GetRunRequest): Promise<GetRunResponse> => {
    try {
        const { response: promise } = get({
            apiName: 'conversation',
            path: '/run',
            options: { queryParams }
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as GetRunResponse
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as GetRunResponse
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}

export const addMessage = async (requestBody: AddMessageRequest): Promise<AddMessageResponse> => {
    try {
        const { response: promise } = post({
            apiName: 'conversation',
            path: '/message',
            options: { body: requestBody }
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as AddMessageResponse
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as AddMessageResponse
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}

export const startRun = async (requestBody: StartRunRequest): Promise<StartRunResponse> => {
    try {
        const { response: promise } = post({
            apiName: 'conversation',
            path: '/run',
            options: { body: requestBody }
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as StartRunResponse
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as StartRunResponse
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}
