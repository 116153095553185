import { SxProps, Theme } from '@mui/material'
import { BOLD_FONT_WEIGHT } from '../../utils/constants'

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 10,
        gap: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white'
    },
    showShadow: {
        boxShadow: `0 2px 4px 0 rgba(0,0,0,.1)`
    },
    placeholder: {
        position: 'relative',
        width: '100%'
    },
    titleText: {
        fontWeight: BOLD_FONT_WEIGHT,
        userSelect: 'none'
    }
}

export default styles
