import { Theme } from '@emotion/react'
import { SxProps } from '@mui/material'

const styles: { [name: string]: SxProps<Theme> } = {
    imageContainer: {
        display: 'flex',
        position: 'relative',
        userSelect: 'none',
        width: '50%'
    },
    bluePattern: {
        position: 'absolute',
        userSelect: 'none',
        bottom: 0,
        left: 0,
        width: '20%',
        translate: '-25% 25%'
    },
    orangePattern: {
        position: 'absolute',
        userSelect: 'none',
        top: 0,
        right: 0,
        width: '20%',
        translate: '-65% -25%'
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20
    },
    informationContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 20
    },
    image: {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        borderRadius: 5
    }
}

export default styles
