import { Box, CircularProgress, SxProps, Theme, Tooltip, Typography } from '@mui/material'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import { HOVER_GRAY } from '../../utils/constants'
import useListenableText from './ListenableText.hooks'
import styles from './styles'
import { ListenableTextProps } from './ListenableText.data'

const ListenableText = ({
    text,
    textSx,
    containerSx,
    noWrap,
    size,
    gender,
    containerRef
}: ListenableTextProps): JSX.Element => {
    const { onClick, isLoadingAudio } = useListenableText({ gender, text })
    let buttonSize = size === 'large' ? 50 : size === 'medium' ? 35 : 25

    return (
        <Box
            ref={containerRef}
            sx={{ ...styles.container, ...containerSx } as SxProps<Theme>}
            onClick={(e) => e.stopPropagation()}
        >
            <Tooltip title={'Click to listen'} placement={'top'}>
                <Box
                    sx={{
                        ...styles.buttonContainer,
                        backgroundColor: 'default',
                        '&:hover': {
                            cursor: 'pointer',
                            backgroundColor: HOVER_GRAY
                        },
                        width: buttonSize,
                        minWidth: buttonSize,
                        height: buttonSize,
                        minHeight: buttonSize
                    }}
                    onClick={onClick}
                >
                    {isLoadingAudio ? (
                        <CircularProgress color="inherit" size={'50%'} />
                    ) : (
                        <VolumeUpIcon sx={styles.icon} />
                    )}
                </Box>
            </Tooltip>
            <Typography noWrap={noWrap} sx={textSx}>
                {text}
            </Typography>
        </Box>
    )
}

export default ListenableText
