import { SxProps, Theme } from '@mui/material'
import { BOLD_FONT_WEIGHT } from '../../utils/constants'

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 20
    },
    titleText: {
        fontSize: 25,
        fontWeight: BOLD_FONT_WEIGHT,
        textAlign: 'center'
    },
    subtitle: {
        fontSize: 20,
        textAlign: 'center'
    }
}

export default styles
