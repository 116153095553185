// import { getEncoding } from 'js-tiktoken'
import { syllable } from 'syllable'

const adheresToQuestionAsking = (prompt: string, response: string): boolean => {
    if (prompt.includes('Ask the user a question in your response.')) {
        if (!response.includes('?')) {
            return false
        }
    } else if (prompt.includes('Do not ask any questions.')) {
        if (response.includes('?')) {
            return false
        }
    }

    return true
}

const adheresToTokenLength = (prompt: string, response: string): boolean => {
    const numTokens = response.split(' ').length / 0.75 // one token is approximately 0.75 words

    if (prompt.includes('Your response should be at most 15 tokens.')) {
        if (numTokens > 15) {
            return false
        }
    } else if (prompt.includes('Your response should be at most 20 tokens.')) {
        if (numTokens > 20) {
            return false
        }
    } else if (prompt.includes('Your response should be at most 25 tokens.')) {
        if (numTokens > 25) {
            return false
        }
    } else if (prompt.includes('Your response should be at most 40 tokens.')) {
        if (numTokens > 40) {
            return false
        }
    }

    return true
}

const adheresToComplexity = (prompt: string, response: string): boolean => {
    const numSyllables = syllable(response)
    const numWords = response.split(' ').length
    const numSentences = response.split(/.|!|\\?/).length
    const fleschKincaidScore = 206.835 - 1.015 * (numWords / numSentences) - 84.6 * (numSyllables / numWords)

    if (prompt.includes('fourth grade reading level.')) {
        if (fleschKincaidScore < 80) {
            return false
        }
    } else if (prompt.includes('sixth grade reading level.')) {
        if (fleschKincaidScore < 60) {
            return false
        }
    }

    return true
}

export const scoreResponse = (prompt: string, response: string): number => {
    let score = 1
    if (!adheresToQuestionAsking(prompt, response)) score -= 1 / 4
    if (!adheresToTokenLength(prompt, response)) score -= 1 / 2
    if (!adheresToComplexity(prompt, response)) score -= 1 / 8
    return score
}
