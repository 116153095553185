import { useState } from 'react'
import { isValidEmail, isValidPassword, isValidResetCode } from '../data'
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth'
import { toast } from 'react-toastify'

const useForgotPasswordComponent = () => {
    const [email, setEmail] = useState<string>('')
    const [resetCode, setResetCode] = useState<string>('')
    const [newPassword, setNewPassword] = useState<string>('')
    const [isLoadingSend, setIsLoadingSend] = useState<boolean>(false)
    const [isLoadingEnter, setIsLoadingEnter] = useState<boolean>(false)
    const [hasSentEmail, setHasSentEmail] = useState<boolean>(false)

    const onSend = async () => {
        setIsLoadingSend(true)
        try {
            await resetPassword({ username: email, options: { email } })
            toast.success(`Reset code successfully sent to ${email}!`)
            setHasSentEmail(true)
        } catch (e: any) {
            let error = 'There was an error sending your reset code.'
            switch (e.name) {
                case 'NetworkError':
                    error = 'Please connect to the internet and try again.'
                    break
                case 'UserNotFoundException':
                    error = `We couldn't find an account with that email.`
                    break
                case 'InvalidParameterException':
                    error = `The account with this email has not yet been verified.`
                    break
                case 'LimitExceededException':
                    error = 'You have sent too many codes. Please try again later.'
                    break
                default:
                    console.log(e.name)
                    break
            }
            toast.error(error)
        }
        setIsLoadingSend(false)
    }

    const onSubmitResetCode = async () => {
        setIsLoadingEnter(true)
        try {
            await confirmResetPassword({ username: email, newPassword, confirmationCode: resetCode })
            toast.success(`You have successfully reset your password!`)
        } catch (e: any) {
            let error = 'There was an error resetting your password.'
            switch (e.name) {
                case 'NetworkError':
                    error = 'Please connect to the internet and try again.'
                    break
                case 'ExpiredCodeException':
                    error = 'The reset code you submitted has expired. Please get a new code.'
                    break
                case 'UserNotFoundException':
                    error = `There was no account found with email ${email}.`
                    break
                case 'CodeMismatchException':
                    error = `Invalid code.`
                    break
                case 'LimitExceededException':
                    error = `Too many attempts. Please try again later.`
                    break
                default:
                    console.log(e.name)
                    break
            }
            toast.error(error)
        }
        setIsLoadingEnter(false)
    }

    return {
        email,
        resetCode,
        newPassword,
        isValidEmail: isValidEmail(email),
        isValidChangePassword: isValidResetCode(resetCode) && isValidPassword(newPassword),
        isLoadingSend,
        isLoadingEnter,
        hasSentEmail,
        setEmail,
        setResetCode,
        setNewPassword,
        onSend,
        onSubmitResetCode
    }
}

export default useForgotPasswordComponent
