import { ApiError, post } from 'aws-amplify/api'
import {
    ResendEmailVerificationRequest,
    ResendEmailVerificationResponse,
    VerifyEmailRequest,
    VerifyEmailResponse
} from '@speakable/speakable-service-contracts/lib/auth'

export const verifyEmail = async (requestBody: VerifyEmailRequest): Promise<VerifyEmailResponse> => {
    try {
        const { response: promise } = post({
            apiName: 'auth',
            path: '/verify-email',
            options: { body: requestBody }
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as VerifyEmailResponse
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as VerifyEmailResponse
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}

export const resendVerificationEmail = async (
    requestBody: ResendEmailVerificationRequest
): Promise<ResendEmailVerificationResponse> => {
    try {
        const { response: promise } = post({
            apiName: 'auth',
            path: '/resend-email-verification',
            options: { body: requestBody }
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as ResendEmailVerificationResponse
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as ResendEmailVerificationResponse
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}
