export const componentShadow = `0 6px 0px -3px black`

export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

export const abbreviatedMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

export const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
]

// colors
export const LIGHT_GREEN = '#c3d984'
export const GREEN = '#B9DC57'
export const DARK_GREEN = '#abd929'
export const HOVER_GRAY = '#f0f0f0'
export const DISABLED_GRAY = 'lightgray'
export const RED = 'red'

export const BLUE_GRAY = '#2A3342'
export const LIGHT_BLUE_GRAY = '#8896AB'
export const EXTRA_LIGHT_BLUE_GRAY = '#BBC3CF'
export const BRIGHT_GREEN = '#22C55E'
export const LIGHT_BRIGHT_GREEN = '#DCFCE7'

export const JOURNEY_BACKGROUND_COLOR = '#e6f5ea'
export const JOURNEY_CURRENT_LEVEL_COLOR = '#57de7b'

// fonts
export const BOLD_FONT_WEIGHT = 700

// pages
export const PAGE_SIDE_PADDING = 50
export const DESKTOP_PAGE_SIDE_PADDING = 50
export const MOBILE_PAGE_SIDE_PADDING = 30
export const MOBILE_SIDE_PADDING = 20
export const NAV_BAR_HEIGHT = 100
export const DESKTOP_NAV_BAR_HEIGHT = 100
export const MOBILE_NAV_BAR_HEIGHT = 60

// audio
export const RE_ENABLE_AUDIO_BUFFER = 250 // 250 ms
