export type SliceState = {
    closedCaptions: boolean | undefined
    questionFrequency: number | undefined
    responseComplexity: number | undefined
    responseLength: number | undefined
    allowConversationAnalysis: boolean | undefined
    autoRecordEnabled: boolean | undefined
}

export const initialState: SliceState = {
    closedCaptions: undefined,
    questionFrequency: undefined,
    responseComplexity: undefined,
    responseLength: undefined,
    allowConversationAnalysis: undefined,
    autoRecordEnabled: undefined
}
