import ReactPlayer from 'react-player'
import { ModalType } from '../../store/site/data'
import LoginComponent from '../AuthComponents/LoginComponent'
import SignupComponent from '../AuthComponents/SignupComponent'
import ForgotPasswordComponent from '../AuthComponents/ForgotPasswordComponent'
import PlaybackBlockedComponent from '../PlaybackBlockedComponent/PlaybackBlockedComponent'
import { Box } from '@mui/material'
import { componentShadow } from '../../utils/constants'
import ListenableText from '../ListenableText'
import { useEffect, useState } from 'react'
import SpeakableButton from '../SpeakableButton'
import { useAppDispatch } from '../../store/hooks'
import { setCurrentModal } from '../../store/site'
import { useGetSite } from '../../store/selectors/siteSelector'
import { acknowledgeBadge } from '../../api/statistics/api'
import PracticeGoals from '../PracticeGoals/PracticeGoals'

export const modalMap: Map<ModalType, () => JSX.Element> = new Map([
    [ModalType.LOGIN, LoginComponent],
    [ModalType.SIGNUP, SignupComponent],
    [ModalType.PRACTICE_GOAL, PracticeGoals],
    [
        ModalType.LANDING_PAGE_VIDEO,
        () => {
            return (
                <ReactPlayer
                    url={'/videos/LandingPageVideo.mp4'}
                    width={'100%'}
                    height={'100%'}
                    controls={true}
                    playing={true}
                />
            )
        }
    ],
    [ModalType.FORGOT_PASSWORD, ForgotPasswordComponent],
    [ModalType.PLAYBACK_BLOCKED, PlaybackBlockedComponent],
    [
        ModalType.UNLOCK_BADGE,
        () => {
            const dispatch = useAppDispatch()
            const {
                currentModalMetadata: { badge }
            } = useGetSite()
            const [opacity, setOpacity] = useState<number>(0)

            const badgeType =
                parseInt(badge.badgeType as string) === 0
                    ? 'bronze'
                    : parseInt(badge.badgeType as string) === 1
                      ? 'silver'
                      : 'gold'

            useEffect(() => {
                let interval: NodeJS.Timer
                let timeout = setTimeout(() => {
                    interval = setInterval(() => {
                        setOpacity((prev) => Math.min(1, prev + 0.01))
                    }, 10)
                }, 500)

                return () => {
                    clearInterval(interval)
                    clearTimeout(timeout)

                    acknowledgeBadge({ achievementId: badge.badgeType, achievedAt: badge.receivedAt })
                }
            }, [])

            return (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 20,
                        gap: 20,
                        width: 500,
                        height: 600,
                        backgroundColor: 'white',
                        borderRadius: 2,
                        border: 1,
                        boxShadow: componentShadow
                    }}
                >
                    <ListenableText
                        textSx={{ fontSize: 28 }}
                        text={`Congratulations! You have earned a ${badgeType[0].toUpperCase()}${badgeType.substring(1)} Practice Badge for the month of ${badge.startDateString.substring(5).replace('-', '/')} to ${badge.endDateString.substring(5).replace('-', '/')}!`}
                        size={'medium'}
                    />
                    <Box component={'img'} src={`/badges/${badgeType}.png`} sx={{ opacity }} />
                    <SpeakableButton
                        text={'Continue'}
                        onClick={() => {
                            dispatch(setCurrentModal(undefined))
                        }}
                        variant={'normal'}
                        id={`acknowledge-${badgeType}-badge`}
                    />
                </Box>
            )
        }
    ]
])
