import { useEffect, useRef } from 'react'

/**
 * This hook returns a function that returns whether the component is mounted.
 *
 * @returns a function that returns whether the component is mounted
 */
export const useIsMounted = () => {
    const isMounted = useRef<boolean>(false)
    useEffect(() => {
        isMounted.current = true
        return () => {
            isMounted.current = false
        }
    }, [])
    return () => isMounted.current
}
