import { SxProps, Theme } from '@mui/material'
import { BOLD_FONT_WEIGHT, MOBILE_PAGE_SIDE_PADDING, componentShadow } from '../../utils/constants'

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        backgroundColor: 'white',
        justifyContent: 'center',
        zIndex: 100,
        pl: MOBILE_PAGE_SIDE_PADDING,
        pr: MOBILE_PAGE_SIDE_PADDING
    },
    titleText: {
        fontSize: 25,
        textAlign: 'center',
        fontWeight: BOLD_FONT_WEIGHT
    },
    subtitle: {
        fontSize: 20,
        textAlign: 'center'
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        gap: 20
    }
}

export default styles
