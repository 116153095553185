import { Box, Typography } from '@mui/material'
import useLandingPageContactSection from './LandingPageContactSection.hooks'
import { forwardRef } from 'react'
import styles from './styles'
import baseStyles from '../styles'
import LandingPageTag from '../LandingPageTag'
import { blurb, sectionTitle } from './LandingPageContactSection.data'
import ContactForm from '../../ContactForm'

const LandingPageContactSection = (): JSX.Element => {
    const { contactRef, containerStyle, titleTextVariant, paragraphVariant, contentContainerStyles, formStyles } =
        useLandingPageContactSection()

    return (
        <Box ref={contactRef} sx={containerStyle}>
            <Box sx={contentContainerStyles}>
                <LandingPageTag text={'Contact'} />
                <Typography variant={titleTextVariant} sx={baseStyles.sectionTitleText}>
                    {sectionTitle}
                </Typography>
                <Typography variant={paragraphVariant}>{blurb}</Typography>
            </Box>
            <ContactForm sx={formStyles} />
        </Box>
    )
}

export default forwardRef(LandingPageContactSection)
