import useDescriptiveNavigate from '../../hooks/useDescriptiveNavigate'
import { conversationMap } from '../../store/conversation/data'

/**
 * This hook holds the business logic needed for the conversation details page.
 *
 * @returns the resources needed for the conversation details page
 */
const useConversationDetailsPage = () => {
    const descriptiveNavigate = useDescriptiveNavigate()
    const conversationId = parseInt(window.location.pathname.split('/')[2])
    const conversationDetails = isNaN(conversationId) ? undefined : conversationMap.get(conversationId)
    const imageSrc = `/images/scenarios/scenario-${conversationId}.png`

    /**
     * This function navigates the user to the conversation page.
     */
    const onStart = () => {
        descriptiveNavigate('/conversation', { conversationId: conversationId.toString() })
    }

    /**
     * This function navigates the user back to the home page.
     */
    const onBack = () => {
        descriptiveNavigate('/home')
    }

    return { conversationDetails, imageSrc, onStart, onBack }
}

export default useConversationDetailsPage
