import { SxProps, Theme } from '@mui/material'
import { BOLD_FONT_WEIGHT } from '../../../utils/constants'

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 5,
        pr: 20,
        border: 1,
        borderRadius: 2,
        borderColor: 'lightgray'
    },
    text: {
        userSelect: 'none',
        fontWeight: BOLD_FONT_WEIGHT
    }
}

export default styles
