type Config = {
    name: 'prod' | 'dev' | 'experimental' | 'local'
    baseUrl:
        | 'https://speakabletherapy.com'
        | 'https://dev.speakabletherapy.com'
        | 'https://exp.speakabletherapy.com'
        | 'http://localhost:3000'
    cognito: {
        region: string
        userPoolID: string
        userPoolAppClientID: string
        identityPoolID: string
    }
    endpoints: Endpoints
}

type Endpoints = {
    conversation: string
    user: string
    contact: string
    auth: string
    payment: string
    audio: string
    analytics: string
    status: string
    statistics: string
    goals: string
}

const prod: Config = {
    name: 'prod',
    baseUrl: 'https://speakabletherapy.com',
    cognito: {
        region: 'us-east-1',
        userPoolID: 'us-east-1_38gPOYxev',
        userPoolAppClientID: 'tko23lqmuhf9m1dosrdl4jjtk',
        identityPoolID: 'us-east-1:a1189aaa-81eb-4779-bd84-b5080e839c24'
    },
    endpoints: {
        conversation: 'https://25uwwy2y8j.execute-api.us-east-1.amazonaws.com',
        user: 'https://lyrsqp585l.execute-api.us-east-1.amazonaws.com',
        contact: 'https://rs6hew1bae.execute-api.us-east-1.amazonaws.com',
        auth: 'https://bcghablqh6.execute-api.us-east-1.amazonaws.com',
        payment: 'https://9lbm7wcj30.execute-api.us-east-1.amazonaws.com',
        audio: 'https://1vbjmxe9i6.execute-api.us-east-1.amazonaws.com',
        analytics: 'https://89mlep4eni.execute-api.us-east-1.amazonaws.com',
        status: 'https://xqdhpdee6e.execute-api.us-east-1.amazonaws.com',
        statistics: 'https://ata65om5bj.execute-api.us-east-1.amazonaws.com',
        goals: 'https://oswkjpas01.execute-api.us-east-1.amazonaws.com'
    }
}

const dev: Config = {
    name: 'dev',
    baseUrl: 'https://dev.speakabletherapy.com',
    cognito: {
        region: 'us-east-1',
        userPoolID: 'us-east-1_d9djbzwWp',
        userPoolAppClientID: '7bfa26ki26i3d2dfsrh4ojhjg5',
        identityPoolID: 'us-east-1:2d4464fa-1d9f-4f57-8d74-092d03756a3f'
    },
    endpoints: {
        conversation: 'https://7z5wzrig1b.execute-api.us-east-1.amazonaws.com',
        user: 'https://slj6byc1t0.execute-api.us-east-1.amazonaws.com',
        contact: 'https://03qoh9vqs7.execute-api.us-east-1.amazonaws.com',
        auth: 'https://2pic3ii0di.execute-api.us-east-1.amazonaws.com',
        payment: 'https://wq9q31qmcc.execute-api.us-east-1.amazonaws.com',
        audio: 'https://i2jebrfa59.execute-api.us-east-1.amazonaws.com',
        analytics: 'https://0d1ybnqo76.execute-api.us-east-1.amazonaws.com',
        status: 'https://8xzopz5vtg.execute-api.us-east-1.amazonaws.com',
        statistics: 'https://f8uigjv3h8.execute-api.us-east-1.amazonaws.com',
        goals: 'https://3l6lhnfma2.execute-api.us-east-1.amazonaws.com'
    }
}

const experimental: Config = {
    name: 'experimental',
    baseUrl: 'https://exp.speakabletherapy.com',
    cognito: dev.cognito,
    endpoints: dev.endpoints
}

const local: Config = {
    name: 'local',
    baseUrl: 'http://localhost:3000',
    cognito: {
        region: process.env.REACT_APP_LOCAL_REGION as string,
        userPoolID: process.env.REACT_APP_LOCAL_USER_POOL_ID as string,
        userPoolAppClientID: process.env.REACT_APP_LOCAL_USER_POOL_APP_CLIENT_ID as string,
        identityPoolID: process.env.REACT_APP_LOCAL_IDENTITY_POOL_ID as string
    },
    endpoints: {
        conversation: process.env.REACT_APP_LOCAL_ENDPOINT_CONVERSATION as string,
        user: process.env.REACT_APP_LOCAL_ENDPOINT_USER as string,
        contact: process.env.REACT_APP_LOCAL_ENDPOINT_CONTACT as string,
        auth: process.env.REACT_APP_LOCAL_ENDPOINT_AUTH as string,
        payment: process.env.REACT_APP_LOCAL_ENDPOINT_PAYMENT as string,
        audio: process.env.REACT_APP_LOCAL_ENDPOINT_AUDIO as string,
        analytics: process.env.REACT_APP_LOCAL_ENDPOINT_ANALYTICS as string,
        status: process.env.REACT_APP_LOCAL_ENDPOINT_STATUS as string,
        statistics: process.env.REACT_APP_LOCAL_ENDPOINT_STATISTICS as string,
        goals: process.env.REACT_APP_LOCAL_ENDPOINT_GOALS as string
    }
}

const current_env = process.env.REACT_APP_ENV

// use `config` to hit the correct endpoints depending on what environment you're working in
export const config =
    current_env === 'prod' ? prod : current_env === 'dev' ? dev : current_env === 'experimental' ? experimental : local
