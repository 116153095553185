import { SxProps, Theme } from '@mui/material'
import { useLandingPageContext } from '../../../pages/LandingPage/LandingPage.hooks'
import useLandingSectionStyling from '../hooks'
import Background from '../../../assets/images/LandingPage/PricingSection/Background.svg'
import useIsMobile from '../../../hooks/useIsMobile'
import styles from './styles'

const useLandingPagePricingSection = () => {
    const { pricingRef } = useLandingPageContext()
    const { titleTextVariant, paragraphVariant, containerStyle } = useLandingSectionStyling()
    const isMobile = useIsMobile()
    const cardRowStyles = { ...styles.cardRow, flexDirection: isMobile ? 'column' : 'row' } as SxProps<Theme>

    return {
        pricingRef,
        titleTextVariant,
        paragraphVariant,
        containerStyle: {
            ...containerStyle,
            gap: 20,
            flexDirection: 'column',
            alignItems: 'center',
            backgroundImage: `url(${Background})`,
            backgroundSize: `100% 100%`,
            backgroundRepeat: 'no-repeat'
        } as SxProps<Theme>,
        cardRowStyles
    }
}

export default useLandingPagePricingSection
