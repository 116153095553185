import { ApiError, post } from 'aws-amplify/api'
import {
    GetLipSyncRequest,
    GetLipSyncResponse,
    TextToSpeechRequest,
    TextToSpeechResponse,
    TranscribeRequest,
    TranscribeResponse
} from '@speakable/speakable-service-contracts/lib/audio'

export const textToSpeech = async (requestBody: TextToSpeechRequest): Promise<TextToSpeechResponse> => {
    try {
        const { response: promise } = post({
            apiName: 'audio',
            path: '/text-to-speech',
            options: { body: requestBody }
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as TextToSpeechResponse
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as TextToSpeechResponse
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}

// TODO: Fix request type in service contracts
export const transcribe = async (requestBody: { audio: number[] }): Promise<TranscribeResponse> => {
    try {
        const { response: promise } = post({
            apiName: 'audio',
            path: '/transcribe',
            options: { body: requestBody }
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as TranscribeResponse
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as TranscribeResponse
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}

export const lipSync = async (requestBody: GetLipSyncRequest): Promise<any> => {
    try {
        const { response: promise } = post({
            apiName: 'audio',
            path: '/lipsync',
            options: { body: requestBody }
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as any
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as any
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}
