import { Box, Typography } from '@mui/material'
import styles from './styles'
import baseStyles from '../styles'
import GoogleButton from '../GoogleButton'
import useSignupComponent from './SignupComponent.hooks'
import LandingPageButton from '../../LandingPage/LandingPageButton'
import SwitchText from '../SwitchText'
import { SwitchTextType } from '../SwitchText/SwitchText.data'
import Field from '../Field/Field'
import { isValidConfirmPassword, isValidEmail, isValidFirstName, isValidLastName, isValidPassword } from '../data'

const SignupComponent = (): JSX.Element => {
    const {
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        isLoading,
        isComplete,
        setFirstName,
        setLastName,
        setEmail,
        setPassword,
        setConfirmPassword,
        setIsLoading,
        onSignUp
    } = useSignupComponent()

    return (
        <Box sx={baseStyles.container}>
            <Box sx={baseStyles.titleContainer}>
                <Typography variant={'h3'} sx={baseStyles.titleText}>
                    {'Create Account'}
                </Typography>
                <Typography variant={'h6'}>{`We're glad to have you onboard!`}</Typography>
            </Box>
            <GoogleButton sx={baseStyles.button} setIsLoading={setIsLoading} />
            <Box sx={baseStyles.dividerRow}>
                <Box sx={baseStyles.divider} />
                <Typography>{'OR'}</Typography>
                <Box sx={baseStyles.divider} />
            </Box>
            <Box sx={styles.nameRow}>
                <Field
                    name={'First Name'}
                    value={firstName}
                    setValue={setFirstName}
                    disabled={isLoading}
                    validation={{ function: isValidFirstName, errorMessage: 'You must enter a first name.' }}
                />
                <Field
                    name={'Last Name'}
                    value={lastName}
                    setValue={setLastName}
                    disabled={isLoading}
                    validation={{ function: isValidLastName, errorMessage: 'You must enter a last name.' }}
                />
            </Box>
            <Field
                name={'Email'}
                value={email}
                setValue={setEmail}
                disabled={isLoading}
                type={'email'}
                validation={{ function: isValidEmail, errorMessage: 'Invalid email.' }}
            />
            <Field
                name={'Password'}
                value={password}
                setValue={setPassword}
                disabled={isLoading}
                type={'password'}
                validation={{ function: isValidPassword, errorMessage: 'Your password must be at least 6 characters.' }}
            />
            <Field
                name={'Confirm Password'}
                value={confirmPassword}
                setValue={setConfirmPassword}
                disabled={isLoading}
                type={'password'}
                validation={{
                    function: (confirmPassword: string) => isValidConfirmPassword(password, confirmPassword),
                    errorMessage: 'Both passwords must be the same.'
                }}
            />
            <LandingPageButton
                id={'sign-up'}
                text={'Sign Up'}
                onClick={onSignUp}
                containerOverrides={baseStyles.button}
                loading={isLoading}
                disabled={!isComplete}
            />
            <SwitchText type={SwitchTextType.SIGNUP} />
        </Box>
    )
}

export default SignupComponent
