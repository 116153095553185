import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { initialState } from './data'

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setAuthenticationState(state, action: PayloadAction<boolean | 'unknown'>) {
            state.isAuthenticated = action.payload
        }
    }
})

export const { setAuthenticationState } = authenticationSlice.actions
export default authenticationSlice.reducer
