import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { verifyEmail } from '../../api/auth/api'
import { toast } from 'react-toastify'
import { VerifyEmailStatus } from './VerifyEmailPage.data'
import useIsMobile from '../../hooks/useIsMobile'
import { useGetUser } from '../../store/selectors/userSelector'
import { useGetAuthentication } from '../../store/selectors/authenticationSelector'
import { useAppDispatch } from '../../store/hooks'
import { setCurrentModal } from '../../store/site'
import { ModalType } from '../../store/site/data'
import { setEmailVerificationState } from '../../store/user'

const useVerifyEmailPage = () => {
    const [status, setStatus] = useState<VerifyEmailStatus>()
    const location = useLocation()
    const isMobile = useIsMobile()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { userId } = useGetUser()
    const { isAuthenticated } = useGetAuthentication()

    useEffect(() => {
        const verifyEmailAddress = async () => {
            const pathComponents = location.pathname.split('/') as string[]
            const emailVerificationId = pathComponents[pathComponents.length - 1]
            setStatus(VerifyEmailStatus.LOADING)
            const { statusCode } = await verifyEmail({ emailVerificationId })

            switch (statusCode) {
                case 201:
                    setStatus(VerifyEmailStatus.SUCCESS)
                    toast.success(`You have successfully verified your email!`)
                    break
                case 400:
                    setStatus(VerifyEmailStatus.INVALID)
                    toast.error(`The link you used is invalid. Please try sending another verification email.`)
                    break
                case 500:
                    setStatus(VerifyEmailStatus.INVALID)
                    toast.error(`There was a problem verifying your email. Please try again.`)
                    break
                default:
                    break
            }
        }

        void verifyEmailAddress()
    }, [])

    useEffect(() => {
        if (userId !== undefined && VerifyEmailStatus.SUCCESS === status) {
            // only after we have fetched the user's information can we set the email verification state to true.
            // otherwise, the email verification field will be set to the stale value.
            dispatch(setEmailVerificationState(true))
        }
    }, [userId, status])

    const onGoHome = () => {
        const route = isAuthenticated === true ? '/home' : '/'
        navigate(route)
    }

    const onContinue = () => {
        if (isAuthenticated === true) {
            navigate('/onboarding')
        } else {
            navigate('/')
            dispatch(setCurrentModal(ModalType.LOGIN))
        }
    }

    return { status, isMobile, isAuthenticated, onGoHome, onContinue }
}

export default useVerifyEmailPage
