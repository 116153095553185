import { SxProps, Theme } from '@mui/material'

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        userSelect: 'none',
        height: 'fit-content'
    }
}

export default styles
