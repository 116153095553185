import styles from './styles'
import useNotFoundPage from './NotFound.hooks'
import { Box, Typography } from '@mui/material'
import SpeakableButton from '../../components/SpeakableButton'

const NotFoundPage = (): JSX.Element => {
    const { onClick } = useNotFoundPage()

    return (
        <Box sx={styles.container}>
            <Box sx={styles.contentContainer}>
                <Typography sx={styles.text}>{`Oops! The page that you're looking for doesn't exist.`}</Typography>
                <SpeakableButton
                    id={'page-not-found-acknowledgement'}
                    text={'Return Home'}
                    onClick={onClick}
                    variant={'important'}
                />
            </Box>
        </Box>
    )
}

export default NotFoundPage
