import { SxProps, Theme } from '@mui/material'
import styles from './styles'
import { useNavigate } from 'react-router-dom'
import { createEvent } from '../../../api/analytics/api'
import { useGetAuthentication } from '../../../store/selectors/authenticationSelector'

const useRegularNavbar = () => {
    const { isAuthenticated } = useGetAuthentication()
    const navigate = useNavigate()
    const onClickHome = () => {
        createEvent({ eventId: 'navbar-home', time: new Date().getTime() })
        navigate('/home')
    }
    const onClickProfile = () => {
        createEvent({ eventId: 'navbar-profile', time: new Date().getTime() })
        navigate('/profile/dashboard')
    }
    const onClickLogo = () => {
        createEvent({ eventId: 'navbar-logo', time: new Date().getTime() })
        const route = isAuthenticated === true ? '/home' : '/'
        navigate(route)
    }

    const pathname = window.location.pathname
    const practiceTextStyles = {
        ...styles.linkText,
        ...(pathname === '/home' ? styles.selectedLinkText : {})
    } as SxProps<Theme>
    const profileTextStyles = {
        ...styles.linkText,
        ...(pathname.includes('/profile') ? styles.selectedLinkText : {})
    } as SxProps<Theme>

    return { onClickHome, onClickProfile, onClickLogo, practiceTextStyles, profileTextStyles }
}

export default useRegularNavbar
