import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type SubscriptionStatus =
    | 'incomplete'
    | 'incomplete_expired'
    | 'trialing'
    | 'active'
    | 'past_due'
    | 'canceled'
    | 'unpaid'
    | 'nonexistent' // for when no subscription exists. All the other fields come from Stripe, themselves

export type SliceState = {
    userId: string | undefined // unique ID for the current user
    firstName: string | undefined // first name of the current user
    lastName: string | undefined // last name of the current user
    email: string | undefined // email address of the current user
    joinedAt: number | undefined // at what time did the user join? milliseconds since the epoch
    verifiedEmail: boolean | undefined // has the user verified their email yet?
    completedOnboarding: boolean | undefined // has the user completed the onboarding process?
    completedDiagnostic: boolean | undefined // has the user completed their diagnostic test?
    customerId: string | undefined // what is the Stripe customerId corresponding to this user?
    subscriptionStatus: SubscriptionStatus | undefined // what is the user's Stripe subscription status for Speakable?
}

export const initialState: SliceState = {
    userId: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    joinedAt: undefined,
    verifiedEmail: undefined,
    completedOnboarding: undefined,
    completedDiagnostic: undefined,
    customerId: undefined,
    subscriptionStatus: undefined
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserID(state, action: PayloadAction<string | undefined>) {
            state.userId = action.payload
        },
        setOnboardingState(state, action: PayloadAction<boolean>) {
            state.completedOnboarding = action.payload
        },
        setEmailVerificationState(state, action: PayloadAction<boolean>) {
            state.verifiedEmail = action.payload
        },
        setUserInfo(state, action: PayloadAction<any>) {
            const p = action.payload
            state.firstName = p.firstName
            state.lastName = p.lastName
            state.email = p.email
            state.joinedAt = p.joinedAt
            state.verifiedEmail = p.verifiedEmail
            state.completedOnboarding = p.completedOnboarding
            state.completedDiagnostic = p.completedDiagnostic
            state.customerId = p.customerId
        },
        setSubscriptionStatus(state, action: PayloadAction<SubscriptionStatus>) {
            state.subscriptionStatus = action.payload
        },
        resetUserData(state) {
            state.userId = initialState.userId
            state.firstName = initialState.firstName
            state.lastName = initialState.lastName
            state.email = initialState.firstName
            state.joinedAt = initialState.joinedAt
            state.verifiedEmail = initialState.verifiedEmail
            state.completedOnboarding = initialState.completedOnboarding
            state.completedDiagnostic = initialState.completedDiagnostic
            state.customerId = initialState.customerId
            state.subscriptionStatus = initialState.subscriptionStatus
        }
    }
})

export const {
    setUserID,
    setOnboardingState,
    setSubscriptionStatus,
    setEmailVerificationState,
    setUserInfo,
    resetUserData
} = userSlice.actions
export default userSlice.reducer
