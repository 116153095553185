import useIsMobile from '../../../hooks/useIsMobile'
import styles from './styles'

const usePricingCard = () => {
    const isMobile = useIsMobile()
    const containerStyles = { ...styles.container, width: isMobile ? '100%' : '50%' }

    return { containerStyles, isMobile }
}

export default usePricingCard
