import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState, ModalType, Referrer, sitePersistConfig } from './data'
import { persistReducer } from 'redux-persist'

const siteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {
        setCurrentModal(state, action: PayloadAction<ModalType | undefined>) {
            state.currentModal = action.payload
        },
        setModalMetadata(state, action: PayloadAction<any>) {
            state.currentModalMetadata = action.payload
        },
        setConversationStatus(state, action: PayloadAction<'operational' | 'down'>) {
            state.conversationStatus = action.payload
        },
        setReferrer(state, action: PayloadAction<Referrer | undefined>) {
            state.referrer = action.payload
        }
    }
})

export const { setCurrentModal, setConversationStatus, setModalMetadata, setReferrer } = siteSlice.actions
export default persistReducer(sitePersistConfig, siteSlice.reducer)
