import { SxProps, Theme } from '@mui/material'

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
    }
}

export default styles
