import { useEffect, useState } from 'react'
import { useGetUser } from '../../store/selectors/userSelector'
import { useNavigate } from 'react-router-dom'
import { resendVerificationEmail } from '../../api/auth/api'
import { toast } from 'react-toastify'

const useVerificationBlockerPage = () => {
    const [isResending, setIsResending] = useState<boolean>(false)
    const { email, verifiedEmail, firstName, lastName } = useGetUser()
    const navigate = useNavigate()

    const resendEmail = async () => {
        if (isResending) return
        setIsResending(true)
        const { statusCode, body } = await resendVerificationEmail({
            email: email as string,
            firstName: firstName as string,
            lastName: lastName as string
        })

        if (statusCode === 500) {
            toast.error('There was a problem resending your verification email. Please try again.')
        } else {
            toast.success('Successfully resent email!')
        }
        setIsResending(false)
    }

    const onGetStarted = () => {
        navigate('/home')
    }

    return { resendEmail, onGetStarted, email, isResending, verifiedEmail }
}

export default useVerificationBlockerPage
