import { Box, SxProps, Theme, Typography } from '@mui/material'
import styles from './styles'
import useProfilePage from './ProfilePage.hooks'
import { Suspense } from 'react'

const ProfilePage = (): JSX.Element => {
    const {
        firstName,
        component: Component,
        titleTextRef,
        selectedTab,
        tabs,
        onClick,
        onClickTOS,
        onClickPrivacy,
        onClickSignOut
    } = useProfilePage()

    return (
        <Box sx={styles.container}>
            <Typography sx={styles.titleText} ref={titleTextRef}>{`Welcome, ${firstName}`}</Typography>
            <Box sx={styles.nonTitleContainer}>
                <Box sx={styles.sidebarContainer}>
                    <Box sx={styles.tabColumn}>
                        {tabs.map((tab, ix) => {
                            const isSelected =
                                tab.toLowerCase() === selectedTab.toLowerCase() ||
                                (selectedTab === 'account' && tab.toLowerCase() === 'subscription') // legacy
                            const textStyles = {
                                ...styles.tabText,
                                ...(isSelected ? styles.selectedTabText : {})
                            } as SxProps<Theme>

                            return (
                                <Box
                                    sx={styles.tabTextContainer}
                                    key={`profile tab ${ix}`}
                                    onClick={() => onClick(tab.toLowerCase())}
                                >
                                    <Typography sx={textStyles} variant={'h4'}>
                                        {tab}
                                    </Typography>
                                </Box>
                            )
                        })}
                    </Box>
                    <Box sx={styles.bottomButtonColumn}>
                        <Typography sx={styles.bottomText} onClick={onClickPrivacy}>
                            {'Privacy Policy'}
                        </Typography>
                        <Typography sx={styles.bottomText} onClick={onClickTOS}>
                            {'Terms of Service'}
                        </Typography>
                        <Typography sx={styles.bottomText} onClick={onClickSignOut}>
                            {'Sign Out'}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={styles.contentContainer}>
                    <Suspense>
                        <Component />
                    </Suspense>
                </Box>
            </Box>
        </Box>
    )
}

export default ProfilePage
