import { ApiError, get, post } from 'aws-amplify/api'
import {
    GetCustomerStatusResponse,
    CreatePortalSessionResponse,
    CreateCheckoutSessionResponse
} from '@speakable/speakable-service-contracts/lib/payment'

// TODO add this input to the service-contracts
export const getSubscriptionStatus = async ({
    customerId
}: {
    customerId: string
}): Promise<GetCustomerStatusResponse> => {
    try {
        const { response: promise } = get({
            apiName: 'payment',
            path: '/subscription-status',
            options: { queryParams: { customerId } }
        })

        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as GetCustomerStatusResponse
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as GetCustomerStatusResponse
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}

// TODO: Fix service contracts
export const createPortalSession = async ({
    customerId
}: {
    customerId: string
}): Promise<CreatePortalSessionResponse> => {
    try {
        const { response: promise } = post({
            apiName: 'payment',
            path: '/portal-session',
            options: { body: { customerId } }
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as CreatePortalSessionResponse
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as CreatePortalSessionResponse
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}

// TODO: Fix service contracts
export const createCheckoutSession = async ({
    customerId
}: {
    customerId: string
}): Promise<CreateCheckoutSessionResponse> => {
    try {
        const { response: promise } = post({
            apiName: 'payment',
            path: '/checkout-session',
            options: { body: { customerId } }
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as CreateCheckoutSessionResponse
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as CreateCheckoutSessionResponse
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}
