import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'
import { persistStore } from 'redux-persist'

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
})

export type RootState = ReturnType<typeof store.getState>
export type AppStore = typeof store
export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store)
export default store
