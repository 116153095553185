import { Box, Typography } from '@mui/material'
import styles from './styles'
import { policySections } from './PrivacyPage.data'

const PrivacyPage = (): JSX.Element => {
    return (
        <Box sx={styles.container}>
            {policySections.map((section: string, ix: number) => {
                return (
                    <Typography variant={'h5'} key={`privacy section ${ix}`}>
                        {section}
                    </Typography>
                )
            })}
        </Box>
    )
}

export default PrivacyPage
