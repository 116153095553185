import { useLocation, useNavigate } from 'react-router-dom'

/**
 * This hook returns a function that navigates to a new location with descriptive metadata.
 *
 * @returns a function that navigates to a new location with descriptive metadata
 */
const useDescriptiveNavigate = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const descriptiveNavigate = (to: string, metadata?: Record<string, string>) => {
        navigate(to, { state: { from: location.pathname, metadata } })
    }
    return descriptiveNavigate
}

export default useDescriptiveNavigate
