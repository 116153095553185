import { Box, Tooltip, Typography } from '@mui/material'
import { IFieldProps } from './Field.data'
import styles from './styles'
import TextInput from '../../TextInput'
import { useField } from './Field.hooks'

const Field = ({ name, value, setValue, validation, disabled, type, placeholder }: IFieldProps) => {
    const { errorMessage } = useField(value, validation)

    return (
        <Box sx={styles.container}>
            <Typography sx={styles.text} variant={'h6'}>
                {name}
            </Typography>
            <Tooltip title={errorMessage} open={errorMessage !== undefined} arrow={true} placement={'top'}>
                <TextInput
                    value={value}
                    setvalue={setValue}
                    sx={styles.input}
                    disabled={disabled}
                    type={type}
                    placeholder={placeholder}
                />
            </Tooltip>
        </Box>
    )
}

export default Field
