export const sectionTitle = 'Improve speech and build confidence from anywhere'

export type SectionInformation = {
    title: string
    blurb: string
}

export const allInformation: SectionInformation[] = [
    {
        title: 'Simulated Conversations',
        blurb: 'Cutting-edge AI avatars and 3D graphics simulate the real world to help you practice scenarios that are important to you.'
    },
    {
        title: 'Practice What Matters',
        blurb: 'Choose between pre-built and "make your own" scenarios to focus the topics you care about.'
    },
    {
        title: 'Difficulty Levels',
        blurb: 'Customize Speakable so that conversations are at the right level of challenge for you.'
    },
    {
        title: 'Track Progress',
        blurb: 'See your improvement over time through statistics on your speech.'
    }
]
