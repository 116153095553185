import { useEffect, useState } from 'react'
import { IValidation } from './Field.data'

export const useField = (value: string, validation?: IValidation) => {
    const [hasBeenModified, setHasBeenModified] = useState<boolean>(false)
    const [hasRecentlyBeenModified, setHasRecentlyBeenModified] = useState<boolean>(false)
    const [isValid, setIsValid] = useState<boolean>(true)

    useEffect(() => {
        let timeout: NodeJS.Timeout
        setHasRecentlyBeenModified(true)
        if (hasBeenModified) {
            timeout = setTimeout(() => {
                setHasRecentlyBeenModified(false)
            }, 1000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [value, hasBeenModified])

    useEffect(() => {
        if (value.length > 0 && !hasBeenModified) setHasBeenModified(true)
    }, [value, hasBeenModified])

    useEffect(() => {
        if (hasBeenModified && validation) {
            setIsValid(validation.function(value))
        }
    }, [value, hasBeenModified, validation])
    return { errorMessage: isValid || hasRecentlyBeenModified ? undefined : validation?.errorMessage }
}
