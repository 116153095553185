import { NonRealTimeVAD, NonRealTimeVADOptions } from '@ricky0123/vad-web'

export enum StartRecordingResponse {
    SUCCESS,
    ALREADY_RECORDING,
    NO_PERMISSION,
    UNEXPECTED_ERROR
}

export enum StopRecordingResponse {
    SUCCESS,
    MISSING_MEDIA_RECORDER,
    UNEXPECTED_ERROR
}

class Recorder {
    private static currentRecorder: Recorder | undefined
    private _mediaRecorder: MediaRecorder | undefined
    private _chunks: number[]
    private _isRecording: boolean
    private _blob: Blob | undefined
    private _isSpeaking: boolean

    public static getRecorder(): Recorder {
        Recorder.currentRecorder ??= new Recorder()
        return Recorder.currentRecorder
    }

    /**
     * USE ONLY FOR TESTING PURPOSES
     *
     */
    public static reset() {
        Recorder.currentRecorder?.resetRecorder()
        Recorder.currentRecorder = undefined
    }

    private constructor() {
        this._mediaRecorder = undefined
        this._chunks = []
        this._isRecording = false
        this._blob = undefined
        this._isSpeaking = false
    }

    private resetRecorder(): void {
        this._mediaRecorder = undefined
        this._chunks = []
        this._isRecording = false
        this._blob = undefined
        this._isSpeaking = false
    }

    /**
     * Queries the user's browser for access to the microphone.
     *
     * @returns an instance of MediaStream iff the user successfully gave Speakable access to their microphone.
     */
    private async getMediaPermissions(): Promise<MediaStream | undefined> {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
            return stream
        } catch (e) {
            this.resetRecorder()
        }
    }

    /**
     * Starts recording with the user's microphone. If permission for the microphone has not yet been determined,
     * the browser will ask the user for access to their microphone.
     *
     * @returns StartRecordingResponse.SUCCESS when the recorder starts recording. If there was a problem with starting
     * the recording, another enum value will be returned, instead.
     */
    public async startRecording(): Promise<StartRecordingResponse> {
        if (this._isRecording) return StartRecordingResponse.ALREADY_RECORDING

        const frameSamples = 1536 * 4
        const options: Partial<NonRealTimeVADOptions> = { preSpeechPadFrames: 0 }
        const context = new window.AudioContext({ sampleRate: 16_000 })

        const startupTaskPromises = [NonRealTimeVAD.new(options), this.getMediaPermissions()]
        const startupTaskResults = await Promise.all(startupTaskPromises)
        const vad = startupTaskResults[0] as NonRealTimeVAD
        const stream = startupTaskResults[1] as MediaStream | undefined
        this.resetRecorder()
        if (stream === undefined) return StartRecordingResponse.NO_PERMISSION

        this._mediaRecorder = new MediaRecorder(stream)
        let readingIx = 0
        let lastSpeechIx = -1
        this._mediaRecorder.addEventListener('dataavailable', async (event: BlobEvent) => {
            const buffer = await event.data.arrayBuffer()
            this._chunks = [...this._chunks, ...new Uint8Array(buffer)]

            try {
                const buffer = await context.decodeAudioData(new Uint8Array(this._chunks).buffer)
                let data = buffer.getChannelData(0)
                data = data.slice(data.length - frameSamples, data.length)

                let isSpeaking = lastSpeechIx != -1 && readingIx - lastSpeechIx < 5
                for await (const _ of vad.run(data, 16_000)) {
                    lastSpeechIx = readingIx
                    isSpeaking = true
                    break
                }

                this._isSpeaking = isSpeaking
                readingIx++
            } catch (e) {
                console.log(e)
            }
        })

        const startPromise = new Promise<void>((res, rej) => {
            if (this._mediaRecorder === undefined) {
                rej()
                return
            }
            this._mediaRecorder.addEventListener('start', async (_event: Event) => {
                res()
            })
        })

        try {
            this._mediaRecorder.start(0.1 * 1_000) // timeslice: 0.1s of audio into a given blob
            this._isRecording = true
        } catch (e: any) {
            this.resetRecorder()
            return StartRecordingResponse.UNEXPECTED_ERROR
        }

        try {
            await startPromise
            return StartRecordingResponse.SUCCESS
        } catch (e) {
            return StartRecordingResponse.UNEXPECTED_ERROR
        }
    }

    /**
     * Stops the recording and saves a blob as an mp3 with the resulting data.
     *
     */
    public async stopRecording(): Promise<StopRecordingResponse> {
        if (this._mediaRecorder === undefined) {
            this.resetRecorder()
            return StopRecordingResponse.MISSING_MEDIA_RECORDER
        }

        try {
            const promise = new Promise<void>((res, rej) => {
                if (this._mediaRecorder === undefined) {
                    rej()
                    return
                }
                this._mediaRecorder.addEventListener('stop', async (_event: Event) => {
                    await new Promise<void>((res) => setTimeout(res, 50)) // wait for the last bytes to flush to dataavailable event subscription
                    const array = new Uint8Array(this._chunks)
                    this._blob = new Blob([array], { type: 'audio/mp3' })
                    this._isRecording = false
                    this._isSpeaking = false
                    res()
                    return
                })
            })

            if (this._mediaRecorder.state === 'inactive') throw new Error('Inactive media recorder')
            this._mediaRecorder.stop()
            await promise
        } catch (e: any) {
            this.resetRecorder()
            return StopRecordingResponse.UNEXPECTED_ERROR
        }
        return StopRecordingResponse.SUCCESS
    }

    public get isRecording() {
        return this._isRecording
    }

    public get blob() {
        return this._blob
    }

    public get isSpeaking() {
        return this._isSpeaking
    }
}

export default Recorder
