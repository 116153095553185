import { SxProps, Theme } from '@mui/material'
import { BOLD_FONT_WEIGHT, BRIGHT_GREEN, LIGHT_BRIGHT_GREEN } from '../../../utils/constants'

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        backgroundColor: LIGHT_BRIGHT_GREEN,
        width: 'fit-content',
        p: 2,
        pl: 10,
        pr: 10
    },
    text: {
        color: BRIGHT_GREEN,
        fontWeight: BOLD_FONT_WEIGHT,
        fontSize: 10,
        userSelect: 'none'
    }
}

export default styles
