import { Box, Typography } from '@mui/material'
import styles from './styles'
import Checkbox from '../../../assets/logos/LandingPageCheck.svg'
import LandingPageButton from '../LandingPageButton'
import { useAppDispatch } from '../../../store/hooks'
import { setCurrentModal } from '../../../store/site'
import { ModalType } from '../../../store/site/data'
import usePricingCard from './PricingCard.hooks'
import { useGetAuthentication } from '../../../store/selectors/authenticationSelector'

export interface IPricingCardProps {
    title: string
    subtitle: string
    pricepermonth: string
    originalPricePerMonth?: string
    availableFeatures: string[]
}

const PricingCard = ({
    title,
    subtitle,
    pricepermonth,
    originalPricePerMonth,
    availableFeatures
}: IPricingCardProps): JSX.Element => {
    const dispatch = useAppDispatch()
    const { containerStyles, isMobile } = usePricingCard()
    const { isAuthenticated } = useGetAuthentication()

    return (
        <Box sx={containerStyles}>
            <Box sx={styles.contentContainer}>
                <PricingCard.Title
                    title={title}
                    subtitle={subtitle}
                    pricepermonth={pricepermonth}
                    originalPricePerMonth={originalPricePerMonth}
                />
                <Box sx={styles.divider} />
                <PricingCard.FeatureList availableFeatures={availableFeatures} id={title} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 20, alignItems: 'center' }}>
                <Box sx={styles.divider} />
                <LandingPageButton
                    id={'pricing-card-button'}
                    text={
                        isMobile
                            ? 'Only Available on Desktop'
                            : isAuthenticated === true
                              ? 'You are already signed in'
                              : 'Get Started Now'
                    }
                    containerOverrides={styles.buttonOverride}
                    onClick={() => dispatch(setCurrentModal(ModalType.SIGNUP))}
                    disabled={isMobile || isAuthenticated === true}
                />
            </Box>
        </Box>
    )
}

interface ITitleRowProps {
    title: string
    subtitle: string
    pricepermonth: string
    originalPricePerMonth?: string
}

const TitleRow = ({ title, subtitle, pricepermonth, originalPricePerMonth }: ITitleRowProps): JSX.Element => {
    return (
        <Box sx={styles.titleRow}>
            <Box sx={styles.titleColumn}>
                <Typography sx={styles.titleText} variant={'h4'}>
                    {title}
                </Typography>
                <Typography sx={styles.subtitleText}>{subtitle}</Typography>
            </Box>
            <Box sx={styles.priceRow}>
                {pricepermonth && <Typography sx={styles.priceText}>{pricepermonth}</Typography>}
                {originalPricePerMonth && (
                    <Typography sx={{ ...styles.priceText, textDecoration: 'line-through' }}>
                        {originalPricePerMonth}
                    </Typography>
                )}
            </Box>
        </Box>
    )
}

interface IFeatureListProps {
    availableFeatures: string[]
    id: string
}

const FeatureList = ({ availableFeatures, id }: IFeatureListProps): JSX.Element => {
    return (
        <Box sx={styles.featuresContainer}>
            {availableFeatures.map((feature: string, ix: number) => {
                return (
                    <Box sx={styles.featureItem} key={`feature ${id} ${ix}`}>
                        <Box component={'img'} src={Checkbox} onDragStart={(e) => e.preventDefault()} />
                        <Typography>{feature}</Typography>
                    </Box>
                )
            })}
        </Box>
    )
}

PricingCard.Title = TitleRow
PricingCard.FeatureList = FeatureList

export default PricingCard
