import { useLayoutEffect, useMemo, useState } from 'react'
import useIsMobile from '../../../hooks/useIsMobile'
import { useLandingPageContext } from '../../../pages/LandingPage/LandingPage.hooks'
import styles from './styles'
import baseStyles from '../styles'
import { SxProps, Theme } from '@mui/material'
import useIsSmallDesktop from '../../../hooks/useIsSmallDesktop'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import useLandingPageSectionStyling from '../hooks'
import { useAppDispatch } from '../../../store/hooks'
import { setCurrentModal } from '../../../store/site'
import { ModalType } from '../../../store/site/data'
import { useGetAuthentication } from '../../../store/selectors/authenticationSelector'
import { useNavigate } from 'react-router-dom'

const useLandingPageProductSection = () => {
    const isMobile = useIsMobile()
    const { isSmallDesktop } = useIsSmallDesktop()
    const { navbarRef, productRef } = useLandingPageContext()
    const { isAuthenticated } = useGetAuthentication()
    const { containerStyle: sectionContainerStyle } = useLandingPageSectionStyling()
    const [navbarHeight, setNavbarHeight] = useState<number | undefined>()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    // grab the navbar's height before painting
    useLayoutEffect(() => {
        const navbarEl = navbarRef.current
        if (navbarEl !== undefined) {
            setNavbarHeight(navbarEl.clientHeight)
        }
    }, [navbarRef.current?.clientHeight])

    const onClick = () => {
        if (isAuthenticated === true) {
            navigate('/home')
        } else {
            dispatch(setCurrentModal(ModalType.SIGNUP))
        }
    }

    // container styles based on window size and navbar height
    const containerStyle = {
        ...sectionContainerStyle,
        ...(isMobile ? styles.mobileContainer : styles.desktopContainer),
        height: `calc(100vh - ${navbarHeight}px)`,
        mt: 0
    } as SxProps<Theme>

    // information container style
    const informationContainerStyle = isMobile ? styles.mobileInformationContainer : styles.desktopInformationContainer

    // text styles
    const titleTextVariant: 'h1' | 'h2' = isSmallDesktop ? 'h2' : 'h1'
    const blurbTextVariant: 'h4' | 'h5' = isSmallDesktop ? 'h5' : 'h4'

    // should display image?
    const displayImage = useMemo(() => !isMobile, [isMobile])

    const buttonSize: 'medium' | 'large' = isMobile ? 'medium' : 'large'
    const isButtonEnabled = !isMobile
    const taglineTextStyles = { ...styles.taglineText }

    return {
        productRef,
        containerStyle,
        informationContainerStyle,
        titleTextVariant,
        taglineTextStyles,
        blurbTextVariant,
        displayImage,
        buttonSize,
        isButtonEnabled,
        onClick
    }
}

const useVideoContainer = () => {
    const dispatch = useAppDispatch()

    const onWatchVideo = () => {
        dispatch(setCurrentModal(ModalType.LANDING_PAGE_VIDEO))
    }

    return onWatchVideo
}

export { useLandingPageProductSection, useVideoContainer }
