import { Input, SxProps, Theme } from '@mui/material'
import { ITextInputProps } from './TextInput.data'
import { ForwardedRef, forwardRef } from 'react'
import styles from './styles'

const TextInput = (props: ITextInputProps, ref: ForwardedRef<any>): JSX.Element => {
    return (
        <Input
            ref={ref}
            placeholder={props.placeholder}
            multiline={props.multiline}
            disabled={props.disabled}
            type={props.type}
            value={props.value}
            onChange={(e) => {
                if (e.target.value === '\n') return
                props.setvalue(e.target.value)
            }}
            disableUnderline={true}
            sx={{ ...styles.input, ...props.sx } as SxProps<Theme>}
            maxRows={props.multiline ? 2 : undefined}
        />
    )
}

export default forwardRef(TextInput)
