import { useEffect, useMemo, useState } from 'react'
import useIsMobile from '../../hooks/useIsMobile'
import styles from './styles'
import {
    DESKTOP_NAV_BAR_HEIGHT,
    DESKTOP_PAGE_SIDE_PADDING,
    MOBILE_NAV_BAR_HEIGHT,
    MOBILE_PAGE_SIDE_PADDING
} from '../../utils/constants'
import { SxProps, Theme } from '@mui/material'
import { scrollThreshold } from './LandingPageNavbar/LandingPageNavbar.data'

const useShowNavbarShadow = () => {
    const [showShadow, setShowShadow] = useState<boolean>(window.scrollY >= scrollThreshold)

    const onScroll = (_event: Event) => {
        setShowShadow(window.scrollY >= scrollThreshold)
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
    }, [])

    return showShadow
}

const useNavbarStyles = () => {
    const isMobile = useIsMobile()
    const showShadow = useShowNavbarShadow()

    const baseContainerStyle = useMemo(
        () =>
            isMobile
                ? {
                      ...styles.container,
                      justifyContent: 'space-between',
                      height: MOBILE_NAV_BAR_HEIGHT,
                      pl: MOBILE_PAGE_SIDE_PADDING,
                      pr: MOBILE_PAGE_SIDE_PADDING
                  }
                : {
                      ...styles.container,
                      justifyContent: 'space-between',
                      height: DESKTOP_NAV_BAR_HEIGHT,
                      pl: DESKTOP_PAGE_SIDE_PADDING,
                      pr: DESKTOP_PAGE_SIDE_PADDING
                  },
        [isMobile]
    )

    const containerStyle = useMemo(
        () => (showShadow ? ({ ...baseContainerStyle, ...styles.showShadow } as SxProps<Theme>) : baseContainerStyle),
        [showShadow, baseContainerStyle]
    )

    const titleVariant: 'h3' | 'h4' = isMobile ? 'h4' : 'h3'

    const placeholderStyles = {
        ...styles.placeholder,
        height: isMobile ? MOBILE_NAV_BAR_HEIGHT : DESKTOP_NAV_BAR_HEIGHT
    }

    return { containerStyle, titleVariant, placeholderStyles }
}

export { useNavbarStyles, useShowNavbarShadow }
