import { Box } from '@mui/material'
import NoMobileComponent from '../../components/NoMobileComponent'
import styles from './styles'
import useMobileWrapper from './MobileWrapper.hooks'
import { MobileWrapperProps } from './MobileWrapper.data'

/**
 * Serves as a check to make sure we're only serving desktop users.
 *
 * @returns the element that represents this component
 */
const MobileWrapper = ({ children }: MobileWrapperProps): JSX.Element => {
    const { childrenContainerStyles, noMobileComponentStyles } = useMobileWrapper()

    return (
        <Box sx={styles.container}>
            <Box sx={childrenContainerStyles}>{children}</Box>
            <Box sx={noMobileComponentStyles}>
                <NoMobileComponent />
            </Box>
        </Box>
    )
}

export default MobileWrapper
