export const practiceGoals = [
    ...new Map<string, number | undefined>([
        ['Casual', 30],
        ['Regular (Recommended)', 60],
        ['Committed', 120],
        ['Custom', undefined]
    ]).entries()
]

export type PracticeGoalSelectorProps = {
    selectedGoalOption: number | undefined
    setSelectedGoalOption: React.Dispatch<React.SetStateAction<number | undefined>>
    customMinutesValue: number | undefined
}
