import { SxProps, Theme } from '@mui/material'
import { BOLD_FONT_WEIGHT } from '../../utils/constants'

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        gap: 50,
        pt: 20
    },
    leftContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'flex-start',
        width: 400,
        gap: 50
    },
    rightContainer: {
        height: '100%',
        width: `calc(100% - 400px)`
    },
    titleText: {
        fontWeight: BOLD_FONT_WEIGHT
    },
    referrerFormContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20
    }
}

export default styles
