import { Box } from '@mui/material'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styles from './styles'

const ToastController = (): JSX.Element => {
    return (
        <Box sx={styles.container}>
            <ToastContainer
                position="top-center"
                autoClose={15_000}
                style={{ width: '50vw', textAlign: 'center' }}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Box>
    )
}

export default ToastController
