import { Tab } from '../../../pages/LandingPage/LandingPage.data'

export const navbarTitle = 'Speakable'
export const navbarTitleVariant = 'h3'
export const tabTextGap = 40
export const tabTextVariant = 'h5'
export const tabs: Tab[] = ['Product', 'Features', 'Story', 'Pricing', 'Contact']
export const buttonText = 'Login'
export const buttonSize = 'small'
export const scrollThreshold = 5
