import { SxProps, Theme } from '@mui/material'

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: 1,
        p: 10,
        '&:hover': { cursor: 'pointer' }
    }
}

export default styles
