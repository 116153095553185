import { Theme } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import './theme.css'

const theme: Theme = createTheme({
    spacing: (value: string | number) => value,
    palette: {
        text: {
            primary: '#000000',
            secondary: '#000000'
        },
        primary: {
            // main: GREEN,
            // light: LIGHT_GREEN,
            // dark: DARK_GREEN,
            main: '#FFFFFF',
            light: '#FFFFFF',
            dark: '#FFFFFF'
        },
        secondary: {
            main: '#0000000',
            light: '#0000000',
            dark: '#0000000'
            // main: '#f0f0f0',  // hover color on white buttons
            // light: 'white',
            // dark: 'lightgray',
        },
        error: {
            main: '#000000',
            light: '#000000',
            dark: '#000000'
        },
        background: {
            default: '#FFFFFF'
        }
    },
    typography: {
        fontFamily: ['DM Sans'].join(','),
        button: {
            textTransform: 'none'
        },
        h1: { fontSize: 60 },
        h2: { fontSize: 40 },
        h3: { fontSize: 30 },
        h4: { fontSize: 22 },
        h5: { fontSize: 18 },
        h6: { fontSize: 16 }
    },
    components: {}
})

export default theme
