import { Box, CircularProgress, SxProps, Theme, Typography } from '@mui/material'
import styles from './styles'
import useRecorder from './Recorder.hooks'
import { IRecorderProps } from './Recorder.data'
import R from '../../utils/classes/recorder'

const Recorder = ({
    sx,
    iconSx,
    disabled,
    disableTooltip,
    onTranscription,
    setRecordingState,
    setLoadingState
}: IRecorderProps): JSX.Element => {
    const { containerStyles, isRecording, isHovering, isLoading, onClick, onMouseEnter, onMouseLeave } = useRecorder(
        onTranscription,
        setRecordingState,
        setLoadingState,
        disabled
    )

    return (
        <Box
            id={'recorder-button'}
            sx={
                {
                    ...containerStyles,
                    ...sx
                } as SxProps<Theme>
            }
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {isLoading ? (
                <CircularProgress size={'70%'} />
            ) : (
                <Box
                    component={'img'}
                    onDragStart={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                    sx={{ ...styles.image, ...iconSx } as SxProps<Theme>}
                    src={require('../../assets/logos/micLogo.png')}
                />
            )}
            {(isHovering || isRecording) && !disabled && !disableTooltip && (
                <Box sx={styles.popupContainer} onClick={(e) => e.stopPropagation()}>
                    <Typography sx={styles.popupText}>
                        {isLoading
                            ? 'Processing your audio'
                            : isRecording
                              ? 'Click again to stop recording'
                              : 'Click to start recording'}
                    </Typography>
                </Box>
            )}
        </Box>
    )
}

export default Recorder
