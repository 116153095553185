export const isValidFirstName = (firstName: string | undefined): boolean => {
    return firstName !== undefined && firstName.length > 0
}

export const isValidLastName = (lastName: string | undefined): boolean => {
    return lastName !== undefined && lastName.length > 0
}

export const isValidEmail = (email: string | undefined): boolean => {
    const acceptedRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
    return email !== undefined && acceptedRegex.test(email)
}

export const isValidPassword = (password: string | undefined): boolean => {
    return password !== undefined && password.length >= 6
}

export const isValidResetCode = (code: string): boolean => {
    return code !== undefined && code.length > 0 && !isNaN(parseInt(code))
}

export const isValidConfirmPassword = (password: string | undefined, confirmPassword: string | undefined): boolean => {
    return password !== undefined && confirmPassword !== undefined && password === confirmPassword
}
