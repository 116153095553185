import { Box, Typography } from '@mui/material'
import useLandingPageFeaturesSection from './LandingPageFeaturesSection.hooks'
import { forwardRef } from 'react'
import styles from './styles'
import baseStyles from '../styles'
import PatternOrange from '../../../assets/images/LandingPage/PatternOrange.svg'
import PatternBlue from '../../../assets/images/LandingPage/PatternBlue.svg'
import { SectionInformation, allInformation, sectionTitle } from './LandingPageFeaturesSection.data'
import LandingPageTag from '../LandingPageTag'
import FeatureInformation from './FeatureInformation'
import SpeakableImage from '../../SpeakableImage/SpeakableImage'

const LandingPageFeaturesSection = (): JSX.Element => {
    const {
        featuresRef,
        containerStyle,
        titleTextVariant,
        displayImage,
        contentContainerStyles,
        informationContainerStyles
    } = useLandingPageFeaturesSection()

    return (
        <Box ref={featuresRef} sx={containerStyle}>
            {displayImage && (
                <Box sx={styles.imageContainer}>
                    <SpeakableImage imageSx={styles.image} src={'/images/LandingPagePicture.png'} />
                    <SpeakableImage imageSx={styles.orangePattern} src={PatternOrange} disableSkeleton={true} />
                    <SpeakableImage imageSx={styles.bluePattern} src={PatternBlue} disableSkeleton={true} />
                </Box>
            )}
            <Box sx={contentContainerStyles}>
                <LandingPageTag text={'Product'} />
                <Typography sx={baseStyles.sectionTitleText} variant={titleTextVariant}>
                    {sectionTitle}
                </Typography>
                <Box sx={informationContainerStyles}>
                    {allInformation.map((information: SectionInformation, ix: number) => {
                        return (
                            <FeatureInformation
                                information={information}
                                ix={ix + 1}
                                key={`feature information section ${ix}`}
                            />
                        )
                    })}
                </Box>
            </Box>
        </Box>
    )
}

export default forwardRef(LandingPageFeaturesSection)
