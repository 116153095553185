const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '100%',
        alignItems: 'center'
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mr: 10,
        border: 1,
        borderRadius: '50%'
    },
    icon: { width: '75%', height: '75%' }
}

export default styles
