import useIsMobile from '../../hooks/useIsMobile'
import { ISpeakableLogoProps, SpeakableLogoType } from './SpeakableLogo.data'
import styles from './styles'

const useSpeakableLogo = ({ type, onClick }: ISpeakableLogoProps) => {
    const isMobile = useIsMobile()

    let style = type === SpeakableLogoType.DARK ? 'dark' : 'light'
    const imagePath = `/logos/speakable-logo-${style}.png`
    const containerStyles = {
        // aspectRatio: 827 / 140
        ...styles.container,
        cursor: onClick ? 'pointer' : 'default',
        maxWidth: isMobile ? 125 : 200,
        minWidth: isMobile ? 125 : 200,
        width: isMobile ? 125 : 200,
        maxHeight: isMobile ? 21.16 : 33.86,
        minHeight: isMobile ? 21.16 : 33.86,
        height: isMobile ? 21.16 : 33.86
    }

    return { imagePath, containerStyles }
}

export default useSpeakableLogo
