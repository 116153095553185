import { useAppDispatch } from '../../../store/hooks'
import { setCurrentModal } from '../../../store/site'
import { ModalType } from '../../../store/site/data'
import { SwitchTextType } from './SwitchText.data'

const useSwitchText = (type: SwitchTextType) => {
    const dispatch = useAppDispatch()

    let normalText = `Already have an account?`
    let switchText = `Sign in`
    if (type === SwitchTextType.LOGIN) {
        normalText = `Don't have an account?`
        switchText = `Sign up`
    } else if (type === SwitchTextType.FORGOT_PASSWORD) {
        normalText = `Back to login?`
        switchText = 'Sign in'
    }

    const onClick = () => {
        let newModal =
            type === SwitchTextType.LOGIN
                ? ModalType.SIGNUP
                : type === SwitchTextType.SIGNUP
                  ? ModalType.LOGIN
                  : ModalType.LOGIN
        dispatch(setCurrentModal(newModal))
    }

    return { normalText, switchText, onClick }
}

export default useSwitchText
