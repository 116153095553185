import { useEffect } from 'react'
import useIsMobile from '../../hooks/useIsMobile'
import styles from './styles'
import { createEvent } from '../../api/analytics/api'

/**
 * This hook contains the business logic neeeded for the MobileWrapper component.
 *
 * @returns the resources needed for the MobileWrapper component
 **/
const useMobileWrapper = () => {
    const isMobile = useIsMobile()

    // log an event whenever the mobile wrapper is loaded
    useEffect(() => {
        if (isMobile) {
            createEvent({ eventId: 'mobile-wrapper', eventType: 'load', time: new Date().getTime() })
        }
    }, [isMobile])

    // generate the styles needed for the MobileWrapper component
    const childrenContainerStyles = { ...styles.subContainer, display: isMobile ? 'none' : 'flex' }
    const noMobileComponentStyles = { ...styles.subContainer, display: isMobile ? 'flex' : 'none' }

    // return the resources
    return { childrenContainerStyles, noMobileComponentStyles }
}

export default useMobileWrapper
