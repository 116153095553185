import { Theme } from '@emotion/react'
import { SxProps } from '@mui/material'
import { BRIGHT_GREEN, DISABLED_GRAY } from '../../../utils/constants'

const verticalPadding = 10
const horizontalPadding = verticalPadding * 2

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: BRIGHT_GREEN,
        borderRadius: 2,
        p: verticalPadding,
        pl: horizontalPadding,
        pr: horizontalPadding,
        width: 'fit-content',
        '&:hover': { cursor: 'pointer' }
    },
    text: { color: 'white', userSelect: 'none' },
    disabled: {
        '&:hover': { cursor: 'default' },
        backgroundColor: DISABLED_GRAY
    }
}

export default styles
