import { Theme } from '@emotion/react'
import { SxProps } from '@mui/material'
import { componentShadow } from '../../utils/constants'

const styles: Record<string, SxProps<Theme>> = {
    container: {
        backgroundColor: 'white',
        display: 'flex',
        width: 600,
        flexDirection: 'column',
        gap: 20,
        border: 1,
        borderRadius: 2,
        alignItems: 'center',
        pl: 100,
        pr: 100,
        pt: 50,
        pb: 50,
        boxShadow: componentShadow
    },
    input: {
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        borderColor: 'black',
        border: 1,
        borderRadius: 2,
        p: 5,
        pl: 10,
        pr: 10,
        backgroundColor: 'white'
    }
}

export default styles
