import { useEffect, useState } from 'react'
import { isValidEmail, isValidPassword } from '../data'
import { signIn } from 'aws-amplify/auth'
import { toast } from 'react-toastify'
import { useAppDispatch } from '../../../store/hooks'
import { setCurrentModal } from '../../../store/site'
import { ModalType } from '../../../store/site/data'
import useIsMobile from '../../../hooks/useIsMobile'

const useLoginComponent = () => {
    const dispatch = useAppDispatch()
    const isMobile = useIsMobile()

    const [isComplete, setIsComplete] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onSignIn = async () => {
        try {
            setIsLoading(true)
            await signIn({
                username: email,
                password
            })
            dispatch(setCurrentModal(undefined))
        } catch (e: any) {
            let error = 'There was a problem logging you in.'
            switch (e.name) {
                case 'NetworkError':
                    error = 'Please connect to the internet and try again.'
                    break
                case 'UserNotFoundException':
                    error = `We couldn't find an account with that email and password.`
                    break
                case 'NotAuthorizedException':
                    error = `We couldn't find an account with that email and password.`
                    break
                default:
                    console.log(e.name)
            }
            toast.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const onForgotPassword = () => {
        dispatch(setCurrentModal(ModalType.FORGOT_PASSWORD))
    }

    useEffect(() => {
        setIsComplete(isValidEmail(email) && isValidPassword(password))
    }, [email, password])

    useEffect(() => {
        if (isMobile) {
            dispatch(setCurrentModal(undefined))
        }
    }, [isMobile])

    return { email, password, isComplete, isLoading, setEmail, setPassword, setIsLoading, onSignIn, onForgotPassword }
}

export default useLoginComponent
