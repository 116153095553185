export interface IPropViewerProps {
    prop: ConversationProp
}

export enum ConversationProp {
    CHINESE_MENU,
    MEXICAN_MENU,
    FAST_FOOD_MENU,
    FINE_DINING_MENU,
    ITALIAN_MENU,
    COFFEE_SHOP_MENU
}

export interface Prop {
    internalName: string
    externalName: string
    image: any
}

export const allProps: Array<Prop> = [
    {
        internalName: 'Chinese Menu',
        externalName: 'Menu',
        image: require('../../../assets/images/ConversationPage/Restaurant/ChineseMenu.png')
    },
    {
        internalName: 'Mexican Menu',
        externalName: 'Menu',
        image: require('../../../assets/images/ConversationPage/Restaurant/MexicanMenu.png')
    },
    {
        internalName: 'Fast Food Menu',
        externalName: 'Menu',
        image: require('../../../assets/images/ConversationPage/Restaurant/FastFoodMenu.png')
    },
    {
        internalName: 'Fine Dining Menu',
        externalName: 'Menu',
        image: require('../../../assets/images/ConversationPage/Restaurant/FineDiningMenu.png')
    },
    {
        internalName: 'Italian Menu',
        externalName: 'Menu',
        image: require('../../../assets/images/ConversationPage/Restaurant/ItalianMenu.png')
    },
    {
        internalName: 'Coffee Shop Menu',
        externalName: 'Menu',
        image: '/images/props/coffeeShopMenu.jpeg'
    }
]

export const propMap: Map<ConversationProp, Prop> = new Map([
    [ConversationProp.CHINESE_MENU, allProps[0]],
    [ConversationProp.MEXICAN_MENU, allProps[1]],
    [ConversationProp.FAST_FOOD_MENU, allProps[2]],
    [ConversationProp.FINE_DINING_MENU, allProps[3]],
    [ConversationProp.ITALIAN_MENU, allProps[4]],
    [ConversationProp.COFFEE_SHOP_MENU, allProps[5]]
])
