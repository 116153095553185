import { useNavigate } from 'react-router-dom'
import { useGetAuthentication } from '../../store/selectors/authenticationSelector'

const useNotFoundPage = () => {
    const navigate = useNavigate()
    const { isAuthenticated } = useGetAuthentication()

    const onClick = () => {
        const route = isAuthenticated === true ? '/home' : '/'
        navigate(route)
    }

    return { onClick }
}

export default useNotFoundPage
