import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { experimentalPersistConfig, initialState } from './data'
import { persistReducer } from 'redux-persist'

const experimentalSlice = createSlice({
    name: 'experimental',
    initialState,
    reducers: {
        setShowGUI(state, action: PayloadAction<boolean>) {
            state.showGUI = action.payload
        },
        toggleShowGUI(state) {
            state.showGUI = !state.showGUI
        },
        selectAvatarId(state, action: PayloadAction<number | undefined>) {
            state.selectedAvatarId = action.payload
        }
    }
})

export const { setShowGUI, toggleShowGUI, selectAvatarId } = experimentalSlice.actions
export default persistReducer(experimentalPersistConfig, experimentalSlice.reducer)
