import { Box, CircularProgress, SxProps, Theme, Typography } from '@mui/material'
import styles from './styles'
import { memo } from 'react'

type LoadingComponentProps = {
    loadingMessage: string
    sx?: SxProps<Theme>
    textSx?: SxProps<Theme>
    spinnerSize?: string | number
}

const LoadingComponent = ({ loadingMessage, sx, textSx, spinnerSize }: LoadingComponentProps): JSX.Element => {
    return (
        <Box sx={{ ...styles.container, ...sx } as SxProps<Theme>}>
            <Typography variant={'h2'} sx={{ ...styles.text, ...textSx } as SxProps<Theme>}>
                {loadingMessage}
            </Typography>
            <CircularProgress size={spinnerSize ?? '50px'} sx={styles.loadingSpinner} />
        </Box>
    )
}

export default memo(LoadingComponent)
