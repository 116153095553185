import { SxProps, Theme } from '@mui/material'
import { BLUE_GRAY, BOLD_FONT_WEIGHT, LIGHT_BLUE_GRAY } from '../../../utils/constants'

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        gap: 20,
        justifyContent: 'space-between'
    },
    linksRow: {
        display: 'flex',
        flexDirection: 'row',
        gap: 20
    },
    linksGrid: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 10
    },
    linkText: {
        color: BLUE_GRAY,
        userSelect: 'none',
        '&:hover': { cursor: 'pointer' }
    },
    corporationText: {
        color: LIGHT_BLUE_GRAY,
        fontSize: 12
    },
    title: {
        fontWeight: BOLD_FONT_WEIGHT
    }
}

export default styles
