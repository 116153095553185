import { useEffect, useState } from 'react'
import styles from './styles'
import { SxProps, Theme } from '@mui/material'
import { maxRecordingTime } from './Recorder.data'
import { LIGHT_GREEN } from '../../utils/constants'
import { toast } from 'react-toastify'
import { throwSentryException } from '../../utils/sentry'
import { transcribe } from '../../api/audio/api'
import Recorder, { StartRecordingResponse, StopRecordingResponse } from '../../utils/classes/recorder'
import { createEvent } from '../../api/analytics/api'
import { useGetAuthentication } from '../../store/selectors/authenticationSelector'

const useRecorder = (
    onTranscription: ((transcription: string) => void) | ((transcription: string) => Promise<void>),
    setRecording?: React.Dispatch<React.SetStateAction<boolean>> | undefined,
    setLoading?: React.Dispatch<React.SetStateAction<boolean>> | undefined,
    isDisabled?: boolean
) => {
    const pathname = window.location.pathname
    const [isRecording, setIsRecording] = useState<boolean>(false)
    const [isHovering, setIsHovering] = useState<boolean>(false)
    const [timePercentageLeft, setTimePercentageLeft] = useState<number>(1)
    const [timeInterval, setTimeInterval] = useState<NodeJS.Timer>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLoadingRecorder, setIsLoadingRecorder] = useState<boolean>(false)
    const { isAuthenticated } = useGetAuthentication()

    useEffect(() => {
        if (setRecording !== undefined) {
            setRecording(isRecording)
        }
    }, [isRecording])

    useEffect(() => {
        if (setLoading !== undefined) {
            setLoading(isLoading)
        }
    }, [isLoading])

    const containerStyles = {
        ...styles.container,
        ...(isLoading ? styles.loading : {}),
        ...(isRecording
            ? {
                  background: `linear-gradient(to top, ${LIGHT_GREEN} ${100 * timePercentageLeft}%, white ${
                      100 * timePercentageLeft
                  }%)`
              }
            : {}),
        ...(isDisabled ? styles.disabled : {})
    } as SxProps<Theme>

    const onClick = () => {
        createEvent(
            {
                eventId: `recorder-button-${isLoading || isDisabled ? 'disabled' : 'enabled'}-${pathname}`,
                time: new Date().getTime()
            },
            isAuthenticated === true
        )
        if (isLoading || isDisabled) return

        if (isRecording) {
            stopRecording()
        } else {
            startRecording()
        }
    }

    const startRecording = async () => {
        if (isLoadingRecorder) return
        const audioRecorder = Recorder.getRecorder()
        setIsLoadingRecorder(true)
        const startRecordingResult = await audioRecorder.startRecording()
        switch (startRecordingResult) {
            case StartRecordingResponse.ALREADY_RECORDING:
                toast.error('You are already recording! Please finish your current recording before recording again.')
                break
            case StartRecordingResponse.NO_PERMISSION:
                toast.error('You have not given Speakable permission to use your microphone.')
                break
            case StartRecordingResponse.UNEXPECTED_ERROR:
                toast.error('There was an unexpected problem while starting the recording.')
                break
            case StartRecordingResponse.SUCCESS:
                setIsRecording(true)
                const startTime = new Date().getTime()
                const intervalId = setInterval(() => {
                    const newInterval = 1 - (new Date().getTime() - startTime) / (1000 * maxRecordingTime)
                    if (newInterval < 0) {
                        clearInterval(intervalId)
                        stopRecording()
                    } else {
                        setTimePercentageLeft(newInterval)
                    }
                }, 50)
                setTimeInterval(intervalId)
                break
        }

        setIsLoadingRecorder(false)
    }

    const stopRecording = async () => {
        cleanupRecording()
        const audioRecorder = Recorder.getRecorder()
        const stopRecordingResult = await audioRecorder.stopRecording()
        switch (stopRecordingResult) {
            case StopRecordingResponse.MISSING_MEDIA_RECORDER:
                toast.error('There was an unexpected problem while stopping the recording.')
                throwSentryException({ error: 'Missing Media Recorder', hint: {} })
                break
            case StopRecordingResponse.UNEXPECTED_ERROR:
                toast.error('There was an unexpected problem while stopping the recording.')
                throwSentryException({ error: 'Unexpected Error', hint: {} })
                break
            case StopRecordingResponse.SUCCESS:
                try {
                    const blob = audioRecorder.blob
                    if (blob === undefined) {
                        toast.error('There was an unexpected problem while stopping the recording.')
                        throwSentryException({ error: 'Error getting blob', hint: {} })
                        return
                    }

                    setIsLoading(true)
                    const audio = [...new Uint8Array(await blob.arrayBuffer())]
                    if (audio.length < 1000) {
                        onTranscription('')
                        setIsLoading(false)
                        return
                    }
                    const { statusCode, body } = await transcribe({ audio })
                    if (statusCode === 200) {
                        onTranscription(body.transcription)
                    } else {
                        throw new Error()
                    }
                } catch (e: any) {
                    throwSentryException({ error: 'Error transcribing audio', hint: {} })
                    toast.error('There was an unexpected problem while processing your audio.')
                } finally {
                    setIsLoading(false)
                }
                break
        }
    }

    const cleanupRecording = () => {
        setIsRecording(false)
        clearInterval(timeInterval)
        setTimeInterval(undefined)
        setTimePercentageLeft(1)
    }

    const onMouseEnter = () => setIsHovering(true)
    const onMouseLeave = () => setIsHovering(false)

    return {
        containerStyles,
        isRecording,
        isHovering,
        timePercentageLeft,
        isLoading,
        onClick,
        onMouseEnter,
        onMouseLeave
    }
}

export default useRecorder
