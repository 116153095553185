import { Box, Skeleton } from '@mui/material'
import { ISpeakableImageProps } from './SpeakableImage.data'
import useSpeakableImage from './SpeakableImage.hooks'

const SpeakableImage = ({ imageSx, skeletonSx, disableSkeleton, onClick, src }: ISpeakableImageProps): JSX.Element => {
    const { hasLoaded, setHasLoaded } = useSpeakableImage()

    return (
        <>
            <Box
                sx={{ ...imageSx, display: hasLoaded ? 'flex' : 'none', userSelect: 'none' }}
                onClick={onClick}
                component={'img'}
                src={src}
                onLoad={() => setHasLoaded(true)}
                onDragStart={(e) => e.preventDefault()}
            />
            <Skeleton
                sx={{
                    ...(skeletonSx ?? imageSx),
                    display: hasLoaded ? 'none' : 'flex',
                    opacity: disableSkeleton ? 0 : 1
                }}
                height={'inherit'}
                animation={'wave'}
                variant={'rectangular'}
            />
        </>
    )
}

export default SpeakableImage
