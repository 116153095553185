export type SliceState = {
    sessionTime: number | undefined
    practiceGoal: number | undefined
    shouldEmailReminders: boolean | undefined
}

export const initialState: SliceState = {
    sessionTime: undefined, // The amount of time the user has spent practicing this week
    practiceGoal: undefined, // The user's practice goal in minutes
    shouldEmailReminders: undefined // Whether the user should receive email reminders
}
