import { Box, Typography } from '@mui/material'
import baseStyles from '../styles'
import LandingPageButton from '../../LandingPage/LandingPageButton'
import SwitchText from '../SwitchText'
import { SwitchTextType } from '../SwitchText/SwitchText.data'
import useForgotPasswordComponent from './ForgotPasswordComponent.hooks'
import { isValidEmail, isValidPassword, isValidResetCode } from '../data'
import Field from '../Field/Field'

const ForgotPasswordComponent = (): JSX.Element => {
    const {
        email,
        resetCode,
        newPassword,
        isValidEmail: validEmail,
        isValidChangePassword,
        isLoadingSend,
        isLoadingEnter,
        hasSentEmail,
        setResetCode,
        setEmail,
        setNewPassword,
        onSend,
        onSubmitResetCode
    } = useForgotPasswordComponent()

    return (
        <Box sx={baseStyles.container}>
            <Box sx={baseStyles.titleContainer}>
                <Typography variant={'h3'} sx={baseStyles.titleText}>
                    {'Forgot Password'}
                </Typography>
                <Typography variant={'h6'}>{`We'll send you an email with a reset code.`}</Typography>
            </Box>
            <Field
                name={'Email'}
                type={'email'}
                value={email}
                setValue={setEmail}
                validation={{ function: isValidEmail, errorMessage: 'You must enter a valid email.' }}
            />
            <LandingPageButton
                id={'send-reset-code'}
                text={`Send ${hasSentEmail ? 'Another' : ''} Reset Code`}
                onClick={onSend}
                containerOverrides={baseStyles.button}
                loading={isLoadingSend}
                disabled={!validEmail}
            />
            <Field
                name={'Reset Code'}
                value={resetCode}
                placeholder={validEmail ? undefined : 'Enter a valid email first.'}
                disabled={!validEmail}
                setValue={setResetCode}
                validation={{ function: isValidResetCode, errorMessage: 'You must enter a reset code.' }}
            />
            <Field
                name={'New Password'}
                type={'password'}
                value={newPassword}
                placeholder={validEmail ? undefined : 'Enter a valid email first.'}
                disabled={!validEmail}
                setValue={setNewPassword}
                validation={{
                    function: isValidPassword,
                    errorMessage: 'Your new password must be at least 6 characters.'
                }}
            />
            <LandingPageButton
                id={'change-password'}
                text={'Change Password'}
                onClick={onSubmitResetCode}
                containerOverrides={baseStyles.button}
                loading={isLoadingEnter}
                disabled={!isValidChangePassword}
            />
            <SwitchText type={SwitchTextType.FORGOT_PASSWORD} />
        </Box>
    )
}

export default ForgotPasswordComponent
