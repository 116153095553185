import { SxProps, Theme } from '@mui/material'
import { BOLD_FONT_WEIGHT, GREEN } from '../../utils/constants'

const sidebarSize = 10
const gapConstant = 20

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        gap: gapConstant * 2
    },
    nonTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: gapConstant * 4,
        width: '100%',
        height: '100%'
    },
    titleText: {
        fontWeight: BOLD_FONT_WEIGHT,
        fontSize: 40
    },
    sidebarContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: 125,
        height: '100%',
        justifyContent: 'space-between'
    },
    contentContainer: {
        display: 'flex',
        width: `${100 - sidebarSize}%`,
        height: 0,
        minHeight: `100%`,
        border: 1,
        borderColor: 'lightgray',
        borderRadius: 1,
        p: 20
    },
    tabTextContainer: {
        display: 'flex',
        width: 'fit-content',
        '&:hover': { cursor: 'pointer' },
        p: gapConstant / 3,
        pl: 0
    },
    tabText: {
        fontWeight: BOLD_FONT_WEIGHT,
        userSelect: 'none'
    },
    selectedTabText: {
        textUnderlineOffset: 5,
        textDecoration: 'underline',
        textDecorationColor: GREEN,
        textDecorationThickness: 2.5
    },
    tabColumn: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        gap: gapConstant / 4
    },
    bottomButtonColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    bottomText: {
        '&:hover': { cursor: 'pointer' },
        pt: gapConstant / 4,
        pb: gapConstant / 4,
        userSelect: 'none'
    }
}

export default styles
