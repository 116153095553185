import { Box, Typography } from '@mui/material'
import PracticeGoals from '../../components/PracticeGoals/PracticeGoals'
import styles from './styles'
import ListenableText from '../../components/ListenableText'
import { useNavigate } from 'react-router-dom'

const PracticeGoalPage = (): JSX.Element => {
    const navigate = useNavigate()

    return (
        <Box sx={styles.container}>
            <Box sx={styles.leftContainer}>
                <Typography sx={styles.titleText}>{'Practice Goals'}</Typography>
                <ListenableText
                    text={`Before we get started, let's set a weekly practice goal!`}
                    textSx={styles.paragraphText}
                />
                <ListenableText
                    text={`Choose from one of the preset options or create a custom goal.`}
                    textSx={styles.paragraphText}
                />
            </Box>
            <PracticeGoals onSubmit={() => navigate('/home')} />
        </Box>
    )
}

export default PracticeGoalPage
