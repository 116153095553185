import { ConversationProp } from '../../components/ConversationPage/PropViewer/PropViewer.data'
import storage from 'redux-persist/lib/storage'
import FastfoodIcon from '@mui/icons-material/Fastfood'
import StoreIcon from '@mui/icons-material/Store'
import WorkIcon from '@mui/icons-material/Work'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import MovieIcon from '@mui/icons-material/Movie'

export enum ConversationTag {
    PROFESSIONAL = 'Professional',
    FOOD = 'Food',
    HEALTH = 'Health',
    ERRANDS = 'Errands',
    SOCIAL = 'Social',
    POP_CULTURE = 'Pop Culture'
}

export const conversationTags = new Map([
    [ConversationTag.ERRANDS, StoreIcon],
    [ConversationTag.PROFESSIONAL, WorkIcon],
    [ConversationTag.FOOD, FastfoodIcon],
    [ConversationTag.HEALTH, LocalHospitalIcon],
    [ConversationTag.SOCIAL, PeopleAltIcon],
    [ConversationTag.POP_CULTURE, MovieIcon]
])

export interface ConversationDetails {
    externalName: string // what the users will see this conversation be called
    assistantId: string
    details?: {
        // (optional) more details about the conversation
        place: string
        role: string
        purpose: string
        behavior: string
    }
    tags: ConversationTag[] // tags for the conversation
    description: string[] // a user-facing description of the conversation, divided into chunks
    categoryName?: string // name of the overarching conversation topic this specific conversation belongs to (optional)
    prop?: ConversationProp // a prop for this conversation (optional)
}

export const conversationMap: Map<number, ConversationDetails> = new Map([
    [
        0,
        {
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: 'Custom Conversation',
            tags: [],
            description: []
        }
    ],
    [
        1,
        {
            assistantId: 'asst_Tcc9PV3RKJAlTkfwYfWL453Y',
            externalName: 'Custom Conversation',
            tags: [],
            description: [
                'First you will speak with an avatar to determine what conversation you would like to have.',
                'After, you will have that conversation!'
            ]
        }
    ],
    [
        2,
        {
            categoryName: 'Chinese Restaurant',
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: 'Ordering Food at a Chinese Restaruant',
            details: {
                place: 'Chinese Restaurant',
                role: 'You are a server at a restaurant that serves the MENU below.',
                purpose: "Take the user's drink and food orders.",
                behavior:
                    'You should be polite and professional. Ask the user if they have any questions about the menu or any dietary restrictions, and help them find something they like to eat. Feel free to make small talk to encourage further conversation.'
            },
            prop: ConversationProp.CHINESE_MENU,
            tags: [ConversationTag.FOOD],
            description: [
                'In this conversation, you are ordering food at a Chinese restaurant. The avatar will be your waiter or waitress and will help you order from the menu.',
                'Your task is to place your food and drink order.'
            ]
        }
    ],
    [
        3,
        {
            categoryName: 'Mexican Restaurant',
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: 'Ordering Food at a Mexican Restaruant',
            details: {
                place: 'Mexican Restaurant',
                role: 'You are a server at a restaurant that serves the MENU below.',
                purpose: "Take the user's drink and food orders.",
                behavior:
                    'You should be polite and professional. Ask the user if they have any questions about the menu or any dietary restrictions, and help them find something they like to eat. Feel free to make small talk to encourage further conversation.'
            },
            prop: ConversationProp.MEXICAN_MENU,
            tags: [ConversationTag.FOOD],
            description: [
                'In this conversation, you are ordering food at a Mexican restaurant. The avatar will be your waiter or waitress and will help you order from the menu.',
                'Your task is to place your food and drink order.'
            ]
        }
    ],
    [
        4,
        {
            categoryName: 'Fast Food Restaurant',
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: 'Ordering Food at a Fast Food Restaruant',
            details: {
                place: 'Fast Food Restaurant',
                role: 'You are a server at a restaurant that serves the MENU below.',
                purpose: "Take the user's drink and food orders.",
                behavior:
                    'You should be polite and professional. Ask the user if they have any questions about the menu or any dietary restrictions, and help them find something they like to eat. Feel free to make small talk to encourage further conversation.'
            },
            prop: ConversationProp.FAST_FOOD_MENU,
            tags: [ConversationTag.FOOD],
            description: [
                'In this conversation, you are ordering food at a Fast Food restaurant. The avatar will be the cashier and will help you order from the menu.',
                'Your task is to place your food and drink order.'
            ]
        }
    ],
    [
        5,
        {
            categoryName: 'Fine Dining Restaurant',
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: 'Ordering Food at a Fine Dining Restaruant',
            details: {
                place: 'Fine Dining Restaurant',
                role: 'You are a server at a restaurant that serves the MENU below.',
                purpose: "Take the user's drink and food orders.",
                behavior:
                    'You should be polite and professional. Ask the user if they have any questions about the menu or any dietary restrictions, and help them find something they like to eat. Feel free to make small talk to encourage further conversation.'
            },
            prop: ConversationProp.FINE_DINING_MENU,
            tags: [ConversationTag.FOOD],
            description: [
                'In this conversation, you are ordering food at a Fine Dining restaurant. The avatar will be your waiter or waitress and will help you order from the menu.',
                'Your task is to place your food and drink order.'
            ]
        }
    ],
    [
        6,
        {
            categoryName: 'Italian Restaurant',
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: 'Ordering Food at an Italian Restaruant',
            details: {
                place: 'Italian Restaurant',
                role: 'You are a server at a restaurant that serves the MENU below.',
                purpose: "Take the user's drink and food orders.",
                behavior:
                    'You should be polite and professional. Ask the user if they have any questions about the menu or any dietary restrictions, and help them find something they like to eat. Feel free to make small talk to encourage further conversation.'
            },
            prop: ConversationProp.ITALIAN_MENU,
            tags: [ConversationTag.FOOD],
            description: [
                'In this conversation, you are ordering food at an Italian restaurant. The avatar will be your waiter or waitress and will help you order from the menu.',
                'Your task is to place your food and drink order.'
            ]
        }
    ],
    [
        7,
        {
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: 'Meeting a new Person',
            tags: [ConversationTag.SOCIAL],
            description: [
                'In this conversation, you are meeting a new person. The avatar will be the new person you are meeting.'
            ]
        }
    ],
    [
        8,
        {
            assistantId: 'asst_wKqzi5Q0jAOvo2ifv0NA05Rm',
            externalName: 'Talking About Your Speech',
            tags: [ConversationTag.HEALTH],
            description: [
                'In this conversation, you will talk about your speech with the avatar.',
                'The avatar will be a supportive listener who will help you express your thoughts and feelings about your speech.'
            ]
        }
    ],
    [
        9,
        {
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: 'Opinion Sharing',
            details: {
                place: '',
                role: 'You are an inquisitive person asking the user a fun, opinion-sharing question like the one from the EXAMPLE QUESTIONs list.',
                purpose: 'Discuss a fun, opinion-sharing question with the user.',
                behavior:
                    'Begin the conversation by asking the user a question like one from the EXAMPLE QUESTIONS list. After the user replies, follow up to ask why they made their choices. After asking follow-up questions, you should either politely offer an opposing/complementary viewpoint or start a new conversation with a new question.'
            },
            tags: [ConversationTag.SOCIAL],
            description: [
                'In this conversation, you will share your opinions on fun, light-hearted topics with the avatar.',
                'The avatar will ask you questions to encourage you to share your thoughts and feelings.'
            ]
        }
    ],
    [
        10,
        {
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: 'Advising a Friend',
            tags: [ConversationTag.SOCIAL],
            description: [
                'In this conversation, you will advise a friend on a problem they are facing. The avatar will be your friend who needs your help.'
            ]
        }
    ],
    [
        11,
        {
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: 'Job Interview',
            tags: [ConversationTag.PROFESSIONAL],
            description: [
                'In this conversation, you are in a job interview. The avatar will be the interviewer asking you questions.',
                'Your task is to answer the interviewer’s questions and ask any questions you have about the job.'
            ]
        }
    ],
    [
        12,
        {
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: 'Hardware Store',
            tags: [ConversationTag.ERRANDS],
            description: [
                'In this conversation, you are at the hardware store.',
                'The avatar will be the store employee helping you find the right tools and materials for your home improvement project.'
            ]
        }
    ],
    [
        13,
        {
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: 'Dinner Party Planning',
            tags: [ConversationTag.FOOD],
            description: [
                'In this conversation, you are planning a dinner party.',
                'The avatar will be a family member who will help you decide what to make for everyone.'
            ]
        }
    ],
    [
        14,
        {
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: 'Networking Event',
            tags: [ConversationTag.PROFESSIONAL],
            description: [
                'In this conversation, you are at a networking event.',
                'The avatar will be another person at the event who you can network with.'
            ]
        }
    ],
    [
        15,
        {
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: 'Recipe Sharing',
            tags: [ConversationTag.FOOD],
            description: [
                'In this conversation, you are talking about recipes.',
                'The avatar will be a friend to share your recipes with.'
            ]
        }
    ],
    [
        16,
        {
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: 'Auto Mechanic Visit',
            tags: [ConversationTag.ERRANDS],
            description: [
                'In this conversation, you are at the auto mechanic.',
                'The avatar will be a mechanic who will answer your car-related issues or questions.'
            ]
        }
    ],
    [
        17,
        {
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: 'Dry Cleaner Visit',
            tags: [ConversationTag.ERRANDS],
            description: [
                'In this conversation, you are at the dry cleaner.',
                'The avatar will be a worker at the dry cleaner who will help you with your dry cleaning assistance.'
            ]
        }
    ],
    [
        18,
        {
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: 'Library Visit',
            tags: [ConversationTag.ERRANDS],
            description: [
                'In this conversation, you are at a library.',
                'The avatar will be a librarian who will help you find books or chat about books.'
            ]
        }
    ],
    [
        19,
        {
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: 'Making an Appointment',
            tags: [ConversationTag.HEALTH],
            description: [
                'In this conversation, you are speaking with a hospital worker.',
                `The avatar will help you schedule a doctor's appointment.`
            ]
        }
    ],
    [
        20,
        {
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: `Doctor's Office Check-In`,
            tags: [ConversationTag.HEALTH],
            description: [
                'In this conversation, you are speaking with a hospital worker.',
                `The avatar will help you schedule a doctor's appointment.`
            ]
        }
    ],
    [
        21,
        {
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: `Pet Store`,
            tags: [ConversationTag.ERRANDS],
            description: [
                'In this conversation, you are at a pet store.',
                `The avatar will be a worker at the store who will help you with your needs.`
            ]
        }
    ],
    [
        22,
        {
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: `Electronics Store`,
            tags: [ConversationTag.ERRANDS],
            description: [
                'In this conversation, you are at an electronics store.',
                `The avatar will be a worker at the store who will help you with your needs.`
            ]
        }
    ],
    [
        23,
        {
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: `Coffee Shop`,
            tags: [ConversationTag.FOOD],
            description: [
                'In this conversation, you are at a coffee shop.',
                `The avatar will be the barista who will take your order.`
            ],
            prop: ConversationProp.COFFEE_SHOP_MENU
        }
    ],
    [
        24,
        {
            assistantId: 'asst_uC2IukKBVDXQpckLrGLF3U2u',
            externalName: `Grocery Store`,
            tags: [ConversationTag.ERRANDS],
            description: [
                'In this conversation, you are at a grocery store.',
                `The avatar will be a worker at the store who will help you find what you need.`
            ]
        }
    ]
])

export enum AvatarAction {
    STANDING,
    TALKING,
    LISTENING,
    THINKING
}

export interface Customizations {
    place: string
    assistantRole: string
    purpose: string
    assistantBehavior: string
    gender: 'Male' | 'Female' | 'Unknown'
}

export enum Role {
    ASSISTANT = 'assistant',
    USER = 'user'
}

export interface VisemeInformation {
    timestamp: number // ms offset from the start of the audio,
    visemeId: number
}

export interface AvatarAudioInformation {
    audio: Blob
    visemes: VisemeInformation[]
    action: AvatarAction
}

export interface Message {
    content: string
    role: Role
}

export interface WordBoundaries {
    audioOffset: number
    boundaryType: string
    text: string
    textOffset: number
    wordLength: number
}

export interface UserMessage extends Message {
    addedToThread: boolean // has the message been added to the thread yet?
    numResponsesToGenerate: number // how many responses should be generated for this message?
}

export interface AssistantMessage extends Message {
    avatarAction: AvatarAction
    avatarId: number
    auxillaryInformation?: {
        audio: string // base64 string
        visemes: VisemeInformation[]
        wordBoundaries: WordBoundaries[]
    }
}

export type Conversation = Array<UserMessage | AssistantMessage>

export type InitializeConversationInput = {
    conversationId: number
    avatarId: number
    customizations?: Customizations
} | void

export type SerializedActionNode = {
    name: string
    _result: string | undefined
    dependencies: string[]
}

export type SliceState = {
    conversationId?: number // if doing a conversation, which one is it?
    avatarAction?: AvatarAction // what should the avatar be doing right now
    avatarId?: number // what avatar is being used in this conversation?
    customizations?: Customizations // if doing a custom conversation, how is it structured?
    threadId?: string // what thread is this conversation living on?
    runIds?: { threadId: string; runId: string }[] // the ids of the run objects that are currently being executed. This will be undefined if no run is currently being executed
    conversation?: Conversation // what is the actual content of the conversation from the thread?
    actionNodes?: SerializedActionNode[] // the (serialized) action nodes that are currently being used for generating assistant instructions
    mostRecentInstruction?: string // what was the most recent instruction we passed to the assistant?
    startTime?: number // at what time was this conversation initiated? (milliseconds since the epoch)
    error?: string // an error message showing a user-friendly message of what went wrong
}

export const initialState: SliceState = {
    conversationId: undefined,
    avatarAction: undefined,
    avatarId: undefined,
    customizations: undefined,
    threadId: undefined,
    runIds: undefined,
    conversation: undefined,
    actionNodes: undefined,
    mostRecentInstruction: undefined,
    startTime: undefined,
    error: undefined
}

export const conversationPersistConfig = {
    key: 'conversation',
    storage,
    blacklist: ['avatarAction', 'error']
}
