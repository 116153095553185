import { Box, SxProps, Theme, Typography } from '@mui/material'
import styles from './styles'
import useLandingPageNavbar from './LandingPageNavbar.hooks'
import { forwardRef } from 'react'
import { Tab } from '../../../pages/LandingPage/LandingPage.data'
import { tabs } from './LandingPageNavbar.data'
import MenuIcon from '@mui/icons-material/Menu'
import LandingPageButton from '../../LandingPage/LandingPageButton'
import { useAppDispatch } from '../../../store/hooks'
import { setCurrentModal } from '../../../store/site'
import { ModalType } from '../../../store/site/data'
import useIsSmallDesktop from '../../../hooks/useIsSmallDesktop'
import SpeakableLogo from '../../SpeakableLogo'
import { SpeakableLogoType } from '../../SpeakableLogo/SpeakableLogo.data'
import { useGetAuthentication } from '../../../store/selectors/authenticationSelector'
import { useNavigate } from 'react-router-dom'
import { signOut } from 'aws-amplify/auth'
import { createEvent } from '../../../api/analytics/api'

/**
 * Navbar for the LandingPage only.
 */
const LandingPageNavbar = (): JSX.Element => {
    const {
        navbarRef,
        currentlySelectedTab,
        onTabClick,
        allowSidebar,
        setShowSidebar,
        containerStyle,
        placeholderStyles,
        tabContainerStyles
    } = useLandingPageNavbar()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { isAuthenticated } = useGetAuthentication()
    const { isSmallDesktop } = useIsSmallDesktop()

    return (
        <Box ref={navbarRef}>
            <Box sx={containerStyle}>
                <SpeakableLogo type={SpeakableLogoType.DARK} onClick={() => onTabClick('Product')} />
                {allowSidebar ? (
                    <Box sx={styles.burgerIconContainer} onClick={() => setShowSidebar((val) => !val)}>
                        <MenuIcon sx={styles.burgerIcon} />
                    </Box>
                ) : (
                    <Box sx={tabContainerStyles}>
                        {tabs.map((tabName: Tab, ix: number) => {
                            return (
                                <Typography
                                    key={`navbar tab ${ix}`}
                                    sx={
                                        {
                                            ...styles.tabText,
                                            ...(tabName === currentlySelectedTab ? styles.selectedTabText : {})
                                        } as SxProps<Theme>
                                    }
                                    variant={isSmallDesktop ? 'h6' : 'h5'}
                                    onClick={() => onTabClick(tabName)}
                                >
                                    {tabName}
                                </Typography>
                            )
                        })}
                    </Box>
                )}
                {!allowSidebar &&
                    (isAuthenticated === true ? (
                        <Box sx={styles.buttonRow}>
                            <Typography
                                sx={styles.loginText}
                                onClick={() => {
                                    signOut()
                                }}
                            >
                                {'Sign Out'}
                            </Typography>
                            <LandingPageButton
                                id={'navbar-practice-button'}
                                text={'Practice'}
                                onClick={() => navigate('/home')}
                            />
                        </Box>
                    ) : (
                        <Box sx={styles.buttonRow}>
                            <Typography
                                sx={styles.loginText}
                                onClick={() => {
                                    createEvent({ eventId: 'navbar-login-button', time: new Date().getTime() })
                                    dispatch(setCurrentModal(ModalType.LOGIN))
                                }}
                            >
                                {'Log In'}
                            </Typography>
                            <LandingPageButton
                                id={'navbar-signup-button'}
                                text={'Sign Up'}
                                onClick={() => dispatch(setCurrentModal(ModalType.SIGNUP))}
                            />
                        </Box>
                    ))}
            </Box>
            <Box sx={placeholderStyles} />
        </Box>
    )
}

export default forwardRef(LandingPageNavbar)
