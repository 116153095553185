import { Outlet } from 'react-router-dom'
import {
    useBackgroundTasks,
    useDetermineAuthenticationState,
    useListenForAuthenticationStateChanges,
    useSessionTracker,
    useStatisticRefresher
} from './App.hooks'

const App = (): JSX.Element => {
    void useDetermineAuthenticationState()
    void useListenForAuthenticationStateChanges()
    void useBackgroundTasks()
    void useSessionTracker()
    void useStatisticRefresher()

    return <Outlet />
}

export default App
