import { SxProps, Theme } from '@mui/material'
import { BOLD_FONT_WEIGHT } from '../../utils/constants'

const leftContainerWidthPercentage = 35
const gap = 20

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap,
        width: '100%',
        height: '100%'
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: gap * 2,
        width: 0,
        minWidth: '100%',
        height: 0,
        minHeight: '100%'
    },
    divider: {
        width: '1px',
        borderLeft: 1,
        height: '100%'
    },
    leftContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: `${leftContainerWidthPercentage}%`,
        height: '100%',
        gap
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: `${100 - leftContainerWidthPercentage}%`,
        height: '100%',
        gap
    },
    conversationScrollable: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        width: '100%',
        overflow: 'auto',
        gap: gap / 2
    },
    customConversationText: {
        userSelect: 'none',
        fontSize: 20
    },
    textBackground: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0.95,
        borderRadius: 2,
        backgroundColor: 'white',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        height: '20%',
        zIndex: 2
    },
    titleText: {
        fontSize: 30,
        fontWeight: BOLD_FONT_WEIGHT
    },
    customConversationImage: {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        borderRadius: 2
    },
    customConversationButton: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '100%',
        borderRadius: 2,
        '&:hover': { cursor: 'pointer', border: 1 }
    },
    prebuiltConversationButton: {
        width: `calc(33.333% - ${gap / 3}px)`,
        aspectRatio: 1 / 1,
        position: 'relative',
        borderRadius: 2,
        '&:hover': { cursor: 'pointer', border: 1 }
    },
    prebuiltImage: {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        borderRadius: 2,
        verticalAlign: 'bottom'
    },
    tagButton: {
        maxWidth: 'fit-content',
        width: 'fit-content',
        minWidth: 'fit-content',
        maxHeight: 'fit-content',
        height: 'fit-content',
        minHeight: 'fit-content',
        pl: 12,
        pr: 12,
        pt: 8,
        pb: 8,
        boxShadow: 'none'
    },
    tagRow: { display: 'flex', flexDirection: 'row', gap: 10, flexWrap: 'wrap' },
    tagText: { fontSize: 24 },
    prebuiltConversationText: { userSelect: 'none' }
}

export default styles
