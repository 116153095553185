import { Amplify } from 'aws-amplify'
import { config } from './config'

let domainStage = ['prod', 'dev'].includes(config.name) ? config.name : process.env.REACT_APP_STAGENAME
if (config.name === 'experimental') domainStage = 'dev'

export const configure = () => {
    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolId: config.cognito.userPoolID,
                identityPoolId: config.cognito.identityPoolID,
                userPoolClientId: config.cognito.userPoolAppClientID,
                loginWith: {
                    oauth: {
                        domain: `speakable-${domainStage}-auth.auth.us-east-1.amazoncognito.com`,
                        scopes: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                        redirectSignIn: [config.baseUrl],
                        redirectSignOut: [config.baseUrl],
                        responseType: 'code'
                    }
                }
            }
        },
        API: {
            REST: {
                conversation: {
                    endpoint: config.endpoints.conversation,
                    region: config.cognito.region
                },
                user: {
                    endpoint: config.endpoints.user,
                    region: config.cognito.region
                },
                contact: {
                    endpoint: config.endpoints.contact,
                    region: config.cognito.region
                },
                auth: {
                    endpoint: config.endpoints.auth,
                    region: config.cognito.region
                },
                payment: {
                    endpoint: config.endpoints.payment,
                    region: config.cognito.region
                },
                audio: {
                    endpoint: config.endpoints.audio,
                    region: config.cognito.region
                },
                analytics: {
                    endpoint: config.endpoints.analytics,
                    region: config.cognito.region
                },
                status: {
                    endpoint: config.endpoints.status,
                    region: config.cognito.region
                },
                statistics: {
                    endpoint: config.endpoints.statistics,
                    region: config.cognito.region
                },
                goals: {
                    endpoint: config.endpoints.goals,
                    region: config.cognito.region
                }
            }
        }
    })
}
