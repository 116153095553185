import { SxProps, Theme } from '@mui/material'
import { BOLD_FONT_WEIGHT, LIGHT_BLUE_GRAY } from '../../../utils/constants'

const edgePadding = 30

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        display: 'flex',
        borderRadius: 2,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        pt: edgePadding,
        pb: edgePadding,
        gap: 20
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 20
    },
    titleText: {
        fontWeight: BOLD_FONT_WEIGHT
    },
    subtitleText: {
        fontSize: 12,
        color: LIGHT_BLUE_GRAY
    },
    titleRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        pl: edgePadding,
        pr: edgePadding
    },
    titleColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    divider: {
        width: '100%',
        height: '1px',
        border: 0.1,
        borderColor: '#EEF0F3'
    },
    featuresContainer: {
        display: 'flex',
        flexDirection: 'column',
        pl: edgePadding,
        pr: edgePadding,
        gap: 10
    },
    featureItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 20
    },
    icon: {
        userSelect: 'none',
        width: 50,
        height: 50
    },
    buttonOverride: {
        width: `calc(100% - ${edgePadding * 2}px)`
    },
    priceText: {
        fontWeight: BOLD_FONT_WEIGHT
    },
    priceRow: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10
    }
}

export default styles
