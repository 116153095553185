import { Theme } from '@emotion/react'
import { SxProps } from '@mui/material'

const gap = 20

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
        gap,
        backgroundColor: '#F7F8F9',
        p: gap * 2
    },
    button: {
        width: '100%',
        height: 45
    },
    input: {
        width: '100%'
    },
    largeInput: {
        flexGrow: 1,
        minHeight: 150,
        overflowY: 'auto'
    },
    messageRow: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        width: '100%',
        gap: 20
    }
}

export default styles
