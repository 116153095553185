import { SxProps, Theme } from '@mui/material'
import { BRIGHT_GREEN } from '../../../utils/constants'

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        gap: 5
    },
    normalText: {},
    switchText: {
        color: BRIGHT_GREEN,
        '&:hover': {
            cursor: 'pointer'
        },
        userSelect: 'none'
    }
}

export default styles
