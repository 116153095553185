import { useGetUser } from '../store/selectors/userSelector'
import { LandingPageContext } from '../pages/LandingPage/LandingPage.data'
import { useLandingPage } from '../pages/LandingPage/LandingPage.hooks'
import { NavbarVariant } from '../components/Navbar/Navbar.data'
import { LazyExoticComponent, lazy } from 'react'
import HomePage from '../pages/HomePage'
import LandingPage from '../pages/LandingPage'
import NotFoundPage from '../pages/NotFoundPage'
import OnboardingPage from '../pages/OnboardingPage'
import PrivacyPage from '../pages/PrivacyPage'
import ProfilePage from '../pages/ProfilePage'
import TOSPage from '../pages/TOSPage'
import VerificationBlockerPage from '../pages/VerificationBlockerPage'
import VerifyEmailPage from '../pages/VerifyEmailPage'
import ConversationDetailsPage from '../pages/ConversationDetailsPage'
import PracticeGoalPage from '../pages/PracticeGoalPage'
import LoadingComponent from '../components/LoadingWrapper/LoadingComponent'
const ConversationPage = lazy(() => import('../pages/ConversationPage/ConversationPage'))

const blankElement = <></>

export const allRoutes: Route[] = [
    {
        element: LandingPage,
        fallback: blankElement,
        path: '/',
        navbarVariant: NavbarVariant.LANDING,
        requiresAuthentication: false,
        requiresSubscription: false,
        requiresDesktop: false,
        requiresEmailVerification: false,
        requiresOnboarding: false,
        requiresPracticeGoal: false,
        context: {
            wrapper: LandingPageContext,
            generator: useLandingPage
        }
    },
    {
        element: LandingPage,
        fallback: blankElement,
        path: '/about',
        navbarVariant: NavbarVariant.LANDING,
        requiresAuthentication: false,
        requiresSubscription: false,
        requiresDesktop: false,
        requiresEmailVerification: false,
        requiresOnboarding: false,
        requiresPracticeGoal: false,
        context: {
            wrapper: LandingPageContext,
            generator: useLandingPage
        }
    },
    {
        element: HomePage,
        fallback: blankElement,
        path: '/home',
        navbarVariant: NavbarVariant.REGULAR,
        requiresAuthentication: true,
        requiresSubscription: true,
        requiresDesktop: true,
        requiresEmailVerification: true,
        requiresOnboarding: true,
        requiresPracticeGoal: true
    },
    {
        element: ConversationPage,
        fallback: <LoadingComponent loadingMessage={'Loading your conversation...'} />,
        path: '/conversation',
        navbarVariant: NavbarVariant.REGULAR,
        requiresAuthentication: true,
        requiresSubscription: true,
        requiresDesktop: true,
        requiresEmailVerification: true,
        requiresOnboarding: true,
        requiresPracticeGoal: true
    },
    {
        element: OnboardingPage,
        fallback: blankElement,
        path: '/onboarding',
        navbarVariant: NavbarVariant.LOGO,
        requiresAuthentication: true,
        requiresSubscription: true,
        requiresDesktop: true,
        requiresEmailVerification: true,
        requiresOnboarding: false,
        requiresPracticeGoal: false
    },
    {
        element: VerifyEmailPage,
        fallback: blankElement,
        path: '/verify-email/:emailVerificationId',
        navbarVariant: NavbarVariant.LOGO,
        requiresAuthentication: false,
        requiresSubscription: false,
        requiresDesktop: false,
        requiresEmailVerification: false,
        requiresOnboarding: false,
        requiresPracticeGoal: false
    },
    {
        element: VerificationBlockerPage,
        fallback: blankElement,
        path: '/verify-email',
        navbarVariant: NavbarVariant.LOGO,
        requiresAuthentication: true,
        requiresSubscription: false,
        requiresDesktop: true,
        requiresEmailVerification: false,
        requiresOnboarding: false,
        requiresPracticeGoal: false,
        useLoad: () => {
            const { verifiedEmail } = useGetUser()
            return verifiedEmail !== undefined
        }
    },
    {
        element: ProfilePage,
        fallback: blankElement,
        path: '/profile/:tab',
        navbarVariant: NavbarVariant.REGULAR,
        requiresAuthentication: true,
        requiresSubscription: false,
        requiresDesktop: true,
        requiresEmailVerification: true,
        requiresOnboarding: true,
        requiresPracticeGoal: true,
        useLoad: () => {
            const { firstName, email, joinedAt, subscriptionStatus } = useGetUser()
            return [firstName, email, joinedAt, subscriptionStatus].every((field) => field !== undefined)
        }
    },
    {
        element: PrivacyPage,
        fallback: blankElement,
        path: '/privacy',
        navbarVariant: NavbarVariant.LOGO,
        requiresAuthentication: false,
        requiresSubscription: false,
        requiresDesktop: false,
        requiresEmailVerification: false,
        requiresOnboarding: false,
        requiresPracticeGoal: false
    },
    {
        element: TOSPage,
        fallback: blankElement,
        path: '/terms-of-service',
        navbarVariant: NavbarVariant.LOGO,
        requiresAuthentication: false,
        requiresSubscription: false,
        requiresDesktop: false,
        requiresEmailVerification: false,
        requiresOnboarding: false,
        requiresPracticeGoal: false
    },
    {
        element: ConversationDetailsPage,
        fallback: blankElement,
        path: '/conversation-details/:conversationId',
        navbarVariant: NavbarVariant.REGULAR,
        requiresAuthentication: true,
        requiresSubscription: true,
        requiresDesktop: true,
        requiresEmailVerification: true,
        requiresOnboarding: true,
        requiresPracticeGoal: true
    },
    {
        element: PracticeGoalPage,
        fallback: blankElement,
        path: '/practice-goals',
        navbarVariant: NavbarVariant.LOGO,
        requiresAuthentication: true,
        requiresSubscription: true,
        requiresDesktop: true,
        requiresEmailVerification: true,
        requiresOnboarding: true,
        requiresPracticeGoal: false
    },
    {
        element: NotFoundPage,
        fallback: blankElement,
        path: '*',
        navbarVariant: NavbarVariant.LOGO,
        requiresAuthentication: false,
        requiresSubscription: false,
        requiresDesktop: false,
        requiresEmailVerification: false,
        requiresOnboarding: false,
        requiresPracticeGoal: false
    }
]

export type Route = {
    element: LazyExoticComponent<() => JSX.Element> | (() => JSX.Element)
    fallback: JSX.Element
    path: string
    navbarVariant: NavbarVariant
    requiresAuthentication: boolean
    requiresSubscription: boolean
    requiresDesktop: boolean
    requiresEmailVerification: boolean
    requiresOnboarding: boolean
    requiresPracticeGoal: boolean
    context?: {
        wrapper: React.Context<any>
        generator: () => any
    }
    useLoad?: () => boolean
}
