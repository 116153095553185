import { Box, CircularProgress, SxProps, Theme, Typography } from '@mui/material'
import styles from './styles'
import { DARK_GREEN, DISABLED_GRAY, GREEN, HOVER_GRAY, LIGHT_GREEN, componentShadow } from '../../utils/constants'
import { useState } from 'react'
import { createEvent } from '../../api/analytics/api'
import { useGetAuthentication } from '../../store/selectors/authenticationSelector'

type SpeakableButtonProps = {
    id: string
    text: string
    disabled?: boolean
    onClick?: () => void
    icon?: JSX.Element
    loading?: boolean
    variant?: 'important' | 'normal'
    size?: 'small' | 'medium' | 'large'
    selected?: boolean
    containerSxOverride?: SxProps<Theme>
    textSxOverride?: SxProps<Theme>
    spinnerSxOverride?: SxProps<Theme>
    testId?: string
}

const sizeScale = 80

const SpeakableButton = (props: SpeakableButtonProps) => {
    const { isAuthenticated } = useGetAuthentication()
    const [isPressed, setIsPressed] = useState<boolean>(false)

    // don't want the button to be enabled if it's disabled or in a loading state
    const effectivelyDisabled = props.disabled || props.loading

    // colors (defaults to white button)
    const normalBackgroundColor = effectivelyDisabled
        ? DISABLED_GRAY
        : props.variant === 'important'
          ? props.selected
              ? DARK_GREEN
              : GREEN
          : props.selected
            ? GREEN
            : 'white'
    const hoverBackgroundColor = props.variant === 'important' ? DARK_GREEN : HOVER_GRAY

    // sizing (defaults to medium size)
    const containerWidth =
        props.size === 'large' ? sizeScale * 3 : props.size === 'small' ? sizeScale * 2 : (sizeScale * 5) / 2
    const containerHeight =
        props.size === 'large' ? (sizeScale * 3) / 4 : props.size === 'small' ? sizeScale / 2 : (sizeScale * 5) / 8
    const spinnerSize =
        props.size === 'large'
            ? `${(sizeScale * 3) / 100}em`
            : props.size === 'small'
              ? `${sizeScale / 50}em`
              : `${(sizeScale * 5) / 2 / 100}em`
    const textVariant = props.size === 'large' ? 'h5' : props.size === 'small' ? 'h6' : 'h5'

    const baseTextStyles: SxProps<Theme> = {
        ...styles.text
    }

    const baseContainerStyles: SxProps<Theme> = {
        ...styles.container,
        boxShadow: isPressed && !effectivelyDisabled ? 'default' : componentShadow,
        translate: `0px ${isPressed && !effectivelyDisabled ? 3 : 0}px`,
        width: containerWidth,
        maxWidth: containerWidth,
        minWidth: containerWidth,
        height: containerHeight,
        maxHeight: containerHeight,
        minHeight: containerHeight,
        backgroundColor: normalBackgroundColor,
        '&:hover': {
            cursor: effectivelyDisabled ? 'default' : 'pointer',
            backgroundColor: effectivelyDisabled
                ? DISABLED_GRAY
                : props.selected
                  ? normalBackgroundColor
                  : hoverBackgroundColor
        }
    }

    const baseSpinnerStyles: SxProps<Theme> = {
        ...styles.loadingSpinner,
        ...props.spinnerSxOverride
    }

    const onClick = () => {
        createEvent({ eventId: props.id, time: new Date().getTime() }, isAuthenticated === true)
        if (effectivelyDisabled || props.onClick === undefined) return
        props.onClick()
    }

    return (
        <Box
            sx={{ ...baseContainerStyles, ...props.containerSxOverride }}
            onClick={onClick}
            onMouseDown={() => setIsPressed(true)}
            onMouseUp={() => setIsPressed(false)}
            onMouseLeave={() => setIsPressed(false)}
            data-testid={props.testId}
        >
            {props.loading ? (
                <CircularProgress sx={baseSpinnerStyles} size={spinnerSize} />
            ) : (
                <Box sx={styles.contentContainer}>
                    {props.text.length > 0 && (
                        <Typography sx={{ ...baseTextStyles, ...props.textSxOverride }} variant={textVariant}>
                            {props.text}
                        </Typography>
                    )}
                    {props.icon !== undefined && <>{props.icon}</>}
                </Box>
            )}
        </Box>
    )
}

export default SpeakableButton
