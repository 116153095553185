import { SxProps, Theme } from '@mui/material'

export enum SwitchTextType {
    LOGIN,
    SIGNUP,
    FORGOT_PASSWORD
}

export interface ISwitchTextProps {
    type: SwitchTextType
    textSx?: SxProps<Theme>
}
