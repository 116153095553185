import { SxProps, Theme } from '@mui/material'
import { BRIGHT_GREEN, DARK_GREEN, GREEN } from '../../../utils/constants'

const styles: { [name: string]: SxProps<Theme> } = {
    forgotPasswordText: {
        alignSelf: 'flex-start',
        fontSize: 12,
        color: BRIGHT_GREEN,
        userSelect: 'none',
        '&:hover': { cursor: 'pointer' }
    },
    passwordWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 10
    }
}

export default styles
