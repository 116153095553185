import { Box, Typography } from '@mui/material'
import useConversationDetailsPage from './ConversationDetailsPage.hooks'
import styles from './styles'
import SpeakableButton from '../../components/SpeakableButton'
import ListenableText from '../../components/ListenableText'
import SpeakableImage from '../../components/SpeakableImage'

/**
 * This page is where conversation details are displayed. It shows the conversation's name, description, and an image of the scenario.
 *
 * @returns the element that represents the conversation details page
 */
const ConversationDetailsPage = (): JSX.Element => {
    const { conversationDetails, imageSrc, onStart, onBack } = useConversationDetailsPage()

    if (conversationDetails === undefined) {
        return (
            <Box sx={styles.notFoundContainer}>
                <Typography sx={styles.titleText}>
                    {"Oops! Looks like the page you're looking for doesn't exist!"}
                </Typography>
                <SpeakableButton
                    id={'conversation-details-not-found-back'}
                    onClick={onBack}
                    text={'Home'}
                    variant={'important'}
                />
            </Box>
        )
    }

    return (
        <Box sx={styles.container}>
            <Box sx={styles.leftSide}>
                <Typography sx={styles.titleText}>{conversationDetails.externalName}</Typography>
                <Box sx={styles.blurbContainer}>
                    {conversationDetails.description.map((blurb, index) => {
                        return (
                            <ListenableText
                                size={'medium'}
                                key={`blurb ${index}`}
                                text={blurb}
                                textSx={{ fontSize: 24 }}
                            />
                        )
                    })}
                </Box>
                <Box sx={styles.buttonRow}>
                    <SpeakableButton id={'conversation-details-back'} onClick={onBack} text={'Back'} />
                    <SpeakableButton
                        id={'conversation-details-start'}
                        onClick={onStart}
                        text={'Start'}
                        variant={'important'}
                    />
                </Box>
            </Box>
            <SpeakableImage src={imageSrc} imageSx={styles.rightSide} />
        </Box>
    )
}

export default ConversationDetailsPage
