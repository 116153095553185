import { Theme } from '@emotion/react'
import { SxProps } from '@mui/material'
import { BOLD_FONT_WEIGHT, LIGHT_BLUE_GRAY } from '../../../utils/constants'

const styles: { [name: string]: SxProps<Theme> } = {
    titleText: {
        color: 'white',
        fontWeight: BOLD_FONT_WEIGHT
    },
    subtitleText: {
        color: LIGHT_BLUE_GRAY
    },
    cardRow: {
        display: 'flex',
        flexDirection: 'row',
        gap: 20,
        width: '100%',
        paddingBottom: 100
    }
}

export default styles
