import { Box } from '@mui/material'
import styles from './styles'
import { PracticeGoalSelectorProps, practiceGoals } from './PracticeGoalSelector.data'
import PracticeGoalOption from '../PracticeGoalOption'

const PracticeGoalSelector = ({
    selectedGoalOption,
    setSelectedGoalOption,
    customMinutesValue
}: PracticeGoalSelectorProps): JSX.Element => {
    return (
        <Box sx={styles.container}>
            {practiceGoals.map((practiceGoal, ix) => {
                const choiceName = practiceGoal[0]
                const choiceOption = practiceGoal[1]
                return (
                    <PracticeGoalOption
                        key={`practice-goal-option-${ix}`}
                        name={choiceName}
                        option={choiceOption ?? customMinutesValue}
                        selected={selectedGoalOption === ix}
                        borderType={
                            ix === 0 ? 'top-rounded' : ix === practiceGoals.length - 1 ? 'bottom-rounded' : 'straight'
                        }
                        onClick={() => setSelectedGoalOption(ix)}
                    />
                )
            })}
        </Box>
    )
}

export default PracticeGoalSelector
