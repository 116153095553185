import { SxProps, Theme } from '@mui/material'
import useIsMobile from '../../../hooks/useIsMobile'
import { useLandingPageContext } from '../../../pages/LandingPage/LandingPage.hooks'
import useLandingSectionStyling from '../hooks'
import styles from './styles'

const useLandingPageContactSection = () => {
    const { paragraphVariant, titleTextVariant, containerStyle: baseContainerStyle } = useLandingSectionStyling()
    const { contactRef } = useLandingPageContext()
    const isMobile = useIsMobile()
    const containerStyle = {
        ...baseContainerStyle,
        gap: 50,
        flexDirection: isMobile ? 'column' : 'row'
    } as SxProps<Theme>
    const contentContainerStyles = { ...styles.contentContainer, width: isMobile ? '100%' : '50%' } as SxProps<Theme>
    const formStyles = { ...styles.form, width: isMobile ? '100%' : '50%' } as SxProps<Theme>
    return { contactRef, paragraphVariant, titleTextVariant, containerStyle, contentContainerStyles, formStyles }
}

export default useLandingPageContactSection
