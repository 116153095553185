import { z } from 'zod'
import { BadRequestResponse, Message } from './types'

/**
 * Validates an object against the provided zod schema.
 *
 * @param schema the zod schema to validate the request body against
 * @param object the object to validate
 * @returns the result of the validation
 */
export const isValidObject = (schema: z.ZodTypeAny, object: any): z.SafeParseReturnType<any, any> => {
    return schema.safeParse(object)
}

/**
 * Validates the request body and query parameters against the provided zod schemas.
 *
 * @param body the request body
 * @param queryParams the query parameters
 * @param bodySchema (optional) the zod schema to validate the request body against
 * @param queryParamsSchema (optional) the zod schema to validate the query parameters against
 * @returns an object with a status code and a message if the payload is invalid, otherwise undefined
 */
export const validatePayload = ({
    body,
    queryParams
}: {
    body?: {
        payload: any
        schema: z.ZodTypeAny
    }
    queryParams?: {
        payload: any
        schema: z.ZodTypeAny
    }
}): BadRequestResponse<Message> | undefined => {
    // validate the request body
    if (body !== undefined) {
        const bodyValidation = isValidObject(body.schema, body.payload)
        if (!bodyValidation.success) {
            return {
                statusCode: 400,
                body: { message: `Invalid request body: ${bodyValidation.error}` }
            }
        }
    }

    // validate the query parameters
    if (queryParams !== undefined) {
        const queryParamsValidation = isValidObject(queryParams.schema, queryParams.payload)
        if (!queryParamsValidation.success) {
            return {
                statusCode: 400,
                body: { message: `Invalid query parameters: ${queryParamsValidation.error}` }
            }
        }
    }
}

/**
 * Determines if the response body is exempt from validation based on the status code.
 *
 * @param statusCode the status code of the response
 * @returns true if the response body is exempt from validation, otherwise false
 */
export const isExemptFromResponseBodyValidation = (statusCode: number): boolean => {
    return [500, 499, 400].includes(statusCode)
}
