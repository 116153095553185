import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState } from './data'

const userSettingsSlice = createSlice({
    name: 'userSettings',
    initialState,
    reducers: {
        setUserSettings(state, action: PayloadAction<Record<string, any>>) {
            const p = action.payload
            state.closedCaptions = p.closedCaptions ?? state.closedCaptions
            state.questionFrequency = p.questionFrequency ?? state.questionFrequency
            state.responseComplexity = p.responseComplexity ?? state.responseComplexity
            state.responseLength = p.responseLength ?? state.responseLength
            state.allowConversationAnalysis = p.allowConversationAnalysis ?? state.allowConversationAnalysis
            state.autoRecordEnabled = p.autoRecordEnabled ?? state.autoRecordEnabled
        },
        resetUserSettingsData(state) {
            state.closedCaptions = initialState.closedCaptions
            state.questionFrequency = initialState.questionFrequency
            state.responseComplexity = initialState.responseComplexity
            state.responseLength = initialState.responseLength
            state.allowConversationAnalysis = initialState.allowConversationAnalysis
        }
    }
})

export const { resetUserSettingsData, setUserSettings } = userSettingsSlice.actions
export default userSettingsSlice.reducer
