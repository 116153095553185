import { SxProps, Theme } from '@mui/material'
import { BOLD_FONT_WEIGHT } from '../../utils/constants'

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        opacity: 1,
        border: 1,
        width: 600,
        gap: 12,
        borderRadius: 2,
        p: 40,
        pl: 80,
        pr: 80
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 5
    },
    button: {
        width: '100%',
        height: 45
    },
    titleText: {
        fontWeight: BOLD_FONT_WEIGHT
    },
    dividerRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 20,
        width: '100%'
    },
    divider: {
        height: '1px',
        flex: 1,
        backgroundColor: 'lightgray'
    }
}

export default styles
