import { ApiError, post } from 'aws-amplify/api'
import {
    SendContactFormRequest,
    SendClinicianFormRequest,
    SendContactFormResponse,
    SendClinicianFormResponse
} from '@speakable/speakable-service-contracts/lib/contact'

export const sendMessage = async (requestBody: SendContactFormRequest): Promise<SendContactFormResponse> => {
    try {
        const { response: promise } = post({
            apiName: 'contact',
            path: '/contact-form',
            options: { body: requestBody }
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as SendContactFormResponse
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as SendContactFormResponse
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}

export const sendForm = async (requestBody: SendClinicianFormRequest): Promise<SendClinicianFormResponse> => {
    try {
        const { response: promise } = post({
            apiName: 'contact',
            path: '/clinician-form',
            options: { body: requestBody }
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as SendClinicianFormResponse
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as SendClinicianFormResponse
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}
