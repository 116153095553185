import { SxProps, Theme } from '@mui/material'
import { BOLD_FONT_WEIGHT, DESKTOP_PAGE_SIDE_PADDING } from '../../utils/constants'

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    sectionTitleText: {
        fontWeight: BOLD_FONT_WEIGHT,
        userSelect: 'none'
    },
    paragraphText: {
        lineHeight: 1.3
    }
}

export default styles
