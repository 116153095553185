import useSpeakableLogo from './SpeakableLogo.hooks'
import { ISpeakableLogoProps } from './SpeakableLogo.data'
import SpeakableImage from '../SpeakableImage/SpeakableImage'
import { createEvent } from '../../api/analytics/api'
import { useGetAuthentication } from '../../store/selectors/authenticationSelector'

const SpeakableLogo = ({ type, onClick }: ISpeakableLogoProps): JSX.Element => {
    const { imagePath, containerStyles } = useSpeakableLogo({ type, onClick })
    const { isAuthenticated } = useGetAuthentication()

    return (
        <SpeakableImage
            src={imagePath}
            imageSx={containerStyles}
            onClick={() => {
                createEvent({ eventId: 'speakable-logo-click', time: new Date().getTime() }, isAuthenticated === true)
                if (onClick) onClick()
            }}
            disableSkeleton={true}
        />
    )
}

export default SpeakableLogo
