import LandingPageNavbar from './LandingPageNavbar'
import { NavbarVariant } from './Navbar.data'
import RegularNavbar from './RegularNavbar/RegularNavbar'

/*
    Requirements:
        (1) If the user is on the LandingPage, display the LandingPageNavbar

        (2) If the user is on either the Privacy Page or the Terms of Service Page, display a special Navbar with just the logo

        (3) Otherwise display the normal Navbar
*/

const Navbar = ({ variant }: { variant: NavbarVariant }): JSX.Element => {
    switch (variant) {
        case NavbarVariant.LANDING:
            return <LandingPageNavbar />
        case NavbarVariant.REGULAR:
            return <RegularNavbar />
        case NavbarVariant.LOGO:
            return <RegularNavbar withoutButtons={true} />
        default:
            return <RegularNavbar />
    }
}

export default Navbar
