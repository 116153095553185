import { Box, Checkbox, Input, Typography } from '@mui/material'
import { continueButtonText, firstTimeTitle, saveButtonText } from './PracticeGoals.data'
import styles from './styles'
import SpeakableButton from '../SpeakableButton'
import { useState } from 'react'
import PracticeGoalSelector from './PracticeGoalSelector'
import { BOLD_FONT_WEIGHT, DARK_GREEN } from '../../utils/constants'
import ListenableText from '../ListenableText'
import { useGetGoals } from '../../store/selectors/goalsSelector'
import { setGoals } from '../../store/goals'
import { useAppDispatch } from '../../store/hooks'
import { updateGoals } from '../../api/goals/api'
import { setCurrentModal } from '../../store/site'

const goalMap = new Map<number, number>([
    [30, 0],
    [60, 1],
    [120, 2]
])

const PracticeGoals = (props?: { onSubmit: () => void }): JSX.Element => {
    const dispatch = useAppDispatch()
    const { practiceGoal, shouldEmailReminders } = useGetGoals()
    const isPreset = practiceGoal !== undefined && [30, 60, 120].includes(practiceGoal)
    const [selectedGoalOption, setSelectedGoalOption] = useState<number | undefined>(
        isPreset ? goalMap.get(practiceGoal) : practiceGoal !== undefined ? 3 : 1
    )
    const [customValue, setCustomValue] = useState<string>(
        !isPreset && practiceGoal !== undefined ? practiceGoal.toString() : ''
    )
    const [emailPracticeGoalReminders, setEmailPracticeGoalReminders] = useState<boolean>(shouldEmailReminders ?? true)
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const canContinue = selectedGoalOption !== undefined && !(selectedGoalOption === 3 && customValue === '')

    return (
        <Box sx={styles.container}>
            <ListenableText textSx={{ fontWeight: BOLD_FONT_WEIGHT, fontSize: 24 }} text={firstTimeTitle} />
            <PracticeGoalSelector
                selectedGoalOption={selectedGoalOption}
                setSelectedGoalOption={setSelectedGoalOption}
                customMinutesValue={isNaN(parseInt(customValue)) ? undefined : parseInt(customValue)}
            />
            {selectedGoalOption === 3 && (
                <Input
                    placeholder={'Minutes / week (max 600 minutes)'}
                    value={customValue}
                    onChange={(e) => {
                        if (
                            e.target.value === '' ||
                            (/^\d+$/.test(e.target.value) &&
                                parseInt(e.target.value) > 0 &&
                                parseInt(e.target.value) <= 600)
                        ) {
                            setCustomValue(e.target.value)
                        }
                    }}
                    disableUnderline={true}
                    sx={styles.input}
                />
            )}
            <Typography sx={{ fontWeight: BOLD_FONT_WEIGHT }}>
                {'You can always change practice goals later!'}
            </Typography>
            <SpeakableButton
                id={'practice-goals-continue'}
                variant={'important'}
                disabled={!canContinue}
                text={practiceGoal === undefined ? continueButtonText : saveButtonText}
                onClick={() => {
                    const newPracticeGoal =
                        selectedGoalOption === 3 ? parseInt(customValue) : [30, 60, 120][selectedGoalOption!]
                    dispatch(
                        setGoals({
                            practiceGoal: newPracticeGoal,
                            shouldEmailReminders: emailPracticeGoalReminders
                        })
                    )
                    updateGoals({ practiceGoal: newPracticeGoal, shouldEmailReminders: emailPracticeGoalReminders })
                    dispatch(setCurrentModal(undefined))
                    props?.onSubmit?.()
                }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
                <Checkbox
                    checked={emailPracticeGoalReminders}
                    onChange={(e) => setEmailPracticeGoalReminders(e.target.checked)}
                    sx={{ color: DARK_GREEN }}
                    color={'default'}
                />
                <Typography>{'Email me practice goal reminders'}</Typography>
            </Box>
        </Box>
    )
}

export default PracticeGoals
