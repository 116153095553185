import { SxProps, Theme } from '@mui/material'

const styles: { [name: string]: SxProps<Theme> } = {
    nameRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: 15
    }
}

export default styles
