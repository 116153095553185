import { speakableAPIGetRequest } from '../../utils/requests'
import {
    getStatusQueryParamsSchema,
    getStatusResponseBodySchema
} from '@speakable/speakable-service-contracts/lib/status/conversation'

/**
 * Gets the status of the overall site (but more likely the conversation service).
 *
 * @returns the status of the site, either 'operational' or 'down'
 */
export const getStatus = speakableAPIGetRequest({
    input: {
        id: 'getStatus',
        apiName: 'status',
        path: '/conversation'
    },
    validation: {
        queryParamsSchema: getStatusQueryParamsSchema,
        responseBodySchema: getStatusResponseBodySchema
    }
})
