import { throwSentryException } from '../sentry'

export type PlayInput = {
    source: string // the source to be played. If no source, will try to continue playing the current source
    speed?: number // must be in the range (0, 1], defaults to 1
    pause?: {
        breakPoints: number[] // the breakpoints to pause at, in milliseconds
        pauseLength: number // the length of the pause, in milliseconds
    }
}

export enum StartPlaybackResponse {
    SUCCESS = 'success',
    ALREADY_PLAYING = 'already playing',
    UNEXPECTED_ERROR = 'unexpected error',
    NOT_ALLOWED_ERROR = 'not allowed error'
}

export default class AudioPlayer {
    private static existingInstance: AudioPlayer | undefined

    // the actual html element to play audio
    private readonly audio: HTMLAudioElement
    private _isPlaying: boolean

    /**
     *
     * @param source (optional) source to load into the audio player
     */
    private constructor() {
        this.audio = new Audio()
        this._isPlaying = false
    }

    /**
     *
     * @returns singleton instance of `AudioPlayer`
     */
    public static GetAudioPlayer(): AudioPlayer {
        this.existingInstance ??= new AudioPlayer()
        return this.existingInstance
    }

    /**
     * Plays the audio at the given speed, and with the given source. Returns a promise
     * when the audio is either finished playing, or something went wrong.
     */
    public async play({ speed, source, pause }: PlayInput): Promise<StartPlaybackResponse> {
        try {
            if (this.isPlaying) return StartPlaybackResponse.ALREADY_PLAYING

            this.audio.src = source
            this.audio.preservesPitch = true
            this.audio.playbackRate = speed ?? 1

            // will reject if something went wrong, otherwise we set isPlaying to true
            const audioPromise = this.audio.play()
            const promiseResult = await audioPromise.then(
                () => {
                    this._isPlaying = true
                },
                (error) => {
                    if (error.name === 'NotAllowedError') {
                        return StartPlaybackResponse.NOT_ALLOWED_ERROR
                    } else {
                        throwSentryException({ error: `Could not initiate playback. Error: ${error}` })
                        return StartPlaybackResponse.UNEXPECTED_ERROR
                    }
                }
            )

            if (
                promiseResult === StartPlaybackResponse.NOT_ALLOWED_ERROR ||
                promiseResult === StartPlaybackResponse.UNEXPECTED_ERROR
            )
                return promiseResult

            let breakPointIx = 0
            if (pause !== undefined) {
                this.audio.addEventListener('timeupdate', () => {
                    if (this.audio.currentTime * 1_000 >= pause.breakPoints[breakPointIx]) {
                        breakPointIx++
                        this.audio.pause()
                        setTimeout(() => {
                            this.audio.play()
                        }, pause.pauseLength)
                    }
                })
            }

            const endPromise = new Promise<void>((res) => {
                const handleEnd = () => {
                    this._isPlaying = false
                    this.audio.removeEventListener('ended', handleEnd)
                    res()
                }
                this.audio.addEventListener('ended', handleEnd)
            })

            // wait for the audio to finish
            await endPromise
            return StartPlaybackResponse.SUCCESS
        } catch (e) {
            return StartPlaybackResponse.UNEXPECTED_ERROR
        }
    }

    public stop(): void {
        if (!this.audio.paused) {
            this.audio.pause()
            this.audio.currentTime = 0
            this._isPlaying = false
        }
    }

    /**
     * Gets the current playback time.
     *
     * @returns the current playback time of the audio, in milliseconds.
     */
    public getCurrentTime(): number {
        return this.audio.currentTime * 1_000
    }

    public get isPlaying() {
        return this._isPlaying
    }
}
