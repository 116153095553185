import { ApiError, get, post } from 'aws-amplify/api'

export const createConversation = async (
    requestBody:
        | {
              conversationId: number
              conversation: { role: 'user' | 'assistant'; message: string }[]
              startDateString: string
              endDateString: string
          }
        | {
              conversationId: number
              conversation: { role: 'user' | 'assistant'; message: string }[]
              startDateString: string
              endDateString: string
              customizations: {
                  place: string
                  assistantRole: string
                  purpose: string
                  assistantBehavior: string
                  gender: string
              }
          }
): Promise<any> => {
    try {
        const { response: promise } = post({
            apiName: 'statistics',
            path: '/conversation',
            options: { body: requestBody }
        })

        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as any
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as any
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}

// @todo add to lib
export const getPracticeHistory = async ({
    startDateString,
    endDateString
}: {
    startDateString: string
    endDateString: string
}): Promise<any> => {
    try {
        const { response: promise } = get({
            apiName: 'statistics',
            path: `/progress`,
            options: { queryParams: { startDateString, endDateString } }
        })

        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as any
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as any
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}

export const acknowledgeBadge = async ({
    achievementId,
    achievedAt
}: {
    achievementId: number
    achievedAt: number
}): Promise<any> => {
    try {
        const { response: promise } = post({
            apiName: 'statistics',
            path: `/achievement/acknowledge`,
            options: { body: { achievementId, achievedAt } }
        })

        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as any
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as any
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}
