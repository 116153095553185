import { useNavigate } from 'react-router-dom'

const useNotAuthorizedComponent = () => {
    const navigate = useNavigate()

    const onClick = () => {
        navigate('/')
    }

    return { onClick }
}

export default useNotAuthorizedComponent
