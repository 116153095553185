import { useRef, useState } from 'react'
import { sendMessage } from '../../api/contact/api'
import { useGetUser } from '../../store/selectors/userSelector'
import { toast } from 'react-toastify'
import styles from './styles'
import { BRIGHT_GREEN, DISABLED_GRAY } from '../../utils/constants'

const useContactForm = (withoutEmail?: boolean) => {
    const [email, setEmail] = useState<string>('')
    const [message, setMessage] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>()
    const { email: userEmail } = useGetUser()

    const senderEmail = withoutEmail && userEmail ? userEmail : (email as string)
    const canSubmit = [senderEmail, message].every((val) => val.length > 0)

    const onSubmit = async (): Promise<void> => {
        if (canSubmit) {
            setIsLoading(true)
            try {
                await sendMessage({ senderEmail, message })
                toast.success('Message successfully sent!')
                setMessage('')
                setEmail('')
            } catch (e) {
                toast.error('There was an error sending your message.')
            } finally {
                setIsLoading(false)
            }
        }
    }

    const onTranscription = (transcription: string) => {
        setMessage(transcription)
    }

    const buttonStyles = {
        ...styles.button,
        backgroundColor: canSubmit ? BRIGHT_GREEN : DISABLED_GRAY,
        '&:hover': { cursor: canSubmit ? 'pointer' : 'default' }
    }

    return { buttonStyles, onSubmit, onTranscription, email, setEmail, message, setMessage, isLoading }
}

const useDisableStates = () => {
    const [isRecording, setIsRecording] = useState<boolean>(false)
    const [isProcessingAudio, setIsProcessingAudio] = useState<boolean>(false)

    return { isRecording, setIsRecording, isProcessingAudio, setIsProcessingAudio }
}

export { useContactForm, useDisableStates }
