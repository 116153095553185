import { ConversationTag, conversationMap } from '../../store/conversation/data'

export const allTags = [
    ConversationTag.ERRANDS,
    ConversationTag.PROFESSIONAL,
    ConversationTag.SOCIAL,
    ConversationTag.FOOD,
    ConversationTag.HEALTH,
    ConversationTag.POP_CULTURE
].filter((tag) => [...conversationMap.values()].some((conversation) => conversation.tags.includes(tag)))

export const customConversationImageFilepath = 'images/scenarios/scenario-1.png'

export const prebuiltConversationImageFilepath = (conversationId: number) =>
    `images/scenarios/scenario-${conversationId}.png`
