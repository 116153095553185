import { Box, SwipeableDrawer, Typography } from '@mui/material'
import { useLandingPageContext } from '../../../pages/LandingPage/LandingPage.hooks'
import styles from './styles'
import { tabs } from './LandingPageSidebar.data'
import { Tab } from '../../../pages/LandingPage/LandingPage.data'
import CloseIcon from '@mui/icons-material/Close'

const LandingPageSidebar = (): JSX.Element => {
    const { currentlySelectedTab, showSidebar, setShowSidebar, onTabClick } = useLandingPageContext()

    return (
        <SwipeableDrawer
            anchor={'right'}
            open={showSidebar}
            onClose={() => setShowSidebar(false)}
            onOpen={() => setShowSidebar(true)}
            sx={styles.container}
        >
            <Box sx={styles.content}>
                <CloseIcon sx={styles.closeIcon} onClick={() => setShowSidebar(false)} />
                {tabs.map((tab: Tab, ix: number) => {
                    return (
                        <Typography
                            key={`nav tab ${ix}`}
                            sx={tab === currentlySelectedTab ? styles.selectedTabText : styles.unselectedTabText}
                            onClick={() => {
                                onTabClick(tab)
                                setShowSidebar(false)
                            }}
                        >
                            {tab}
                        </Typography>
                    )
                })}
            </Box>
        </SwipeableDrawer>
    )
}

export default LandingPageSidebar
