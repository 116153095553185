import { Box, Typography } from '@mui/material'
import useLandingPagePricingSection from './LandingPagePricingSection.hooks'
import { forwardRef } from 'react'
import { sectionSubtitle, sectionTitle } from './LandingPagePricingSection.data'
import LandingPageTag from '../LandingPageTag'
import styles from './styles'
import PricingCard from '../PricingCard'

const LandingPagePricingSection = (): JSX.Element => {
    const { pricingRef, titleTextVariant, paragraphVariant, containerStyle, cardRowStyles } =
        useLandingPagePricingSection()

    return (
        <Box sx={containerStyle} ref={pricingRef}>
            <LandingPageTag text={'Pricing'} />
            <Typography sx={styles.titleText} variant={'h2'}>
                {sectionTitle}
            </Typography>
            <Typography sx={styles.subtitleText} variant={'h5'}>
                {sectionSubtitle}
            </Typography>
            <Box sx={cardRowStyles}>
                <PricingCard
                    title={'Free Trial'}
                    pricepermonth={'Free'}
                    subtitle={'Experience Speakable risk-free'}
                    availableFeatures={['2 week trial access', 'No credit card']}
                />
                <PricingCard
                    title={'Monthly'}
                    pricepermonth={'$19.99/mo'}
                    originalPricePerMonth={'$30/mo'}
                    subtitle={'Only $0.67/day for a limited time'}
                    availableFeatures={[
                        'Unlimited access',
                        'Custom and pre-built scenarios',
                        // 'Instant feedback and coaching',
                        'Optional 15-min onboarding session with a team member'
                    ]}
                />
            </Box>
        </Box>
    )
}

export default forwardRef(LandingPagePricingSection)
