import { Box, CircularProgress, SxProps, Theme, Typography } from '@mui/material'
import styles from './styles'
import { createEvent } from '../../../api/analytics/api'
import { useGetAuthentication } from '../../../store/selectors/authenticationSelector'

export interface LandingPageButtonProps {
    id: string
    onClick: () => void
    text: string
    containerOverrides?: SxProps<Theme>
    disabled?: boolean
    loading?: boolean
}

const LandingPageButton = ({
    id,
    onClick: passedOnClick,
    text,
    containerOverrides,
    disabled,
    loading
}: LandingPageButtonProps): JSX.Element => {
    const { isAuthenticated } = useGetAuthentication()
    const containerStyles = { ...styles.container, ...(loading || disabled ? styles.disabled : {}) } as SxProps<Theme>

    const onClick = () => {
        createEvent({ eventId: id, time: new Date().getTime() }, isAuthenticated === true)
        if (!loading && !disabled) passedOnClick()
    }

    return (
        <Box sx={{ ...containerStyles, ...containerOverrides } as SxProps<Theme>} onClick={onClick}>
            {loading ? <CircularProgress size={'20px'} /> : <Typography sx={styles.text}>{text}</Typography>}
        </Box>
    )
}

export default LandingPageButton
