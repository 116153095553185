import { SxProps, Theme } from '@mui/material'
import { BOLD_FONT_WEIGHT } from '../../utils/constants'

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        gap: 40,
        width: '100%',
        height: '100%'
    },
    titleText: {
        fontSize: 30,
        alignSelf: 'center',
        fontWeight: BOLD_FONT_WEIGHT
    },
    buttonRow: {
        display: 'flex',
        alignSelf: 'center',
        flexDirection: 'row',
        gap: 20
    },
    leftSide: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        width: '50%'
    },
    blurbContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        border: 1,
        borderColor: 'lightgray',
        borderRadius: 2,
        height: '100%',
        p: 20
    },
    rightSide: {
        width: '50%',
        height: 0,
        minHeight: '100%',
        objectFit: 'cover',
        borderRadius: 2
    },
    notFoundContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        gap: 20,
        justifyContent: 'center',
        alignItems: 'center'
    }
}

export default styles
