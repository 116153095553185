import { SxProps, Theme } from '@mui/material'

const styles: { [name: string]: SxProps<Theme> } = {
    input: {
        display: 'flex',
        alignItems: 'flex-start',
        border: 1,
        borderRadius: 2,
        borderColor: '#D5DAE1',
        p: 5,
        pl: 10,
        pr: 10,
        backgroundColor: 'white'
    }
}

export default styles
