import { SxProps, Theme } from '@mui/material'
import { BOLD_FONT_WEIGHT } from '../../utils/constants'

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        gap: 50
    },
    leftContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        width: 400
    },
    titleText: {
        fontSize: 40,
        fontWeight: BOLD_FONT_WEIGHT
    },
    paragraphText: {
        fontSize: 20
    }
}

export default styles
