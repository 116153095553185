import { Box, Typography } from '@mui/material'
import baseStyles from '../styles'
import useLandingPageStorySection from './LandingPageStorySection.hooks'
import { forwardRef } from 'react'
import { paragraph1, paragraph2, titleText } from './LandingPageStorySection.data'
import LandingPageTag from '../LandingPageTag'
import styles from './styles'
import LandingPageButton from '../LandingPageButton'
import { useAppDispatch } from '../../../store/hooks'
import { setCurrentModal } from '../../../store/site'
import { ModalType } from '../../../store/site/data'
import SpeakableImage from '../../SpeakableImage/SpeakableImage'
import { useGetAuthentication } from '../../../store/selectors/authenticationSelector'
import { useNavigate } from 'react-router-dom'

const LandingPageStorySection = (): JSX.Element => {
    const {
        storyRef,
        titleTextVariant,
        containerStyle,
        paragraphVariant,
        displayImage,
        contentContainerStyles,
        isButtonEnabled
    } = useLandingPageStorySection()
    const { isAuthenticated } = useGetAuthentication()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    return (
        <Box ref={storyRef} sx={containerStyle}>
            <Box sx={contentContainerStyles}>
                <LandingPageTag text={'Our Story'} />
                <Typography variant={titleTextVariant} sx={baseStyles.sectionTitleText}>
                    {titleText}
                </Typography>
                <Typography variant={paragraphVariant}>{paragraph1}</Typography>
                <Typography variant={paragraphVariant}>{paragraph2}</Typography>
                <LandingPageButton
                    id={'story-section-button'}
                    text={
                        isButtonEnabled
                            ? isAuthenticated === true
                                ? 'Practice'
                                : 'Get Started'
                            : 'Only Available on Desktop'
                    }
                    onClick={() => {
                        if (isAuthenticated) {
                            navigate('/home')
                        } else {
                            dispatch(setCurrentModal(ModalType.SIGNUP))
                        }
                    }}
                    disabled={!isButtonEnabled}
                />
            </Box>
            {displayImage && (
                <SpeakableImage
                    src={require('../../../assets/images/LandingPage/StorySection/mit.png')}
                    imageSx={styles.image}
                    skeletonSx={{ width: '50%', aspectRatio: 631 / 314, borderRadius: 5 }}
                />
            )}
        </Box>
    )
}

export default forwardRef(LandingPageStorySection)
