import { Box, SxProps, Theme, Typography } from '@mui/material'
import styles from './styles'
import { ISwitchTextProps } from './SwitchText.data'
import useSwitchText from './SwitchText.hooks'

const SwitchText = ({ type, textSx }: ISwitchTextProps): JSX.Element => {
    const { normalText, switchText, onClick } = useSwitchText(type)

    return (
        <Box sx={styles.container}>
            <Typography sx={{ ...styles.normalText, ...textSx } as SxProps<Theme>}>{normalText}</Typography>
            <Typography sx={{ ...styles.switchText, ...textSx } as SxProps<Theme>} onClick={onClick}>
                {switchText}
            </Typography>
        </Box>
    )
}

export default SwitchText
