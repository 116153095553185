import { SxProps, Theme } from '@mui/material'
import { BOLD_FONT_WEIGHT } from '../../utils/constants'

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    text: {
        fontSize: 25,
        fontWeight: BOLD_FONT_WEIGHT,
        textAlign: 'center'
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 20
    }
}

export default styles
