import { Box, Typography } from '@mui/material'
import styles from './styles'
import SpeakableButton from '../../components/SpeakableButton'
import LoadingComponent from '../../components/LoadingWrapper/LoadingComponent'
import useVerifyEmailPage from './VerifyEmailPage.hooks'
import { VerifyEmailStatus } from './VerifyEmailPage.data'

const VerifyEmailPage = (): JSX.Element => {
    const { status, isMobile, isAuthenticated, onGoHome, onContinue } = useVerifyEmailPage()

    return (
        <Box sx={styles.container}>
            {status === VerifyEmailStatus.LOADING || status === undefined ? (
                <LoadingComponent loadingMessage={'Verifying Email'} />
            ) : status === VerifyEmailStatus.SUCCESS ? (
                <Box sx={styles.contentContainer}>
                    <Typography sx={styles.titleText}>{`You've successfully verified your email!`}</Typography>
                    {isMobile ? (
                        <Typography sx={styles.text}>
                            {'Please sign into your account on desktop to get started!'}
                        </Typography>
                    ) : (
                        <SpeakableButton
                            id={'verified-email-get-started'}
                            text={isAuthenticated === true ? 'Get Started' : 'Back to Login'}
                            variant={'important'}
                            disabled={isMobile}
                            onClick={onContinue}
                        />
                    )}
                </Box>
            ) : (
                <Box sx={styles.contentContainer}>
                    <Typography sx={styles.titleText}>
                        {'Could not verify your email. Are you using an old or expired link?'}
                    </Typography>
                    <SpeakableButton
                        id={'failed-verify-email-home'}
                        text={'Go Home'}
                        onClick={onGoHome}
                        variant={'important'}
                    />
                </Box>
            )}
        </Box>
    )
}

export default VerifyEmailPage
