import { Box, Typography } from '@mui/material'
import styles from './styles'
import { forwardRef } from 'react'

interface ILandingPageTagProps {
    text: string
}

const LandingPageTag = ({ text }: ILandingPageTagProps): JSX.Element => {
    return (
        <Box sx={styles.container}>
            <Typography sx={styles.text}>{text}</Typography>
        </Box>
    )
}

export default LandingPageTag
