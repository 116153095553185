import { Box, Typography } from '@mui/material'
import { SectionInformation } from '../LandingPageFeaturesSection.data'
import styles from './styles'
import useFeatureInformation from './FeatureInformation.hooks'

export interface IFeatureInformationProps {
    information: SectionInformation
    ix: number
}

const FeatureInformation = ({ information, ix }: IFeatureInformationProps): JSX.Element => {
    const { blurbStyles, titleStyles, containerStyles } = useFeatureInformation()

    return (
        <Box sx={containerStyles}>
            <Box sx={styles.circle}>
                <Typography sx={styles.number}>{ix}</Typography>
            </Box>
            <Typography sx={titleStyles}>{information.title}</Typography>
            <Typography sx={blurbStyles}>{information.blurb}</Typography>
        </Box>
    )
}

export default FeatureInformation
