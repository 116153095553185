import { useEffect, useState } from 'react'
import { isValidConfirmPassword, isValidEmail, isValidFirstName, isValidLastName, isValidPassword } from '../data'
import { signUp, autoSignIn } from 'aws-amplify/auth'
import { toast } from 'react-toastify'
import { useAppDispatch } from '../../../store/hooks'
import { setCurrentModal } from '../../../store/site'
import useIsMobile from '../../../hooks/useIsMobile'
import { useGetSite } from '../../../store/selectors/siteSelector'

const useSignupComponent = () => {
    const { referrer } = useGetSite()
    const dispatch = useAppDispatch()
    const isMobile = useIsMobile()
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isComplete, setIsComplete] = useState<boolean>(false)

    useEffect(() => {
        setIsComplete(
            isValidFirstName(firstName) &&
                isValidLastName(lastName) &&
                isValidEmail(email) &&
                isValidPassword(password) &&
                isValidConfirmPassword(password, confirmPassword)
        )
    }, [firstName, lastName, email, password, confirmPassword])

    const onSignUp = async () => {
        try {
            setIsLoading(true)
            await signUp({
                username: email,
                password,
                options: {
                    userAttributes: {
                        given_name: firstName,
                        family_name: lastName
                    },
                    autoSignIn: true,
                    clientMetadata: referrer === undefined ? {} : { referrer }
                }
            })
            await autoSignIn()
            dispatch(setCurrentModal(undefined))
        } catch (e: any) {
            let error = 'There was a problem creating your account.'
            switch (e.name) {
                case 'NetworkError':
                    error = 'Please connect to the internet and try again.'
                    break
                case 'UsernameExistsException':
                    error = 'An account with that email already exists.'
                    break
                case 'UserLambdaValidationException':
                    error = 'The email you entered already has a Speakable account through Google.'
                    break
                default:
                    console.log(e.name)
                    break
            }
            toast.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (isMobile) {
            dispatch(setCurrentModal(undefined))
        }
    }, [isMobile])

    return {
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        isLoading,
        isComplete,
        setFirstName,
        setLastName,
        setEmail,
        setPassword,
        setConfirmPassword,
        setIsLoading,
        onSignUp
    }
}

export default useSignupComponent
