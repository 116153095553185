import { useEffect, useState } from 'react'

const useSpeakableImage = () => {
    const [hasLoaded, setHasLoaded] = useState<boolean>(false)
    const [display, setDisplay] = useState<boolean>(true)

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!hasLoaded) setDisplay(false)
        }, 200)

        return () => clearTimeout(timeout)
    }, [hasLoaded])

    return { hasLoaded: display || hasLoaded, setHasLoaded }
}

export default useSpeakableImage
