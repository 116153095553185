import { Box, Typography } from '@mui/material'
import styles from './styles'

const NoMobileComponent = (): JSX.Element => {
    return (
        <Box sx={styles.container}>
            <Box sx={styles.textContainer}>
                <Typography sx={styles.titleText} variant={'h4'}>
                    {'We only offer Speakable on desktop.'}
                </Typography>
                <Typography sx={styles.subtitle} variant={'h5'}>
                    {
                        'If you are on a desktop or laptop, please make your browser window larger for a better experience. Thank you!'
                    }
                </Typography>
            </Box>
        </Box>
    )
}

export default NoMobileComponent
