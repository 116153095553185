import { signOut } from 'aws-amplify/auth'
import { config } from '../../config/config'
import { useGetUser } from '../../store/selectors/userSelector'
import { useNavigate } from 'react-router-dom'
import { useRef } from 'react'
import { tabs as baseTabs } from './ProfilePage.data'
import { createEvent } from '../../api/analytics/api'
import { lazy } from 'react'
const Experimental = lazy(() => import('../../components/ProfilePage/Experimental'))
const Subscription = lazy(() => import('../../components/ProfilePage/Subscription'))
const Dashboard = lazy(() => import('../../components/ProfilePage/Dashboard'))
const Settings = lazy(() => import('../../components/ProfilePage/Settings'))
const Help = lazy(() => import('../../components/ProfilePage/Help'))

const useProfilePage = () => {
    const { firstName } = useGetUser()
    const titleTextRef = useRef<HTMLElement>(null)
    const selectedTab = window.location.pathname.split('/')[2]
    const tabs = config.name === 'experimental' ? [...baseTabs, 'Experimental'] : baseTabs
    const navigate = useNavigate()

    let component: React.LazyExoticComponent<() => JSX.Element>
    switch (selectedTab) {
        case 'dashboard':
            component = Dashboard
            break
        case 'settings':
            component = Settings
            break
        case 'subscription':
            component = Subscription
            break
        case 'account':
            component = Subscription // legacy
            break
        case 'help':
            component = Help
            break
        case 'experimental':
            component = Experimental
            break
        default:
            component = Dashboard
            break
    }

    const onClick = (tab: string): void => {
        createEvent({ eventId: `profile-sidebar-tab-${tab}`, time: new Date().getTime() })
        navigate(`/profile/${tab}`)
    }

    const onClickTOS = () => {
        createEvent({ eventId: `profile-sidebar-terms-of-service`, time: new Date().getTime() })
        window.open(`${config.baseUrl}/terms-of-service`, '_blank')
    }

    const onClickPrivacy = () => {
        createEvent({ eventId: `profile-sidebar-privacy-policy`, time: new Date().getTime() })
        window.open(`${config.baseUrl}/privacy`, '_blank')
    }

    const onClickSignOut = async () => {
        createEvent({ eventId: `profile-sidebar-sign-out`, time: new Date().getTime() })
        signOut()
    }

    return {
        firstName,
        component,
        selectedTab,
        titleTextRef,
        tabs,
        onClick,
        onClickTOS,
        onClickPrivacy,
        onClickSignOut
    }
}

export default useProfilePage
