import { Box, SxProps, Theme, Typography } from '@mui/material'
import styles from './styles'
import useGoogleButton from './GoogleButton.hooks'
import { IGoogleButtonProps } from './GoogleButton.data'

const GoogleButton = ({ sx, disabled, setIsLoading }: IGoogleButtonProps): JSX.Element => {
    const { onClick, containerStyles } = useGoogleButton(disabled, setIsLoading)

    return (
        <Box sx={{ ...containerStyles, ...sx } as SxProps<Theme>} onClick={onClick}>
            <Box component={'img'} src={'/logos/google-icon.svg'} onDragStart={(e) => e.preventDefault()} />
            <Typography sx={styles.text}>{'Continue with Google'}</Typography>
        </Box>
    )
}

export default GoogleButton
