import { Box } from '@mui/material'
import styles from './styles'
import LandingPageProductSection from '../../components/LandingPage/LandingPageProductSection'
import LandingPageFeaturesSection from '../../components/LandingPage/LandingPageFeaturesSection'
import LandingPageStorySection from '../../components/LandingPage/LandingPageStorySection'
import LandingPagePricingSection from '../../components/LandingPage/LandingPagePricingSection'
import LandingPageContactSection from '../../components/LandingPage/LandingPageContactSection'
import LandingPageSidebar from '../../components/LandingPage/LandingPageSidebar/LandingPageSidebar'
import LandingPageFooter from '../../components/LandingPage/LandingPageFooter'
import { useTrackReferrer } from './LandingPage.hooks'

/**
 * Serves as a landing page for users. They will look at this page when they first get to the site,
 * where this page gives them information about what Speakable is. It also offers them the opportunity
 * to sign up/login and contact us.
 */
const LandingPage = (): JSX.Element => {
    void useTrackReferrer()

    return (
        <Box sx={styles.container}>
            <Box sx={styles.contentContainer}>
                <LandingPageProductSection />
                <LandingPageFeaturesSection />
                <LandingPageStorySection />
                <LandingPagePricingSection />
                <LandingPageContactSection />
                <LandingPageFooter />
            </Box>
            <LandingPageSidebar />
        </Box>
    )
}

export default LandingPage
