import { SxProps, Theme } from '@mui/material'

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        display: 'flex',
        backgroundColor: 'white',
        flexDirection: 'column',
        padding: 50,
        gap: 20,
        alignItems: 'center',
        borderRadius: 2
    }
}

export default styles
