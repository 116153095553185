import { SxProps, Theme } from '@mui/material'
import { BOLD_FONT_WEIGHT, GREEN, MOBILE_PAGE_SIDE_PADDING } from '../../../utils/constants'

const styles: { [name: string]: SxProps<Theme> } = {
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '50vw',
        height: '100%',
        gap: 20,
        padding: MOBILE_PAGE_SIDE_PADDING
    },
    unselectedTabText: {
        fontWeight: BOLD_FONT_WEIGHT,
        userSelect: 'none',
        '&:hover': { cursor: 'pointer' }
    },
    selectedTabText: {
        fontWeight: BOLD_FONT_WEIGHT,
        userSelect: 'none',
        '&:hover': { cursor: 'pointer' },
        textUnderlineOffset: 5,
        textDecoration: 'underline',
        textDecorationColor: GREEN,
        textDecorationThickness: 2.5
    },
    closeIcon: {
        alignSelf: 'flex-end',
        '&:hover': { cursor: 'pointer' }
    }
}

export default styles
