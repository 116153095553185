import { useEffect, useState } from 'react'
import AudioPlayer, { StartPlaybackResponse } from '../../utils/classes/audioPlayer'
import { toast } from 'react-toastify'
import { textToSpeech } from '../../api/audio/api'
import { throwSentryException } from '../../utils/sentry'
import Recorder from '../../utils/classes/recorder'
import { createEvent } from '../../api/analytics/api'
import { useGetAuthentication } from '../../store/selectors/authenticationSelector'
import { useIsMounted } from '../../hooks/useIsMounted'

const audioPlayer = AudioPlayer.GetAudioPlayer()
const audioRecorder = Recorder.getRecorder()

const useListenableText = ({ gender, text }: { gender?: 'MALE' | 'FEMALE'; text: string }) => {
    const isMounted = useIsMounted()
    const { isAuthenticated } = useGetAuthentication()
    const [cachedAudio, setCachedAudio] = useState<Blob>()
    const [isLoadingAudio, setIsLoadingAudio] = useState<boolean>(false)
    const [isPlayingAudio, setIsPlayingAudio] = useState<boolean>(false)

    // stop the audio if the component is unmounted
    useEffect(() => {
        if (isPlayingAudio) {
            return () => {
                if (!isMounted()) audioPlayer.stop()
            }
        }
    }, [isPlayingAudio])

    // whenever the text changes, we need to clear the cached audio
    useEffect(() => {
        setCachedAudio(undefined)
    }, [text])

    const onClick = async () => {
        if (audioPlayer.isPlaying || isLoadingAudio || audioRecorder.isRecording) return
        createEvent(
            { eventId: `listenable-text-${text.slice(0, 10)}`, time: new Date().getTime() },
            isAuthenticated === true
        )
        if (cachedAudio !== undefined) {
            const source = URL.createObjectURL(cachedAudio)
            setIsPlayingAudio(true)
            const response = await audioPlayer.play({ source })
            if (response !== StartPlaybackResponse.SUCCESS) {
                throwSentryException({
                    error: `Could not play cached audio from ListenableText. StartPlaybackResponse = ${response}`
                })
                toast.error('Could not play the audio.')
            }
            setIsPlayingAudio(false)
            return
        }

        setIsLoadingAudio(true)
        const audioGender = gender ?? (['MALE', 'FEMALE'][Math.floor(Math.random() * 2)] as 'MALE' | 'FEMALE')
        const { statusCode, body } = await textToSpeech({ text, gender: audioGender })
        setIsLoadingAudio(false)
        if (statusCode === 200) {
            const data = new Uint8Array((body.audio as any).data)
            const blob = new Blob([data], { type: 'audio/mp3' })
            setCachedAudio(blob)
            const source = URL.createObjectURL(blob)
            setIsPlayingAudio(true)
            const response = await audioPlayer.play({ source })
            if (response !== StartPlaybackResponse.SUCCESS) {
                throwSentryException({
                    error: `Could not play cached audio from ListenableText. StartPlaybackResponse = ${response}`
                })
                toast.error('Could not play the audio.')
            }
            setIsPlayingAudio(false)
        } else {
            throwSentryException({ error: `Could not play generated audio from ListenableText.` })
            toast.error('Could not play the audio.')
        }
    }

    return { onClick, isLoadingAudio }
}

export default useListenableText
