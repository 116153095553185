import * as Sentry from '@sentry/react'
import { config } from './config'

export const configure = () => {
    Sentry.init({
        dsn: 'https://ab75f63c57f2cce2036d3533fd9325e5@o4506691089661952.ingest.sentry.io/4506691093856256',
        normalizeDepth: 10,
        integrations: [],
        environment: config.name
    })
}
