import { ApiError, get, put } from 'aws-amplify/api'

export const getGoals = async (): Promise<any> => {
    try {
        const { response: promise } = get({
            apiName: 'goals',
            path: '/'
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as any
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as any
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}

export const updateGoals = async (body: { shouldEmailReminders: boolean; practiceGoal: number }): Promise<any> => {
    try {
        const { response: promise } = put({
            apiName: 'goals',
            path: '/',
            options: { body }
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as any
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as any
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}
