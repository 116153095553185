import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ConversationTag, conversationMap } from '../../store/conversation/data'
import { createEvent } from '../../api/analytics/api'
import { useGetAuthentication } from '../../store/selectors/authenticationSelector'

const useHomePage = () => {
    const [selectedTag, setSelectedTag] = useState<ConversationTag | null>(null)
    const descriptiveNavigate = useNavigate()

    const onClick = async ({ conversationId }: { conversationId: number }) => {
        createEvent({ eventId: 'conversation-picture', time: new Date().getTime() }, true)
        descriptiveNavigate(`/conversation-details/${conversationId}`)
    }

    const candidateConversations = Array.from(conversationMap.entries()).sort(() => Math.random() - 0.5)
    const selectedPrebuiltConversations = candidateConversations.filter((conversation) => {
        if (conversation[0] < 2) return false
        if (!selectedTag) return true
        return conversation[1].tags.includes(selectedTag)
    })

    return {
        onClick,
        selectedPrebuiltConversations,
        selectedTag,
        setSelectedTag
    }
}

export default useHomePage
