import { SxProps, Theme } from '@mui/material'
import useIsMobile from '../../../hooks/useIsMobile'
import { useLandingPageContext } from '../../../pages/LandingPage/LandingPage.hooks'
import useLandingSectionStyling from '../hooks'
import styles from './styles'

const useLandingPageStorySection = () => {
    const { titleTextVariant, containerStyle: baseContainerStyle, paragraphVariant } = useLandingSectionStyling()
    const { storyRef } = useLandingPageContext()
    const isMobile = useIsMobile()

    const containerStyle = { ...baseContainerStyle, gap: 50 }
    const contentContainerStyles = { ...styles.contentContainer, width: isMobile ? '100%' : '50%' } as SxProps<Theme>

    return {
        storyRef,
        titleTextVariant,
        containerStyle,
        paragraphVariant,
        displayImage: !isMobile,
        contentContainerStyles,
        isButtonEnabled: !isMobile
    }
}

export default useLandingPageStorySection
