import { captureException, captureMessage, createReduxEnhancer } from '@sentry/react'
import { config } from '../config/config'

export interface IThrowSentryException {
    error: string
    hint?: any
    includeLocal?: boolean
}

export const sentryReduxEnhancer = createReduxEnhancer()

export const throwSentryException = ({ error, hint, includeLocal }: IThrowSentryException) => {
    if (!includeLocal && config.name === 'local') return
    captureException(error, { data: hint })
}

export interface ICaptureSentryMessage {
    message: string
    includeLocal?: boolean
}

export const captureSentryMessage = ({ message, includeLocal }: ICaptureSentryMessage) => {
    if (!includeLocal && config.name === 'local') return
    captureMessage(message)
}

/**
 * Executes an asynchronous function with the expectation that if the function throws an error, we will
 * produce a sentry error.
 *
 * @param fn The asynchronous function to be executed
 * @param errorMessage The message to be displayed in the Sentry dashboard
 * @returns The result of `fn`
 */
export const asyncSentryWrapper = <T>(
    fn: (...args: any) => Promise<T>,
    errorMessage: string
): ((...args: any) => Promise<T>) => {
    return async (...args: any) => {
        try {
            return await fn(...args)
        } catch (e) {
            throwSentryException({ error: errorMessage, hint: { actualError: e } })
            throw new Error('An unexpected error occurred')
        }
    }
}
