import { SxProps, Theme } from '@mui/material'

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '100%',
        alignItems: 'flex-start',
        justifySelf: 'center'
    },
    text: {},
    input: {
        width: '100%'
    }
}

export default styles
