import { Box, Typography } from '@mui/material'
import styles from './styles'
import SpeakableButton from '../../components/SpeakableButton'
import useVerificationBlockerPage from './VerificationBlockerPage.hooks'

const VerificationBlockerPage = (): JSX.Element => {
    const { resendEmail, onGetStarted, isResending, email, verifiedEmail } = useVerificationBlockerPage()

    return (
        <Box sx={styles.container}>
            {verifiedEmail ? (
                <Box sx={styles.contentContainer}>
                    <Typography sx={styles.titleText}>{`Looks like you've already verified your email!`}</Typography>
                    <SpeakableButton
                        id={'verified-email-blocker-get-started'}
                        text={'Get Started!'}
                        onClick={onGetStarted}
                        variant={'important'}
                    />
                </Box>
            ) : (
                <Box sx={styles.contentContainer}>
                    <Typography sx={styles.titleText}>{'Please verify your email before continuing.'}</Typography>
                    <Typography
                        sx={styles.subtitle}
                    >{`We sent an email to ${email}. It may take a couple of minutes to appear in your inbox.`}</Typography>
                    <Typography sx={styles.subtitle}>{"Didn't receive the email?"}</Typography>
                    <SpeakableButton
                        id={'resend-email'}
                        text={'Resend Email'}
                        onClick={resendEmail}
                        loading={isResending}
                    />
                </Box>
            )}
        </Box>
    )
}

export default VerificationBlockerPage
