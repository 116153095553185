import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { allRoutes, Route as SpeakableRoute } from './router.data'
import { useGetAuthentication } from '../store/selectors/authenticationSelector'
import MobileWrapper from '../wrappers/MobileWrapper'
import PageWrapper from '../wrappers/PageWrapper'
import App from '../app'
import { useGetUser } from '../store/selectors/userSelector'
import LoadingComponent from '../components/LoadingWrapper/LoadingComponent'
import NotAuthorizedPage from '../components/NotAuthorizedComponent/NotAuthorizedComponent'
import { Suspense } from 'react'
import { useGetGoals } from '../store/selectors/goalsSelector'

export type RouteWrapperProps = { children: JSX.Element }

const RequiresAuthenticationRouteWrapper = ({ children }: RouteWrapperProps): JSX.Element => {
    const { isAuthenticated } = useGetAuthentication()

    if (isAuthenticated === true) {
        return children
    } else if (isAuthenticated === undefined) {
        return <LoadingComponent loadingMessage={'Loading your account information'} />
    }

    return <NotAuthorizedPage />
}

const RequiresSubscriptionRouteWrapper = ({ children }: RouteWrapperProps): JSX.Element => {
    const { subscriptionStatus } = useGetUser()

    if (subscriptionStatus === undefined) {
        return <LoadingComponent loadingMessage={'Loading your account information'} />
    }

    if (subscriptionStatus && ['active', 'trialing'].includes(subscriptionStatus)) {
        return children
    }

    return <Navigate to={'/profile/account'} />
}

const RequiresEmailVerificationRouteWrapper = ({ children }: RouteWrapperProps): JSX.Element => {
    const { verifiedEmail } = useGetUser()

    if (verifiedEmail === undefined) {
        return <LoadingComponent loadingMessage={'Loading your account information'} />
    }

    if (verifiedEmail) {
        return children
    }

    return <Navigate to={'/verify-email'} />
}

const RequiresOnboaringRouteWrapper = ({ children }: RouteWrapperProps): JSX.Element => {
    const { completedOnboarding } = useGetUser()

    if (completedOnboarding === undefined) {
        return <LoadingComponent loadingMessage={'Loading your account information'} />
    }

    if (completedOnboarding) {
        return children
    }

    return <Navigate to={'/onboarding'} />
}

const RequiresPracticeGoalRouteWrapper = ({ children }: RouteWrapperProps): JSX.Element => {
    const { shouldEmailReminders, practiceGoal } = useGetGoals()

    if (shouldEmailReminders === undefined) {
        return <LoadingComponent loadingMessage={'Loading your account information'} />
    }

    if (practiceGoal !== undefined) {
        return children
    }

    return <Navigate to={'/practice-goals'} />
}

const RequiresLoadingRouteWrapper = ({
    children,
    useLoad
}: {
    children: JSX.Element
    useLoad: () => boolean
}): JSX.Element => {
    const isReadyToRender = useLoad()

    return isReadyToRender ? children : <LoadingComponent loadingMessage={'Loading your account information'} />
}

const applyWrappers = (route: SpeakableRoute): (() => JSX.Element) => {
    const allWrappers: ((props: RouteWrapperProps) => JSX.Element)[] = []

    if (route.useLoad) {
        allWrappers.push(({ children }: RouteWrapperProps) => {
            if (!route.useLoad) throw new Error()
            return <RequiresLoadingRouteWrapper useLoad={route.useLoad}>{children}</RequiresLoadingRouteWrapper>
        })
    }
    allWrappers.push(({ children }: RouteWrapperProps) => {
        return <PageWrapper navbarVariant={route.navbarVariant}>{children}</PageWrapper>
    })
    if (route.context) {
        allWrappers.push(({ children }: RouteWrapperProps) => {
            if (!route.context) throw new Error()
            return (
                <route.context.wrapper.Provider value={route.context.generator()}>
                    {children}
                </route.context.wrapper.Provider>
            )
        })
    }
    if (route.requiresDesktop) allWrappers.push(MobileWrapper)
    if (route.requiresPracticeGoal) allWrappers.push(RequiresPracticeGoalRouteWrapper)
    if (route.requiresOnboarding) allWrappers.push(RequiresOnboaringRouteWrapper)
    if (route.requiresEmailVerification) allWrappers.push(RequiresEmailVerificationRouteWrapper)
    if (route.requiresSubscription) allWrappers.push(RequiresSubscriptionRouteWrapper)
    if (route.requiresAuthentication) allWrappers.push(RequiresAuthenticationRouteWrapper)

    let Element = () => (
        <Suspense fallback={route.fallback}>
            <route.element />
        </Suspense>
    )
    for (const Wrapper of allWrappers) {
        const ElementCopy = Element
        Element = () => (
            <Wrapper>
                <ElementCopy />
            </Wrapper>
        )
    }

    return Element
}

export const SpeakableRouter = (): JSX.Element => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={<App />}
                    children={allRoutes.map((route, ix) => {
                        const Element = applyWrappers(route)
                        return <Route key={`route ${ix}`} path={route.path} element={<Element />} />
                    })}
                />
            </Routes>
        </BrowserRouter>
    )
}
