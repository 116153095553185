import { SxProps, Theme } from '@mui/material'
import { useLandingPageContext } from '../../../pages/LandingPage/LandingPage.hooks'
import useLandingSectionStyling from '../hooks'
import useIsMobile from '../../../hooks/useIsMobile'
import styles from './styles'

const useLandingPageFeaturesSection = () => {
    const isMobile = useIsMobile()
    const { titleTextVariant, paragraphVariant, containerStyle } = useLandingSectionStyling()
    const informationContainerStyles = {
        ...styles.informationContainer,
        flexDirection: isMobile ? 'column' : 'row'
    } as SxProps<Theme>
    const { featuresRef } = useLandingPageContext()

    return {
        featuresRef,
        titleTextVariant,
        paragraphVariant,
        displayImage: !isMobile,
        containerStyle: { ...containerStyle, gap: 50 } as SxProps<Theme>,
        contentContainerStyles: {
            ...styles.contentContainer,
            width: isMobile ? '100%' : '50%'
        } as SxProps<Theme>,
        informationContainerStyles
    }
}

export default useLandingPageFeaturesSection
