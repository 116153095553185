import { Theme } from '@emotion/react'
import { SxProps } from '@mui/material'
import { BOLD_FONT_WEIGHT, GREEN } from '../../../utils/constants'

const styles: { [name: string]: SxProps<Theme> } = {
    linksRow: {
        display: 'flex',
        flexDirection: 'row',
        gap: 20
    },
    linkText: {
        fontWeight: BOLD_FONT_WEIGHT,
        fontSize: 20,
        userSelect: 'none'
    },
    linkTextContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': { cursor: 'pointer' },
        p: 5,
        pr: 10,
        pl: 10
    },
    selectedLinkText: {
        textUnderlineOffset: 5,
        textDecoration: 'underline',
        textDecorationColor: GREEN,
        textDecorationThickness: 2.5
    }
}

export default styles
