import { Box } from '@mui/material'
import styles from './styles'
import ListenableText from '../../components/ListenableText'
import SpeakableImage from '../../components/SpeakableImage/SpeakableImage'
import useHomePage from './HomePage.hooks'
import { conversationTags } from '../../store/conversation/data'
import SpeakableButton from '../../components/SpeakableButton'
import { allTags, customConversationImageFilepath, prebuiltConversationImageFilepath } from './HomePage.data'

const HomePage = (): JSX.Element => {
    const { onClick, selectedPrebuiltConversations, selectedTag, setSelectedTag } = useHomePage()

    return (
        <Box sx={styles.container}>
            <Box sx={styles.contentContainer}>
                <Box sx={styles.leftContainer}>
                    <ListenableText text={'Custom Conversation'} size={'medium'} textSx={styles.titleText} />
                    <Box sx={styles.customConversationButton} onClick={() => onClick({ conversationId: 1 })}>
                        <SpeakableImage
                            src={customConversationImageFilepath}
                            imageSx={styles.customConversationImage}
                        />
                        <Box sx={styles.textBackground}>
                            <ListenableText
                                textSx={styles.customConversationText}
                                text={'Custom Conversation'}
                                size={'medium'}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box sx={styles.divider} />
                <Box sx={styles.rightContainer}>
                    <ListenableText text={'Prebuilt Conversations'} size={'medium'} textSx={styles.titleText} />
                    <Box sx={styles.tagRow}>
                        {allTags.map((tag) => {
                            const Icon = conversationTags.get(tag)!
                            return (
                                <SpeakableButton
                                    id={`tag-${tag.toLowerCase()}`}
                                    text={tag}
                                    key={tag}
                                    textSxOverride={styles.tagText}
                                    containerSxOverride={styles.tagButton}
                                    variant={selectedTag === tag ? 'important' : 'normal'}
                                    onClick={() =>
                                        setSelectedTag((prev) => (prev === null || tag !== prev ? tag : null))
                                    }
                                    icon={<Icon />}
                                />
                            )
                        })}
                    </Box>
                    <Box sx={styles.conversationScrollable}>
                        {selectedPrebuiltConversations.map(([conversationId, val], ix) => {
                            return (
                                <Box
                                    sx={styles.prebuiltConversationButton}
                                    key={`scenario-${ix}`}
                                    onClick={() => onClick({ conversationId })}
                                >
                                    <SpeakableImage
                                        src={prebuiltConversationImageFilepath(conversationId)}
                                        imageSx={styles.prebuiltImage}
                                    />
                                    <Box sx={styles.textBackground}>
                                        <ListenableText
                                            noWrap={true}
                                            textSx={styles.prebuiltConversationText}
                                            text={val.categoryName ?? val.externalName}
                                            size={'small'}
                                        />
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default HomePage
