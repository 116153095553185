import { Box } from '@mui/material'
import styles from './styles'
import useModalManager from './ModalManager.hooks'

const ModalManager = (): JSX.Element => {
    const { Child, onClose } = useModalManager()

    if (Child === null) return <></>

    return (
        <Box sx={styles.container} onClick={onClose}>
            <Box sx={styles.background} />
            <Box sx={styles.child} onClick={(e) => e.stopPropagation()}>
                <Child />
            </Box>
        </Box>
    )
}

export default ModalManager
