import { SxProps, Theme } from '@mui/material'
import { BLUE_GRAY, BOLD_FONT_WEIGHT, EXTRA_LIGHT_BLUE_GRAY } from '../../../utils/constants'
import { tabTextGap } from './LandingPageNavbar.data'

const styles: { [name: string]: SxProps<Theme> } = {
    tabContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    tabText: {
        fontWeight: BOLD_FONT_WEIGHT,
        userSelect: 'none',
        color: EXTRA_LIGHT_BLUE_GRAY,
        '&:hover': { cursor: 'pointer' }
    },
    selectedTabText: {
        color: 'white'
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: tabTextGap * 2,
        alignItems: 'center'
    },
    burgerIconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5,
        '&:hover': { cursor: 'pointer' }
    },
    burgerIcon: {
        color: 'white'
    },
    buttonRow: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        gap: tabTextGap / 4
    },
    loginText: {
        color: 'white',
        userSelect: 'none',
        borderRadius: 2,
        p: 10,
        pl: 15,
        pr: 15,
        '&:hover': {
            cursor: 'pointer'
        }
    }
}

export default styles
