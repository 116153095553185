import { useEffect } from 'react'
import { useAppDispatch } from '../store/hooks'
import { Hub } from 'aws-amplify/utils'
import { getCurrentUser } from 'aws-amplify/auth'
import { resetUserData, setSubscriptionStatus, setUserID, setUserInfo } from '../store/user'
import { getUser, getUserSettings } from '../api/user/api'
import { resetConversation } from '../store/conversation'
import { getSubscriptionStatus } from '../api/payment/api'
import { setAuthenticationState } from '../store/authentication'
import { useGetAuthentication } from '../store/selectors/authenticationSelector'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { debounce } from '@mui/material'
import { addToSession, getSessionTime } from '../api/analytics/api'
import { useGetUser } from '../store/selectors/userSelector'
import { resetUserSettingsData, setUserSettings } from '../store/userSettings'
import { getStatus } from '../api/status/conversation/api'
import { setConversationStatus } from '../store/site'
import { getGoals } from '../api/goals/api'
import { setGoals, setSessionTime } from '../store/goals'

const useBackgroundTasks = () => {
    const { isAuthenticated } = useGetAuthentication()
    const dispatch = useAppDispatch()

    useEffect(() => {
        const fetchUser = async () => {
            if (isAuthenticated == true) {
                const tasks = [getUser(), getUserSettings(), getGoals()]
                const [getUserResponse, getUserSettingsResponse, getGoalsResponse] = await Promise.all(tasks)
                if (getUserResponse.statusCode !== 200) {
                    toast.error('There was a problem getting your account information. Please refresh and try again!')
                    return
                }
                const { statusCode: getSubscriptionStatusCode, body: getSubscriptionStatusBody } =
                    await getSubscriptionStatus({ customerId: getUserResponse.body.customerId })
                if (getSubscriptionStatusCode === 404) {
                    dispatch(setUserInfo(getUserResponse.body))
                    dispatch(setUserID(getUserResponse.body.userId))
                    dispatch(setSubscriptionStatus('nonexistent'))
                }
                if (getSubscriptionStatusCode === 500) {
                    toast.error('There was a problem getting your account information. Please refresh and try again!')
                    return
                }
                if (getSubscriptionStatusCode === 200) {
                    dispatch(setSubscriptionStatus(getSubscriptionStatusBody.status))
                }

                dispatch(setUserInfo(getUserResponse.body))
                dispatch(
                    setUserSettings({
                        ...getUserSettingsResponse.body,
                        responseLength: parseInt(getUserSettingsResponse.body.responseLength),
                        responseComplexity: parseInt(getUserSettingsResponse.body.responseComplexity),
                        questionFrequency: parseInt(getUserSettingsResponse.body.questionFrequency),
                        allowConversationAnalysis: getUserSettingsResponse.body.allowConversationAnalysis
                    })
                )
                dispatch(setGoals(getGoalsResponse.body))
                dispatch(setUserID(getUserResponse.body.userId))
            }
        }

        void fetchUser()
    }, [isAuthenticated])

    useEffect(() => {
        const getConversationStatus = async () => {
            if (isAuthenticated == true) {
                const { statusCode, body } = await getStatus()
                if (statusCode === 200) {
                    dispatch(setConversationStatus(body.status))
                }
            }
        }

        void getConversationStatus()
    }, [isAuthenticated])
}

const useListenForAuthenticationStateChanges = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const authenticate = () => {
        dispatch(setAuthenticationState(true))
        setTimeout(() => navigate('/home'))
    }

    const unAuthenticate = () => {
        dispatch(resetConversation())
    }

    useEffect(() => {
        const subscribe = Hub.listen('auth', async ({ payload }) => {
            switch (payload.event) {
                case 'signedIn':
                    authenticate()
                    break
                case 'signedOut':
                    unAuthenticate()
                    break
                case 'tokenRefresh':
                    break
                case 'tokenRefresh_failure':
                    break
                case 'signInWithRedirect':
                    break
                case 'signInWithRedirect_failure':
                    break
                case 'customOAuthState':
                    break
            }
        })

        return subscribe
    }, [])
}

/**
 * This hook hydrates statistics data for the profile dashboard periodically. It will fetch this information roughly every 5 minutes.
 */
const useStatisticRefresher = () => {
    const { isAuthenticated } = useGetAuthentication()
    const { userId } = useGetUser()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (isAuthenticated === true && userId !== undefined) {
            const updateSessionTime = async () => {
                const now = new Date()
                const dayOfWeek = now.getDay()
                const daysSinceSunday = (dayOfWeek === 0 ? 7 : dayOfWeek) - 1
                now.setDate(now.getDate() - daysSinceSunday)
                now.setHours(0, 0, 0, 0) // most recent Sunday 12am
                getSessionTime({
                    startTime: now.getTime(),
                    endTime: now.getTime() + 7 * 24 * 60 * 60 * 1_000
                }).then((res) => {
                    dispatch(setSessionTime(res.body.sessionTime))
                })
            }

            const interval = setInterval(updateSessionTime, 5 * 60 * 1_000)
            updateSessionTime()
            return () => clearInterval(interval)
        }
    }, [isAuthenticated, userId])
}

const useSessionTracker = () => {
    const { isAuthenticated } = useGetAuthentication()
    const { userId } = useGetUser()

    // tracks mouse clicks and key presses for users that are authenticated
    useEffect(() => {
        if (isAuthenticated !== true || userId === undefined) return

        const onUserAction = debounce(() => {
            addToSession({ actionTime: new Date().getTime() })
        }, 2.5 * 1000)

        window.addEventListener('mousedown', onUserAction)
        window.addEventListener('keypress', onUserAction)

        // cleanup subscriptions
        return () => {
            window.removeEventListener('mousedown', onUserAction)
            window.removeEventListener('keypress', onUserAction)
        }
    }, [isAuthenticated, userId])
}

const useDetermineAuthenticationState = () => {
    const dispatch = useAppDispatch()

    const determineAuthenticationState = async () => {
        try {
            await getCurrentUser()
            dispatch(setAuthenticationState(true))
        } catch (e) {
            dispatch(setAuthenticationState(false))
        }
    }

    useEffect(() => {
        void determineAuthenticationState()
    }, [])
}

export {
    useDetermineAuthenticationState,
    useBackgroundTasks,
    useSessionTracker,
    useListenForAuthenticationStateChanges,
    useStatisticRefresher
}
