import { Box, Typography } from '@mui/material'
import ListenableText from '../ListenableText'
import SpeakableButton from '../SpeakableButton'
import { useAppDispatch } from '../../store/hooks'
import { setCurrentModal } from '../../store/site'
import styles from './styles'

const PlaybackBlockedComponent = (): JSX.Element => {
    const dispatch = useAppDispatch()

    return (
        <Box sx={styles.container}>
            <Typography sx={{ fontSize: 20 }}>
                {'Your browser has not granted Speakable permission to play sound.'}
            </Typography>
            <SpeakableButton
                id={'playback-blocked-acknowledgement'}
                onClick={() => dispatch(setCurrentModal(undefined))}
                variant="important"
                text={'Try Again'}
            />
        </Box>
    )
}

export default PlaybackBlockedComponent
