import { SxProps, Theme } from '@mui/material'

const styles: { [name: string]: SxProps<Theme> } = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    loadingSpinner: {
        color: 'black',
        mt: 20
    },
    text: {
        textAlign: 'center'
    }
}

export default styles
