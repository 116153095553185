import { modalMap } from './ModalManager.data'
import { useGetSite } from '../../store/selectors/siteSelector'
import { useAppDispatch } from '../../store/hooks'
import { setCurrentModal } from '../../store/site'

const useModalManager = () => {
    const { currentModal } = useGetSite()
    const dispatch = useAppDispatch()

    const onClose = () => {
        dispatch(setCurrentModal(undefined))
    }

    const child = currentModal === undefined ? null : modalMap.get(currentModal) ?? null

    return { Child: child, onClose }
}

export default useModalManager
