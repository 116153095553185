import { Box, Typography } from '@mui/material'
import styles from './styles'
import useLandingPageFooter from './LandingPageFooter.hooks'
import { LinkName, allLinks } from './LandingPageFooter.data'
import { Tab } from '../../../pages/LandingPage/LandingPage.data'
import { SpeakableLogoType } from '../../SpeakableLogo/SpeakableLogo.data'
import SpeakableLogo from '../../SpeakableLogo'

const LandingPageFooter = (): JSX.Element => {
    const { containerStyle, linkCollectionStyles, onTabClick, onPrivacyClick, onTOSClick } = useLandingPageFooter()

    return (
        <Box sx={containerStyle}>
            <Box sx={styles.row}>
                <SpeakableLogo type={SpeakableLogoType.LIGHT} onClick={() => onTabClick('Product')} />
                <Box sx={linkCollectionStyles}>
                    {allLinks.map((linkName: LinkName, ix: number) => {
                        const onClick =
                            linkName === 'Terms of Service'
                                ? onTOSClick
                                : linkName === 'Privacy Policy'
                                  ? onPrivacyClick
                                  : () => onTabClick(linkName as Tab)

                        return (
                            <Typography sx={styles.linkText} key={`footer link ${ix}`} onClick={onClick}>
                                {linkName}
                            </Typography>
                        )
                    })}
                </Box>
            </Box>
            <Typography sx={styles.corporationText}>{'© 2024 Speakable Therapy LLC. All rights reserved.'}</Typography>
        </Box>
    )
}

export default LandingPageFooter
