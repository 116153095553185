import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { completeOnboarding } from '../../api/user/api'
import { toast } from 'react-toastify'
import { useAppDispatch } from '../../store/hooks'
import { setOnboardingState } from '../../store/user'

const useOnboardingPage = () => {
    const [isCompletingOnboarding, setIsCompletingOnboarding] = useState<boolean>(false)
    const [referrer, setReferrer] = useState<string>('')
    const canContinue = referrer !== ''
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const onGetStarted = async () => {
        if (referrer === '') return
        setIsCompletingOnboarding(true)
        const { statusCode } = await completeOnboarding({ selfReportedReferrer: referrer })
        if (statusCode === 201) {
            dispatch(setOnboardingState(true))
            navigate('/home')
        } else {
            toast.error('There was a problem completing your onboarding. Please try again!')
        }
        setIsCompletingOnboarding(false)
    }

    return { onGetStarted, isCompletingOnboarding, referrer, setReferrer, canContinue }
}

export default useOnboardingPage
