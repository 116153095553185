export type PracticeGoalOptionProps = {
    name: string
    option: number | undefined // undefined for custom
    selected: boolean
    borderType: 'straight' | 'top-rounded' | 'bottom-rounded'
    onClick: () => void
}

export const borderRadius = 10

export const getOptionDescription = (practiceMinutes: number) => {
    const hours = Math.floor(practiceMinutes / 60)
    const minutes = practiceMinutes % 60
    let out = ''
    if (hours > 0) {
        const hourText = hours === 1 ? 'hour' : 'hours'
        out += `${hours} ${hourText} `
    }
    if (minutes > 0) {
        const minuteText = minutes === 1 ? 'minute' : 'minutes'
        out += `${minutes} ${minuteText} `
    }
    return out + '/ week'
}
