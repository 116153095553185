import storage from 'redux-persist/es/storage'

export type SliceState = {
    showGUI: boolean
    selectedAvatarId: number | undefined
}

export const initialState: SliceState = {
    showGUI: true, // Whether the user should see the GUI during a conversation
    selectedAvatarId: undefined // The ID of the selected avatar
}

export const experimentalPersistConfig = {
    key: 'experimental',
    storage
}
