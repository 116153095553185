import { BOLD_FONT_WEIGHT, componentShadow } from '../../utils/constants'

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        border: 1,
        borderColor: 'black',
        borderRadius: 5
    },
    text: {
        fontWeight: BOLD_FONT_WEIGHT,
        textAlign: 'center',
        userSelect: 'none'
    },
    loadingSpinner: {
        color: 'white'
    },
    icon: {
        width: 50,
        aspectRatio: 1
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5
    }
}

export default styles
