import { SxProps, Theme } from '@mui/material'
import useLandingSectionStyling from '../hooks'
import { useLandingPageContext } from '../../../pages/LandingPage/LandingPage.hooks'
import { config } from '../../../config/config'
import useIsMobile from '../../../hooks/useIsMobile'
import styles from './styles'
import { useNavigate } from 'react-router-dom'
import { useGetAuthentication } from '../../../store/selectors/authenticationSelector'
import { createEvent } from '../../../api/analytics/api'

const useLandingPageFooter = () => {
    const { isAuthenticated } = useGetAuthentication()
    const { containerStyle } = useLandingSectionStyling()
    const { onTabClick } = useLandingPageContext()
    const isMobile = useIsMobile()
    const navigate = useNavigate()

    const actualStyle = {
        ...containerStyle,
        gap: 10,
        flexDirection: 'column',
        backgroundColor: '#F7F8F9',
        paddingBottom: 100
    } as SxProps<Theme>

    const linkCollectionStyles = isMobile ? styles.linksGrid : styles.linksRow

    const onPrivacyClick = () => {
        createEvent({ eventId: 'landing-page-footer-privacy', time: new Date().getTime() }, isAuthenticated === true)
        window.open(`${config.baseUrl}/privacy`, '_blank')
    }

    const onTOSClick = () => {
        createEvent(
            { eventId: 'landing-page-footer-terms-of-service', time: new Date().getTime() },
            isAuthenticated === true
        )
        window.open(`${config.baseUrl}/terms-of-service`, '_blank')
    }

    return {
        containerStyle: actualStyle,
        linkCollectionStyles,
        onTabClick,
        onPrivacyClick,
        onTOSClick
    }
}

export default useLandingPageFooter
