import { SxProps, Theme } from '@mui/material'
import {
    BOLD_FONT_WEIGHT,
    BRIGHT_GREEN,
    DESKTOP_PAGE_SIDE_PADDING,
    LIGHT_BLUE_GRAY,
    MOBILE_PAGE_SIDE_PADDING
} from '../../../utils/constants'
import BackgroundImage from '../../../assets/images/LandingPage/HomeSection/BackgroundImage.svg'

const desktopPercentText = 50
const desktopGap = 100
const mobileGap = 50
const textGap = 40

const styles: { [name: string]: SxProps<Theme> } = {
    desktopContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: desktopGap,
        width: '100%',
        pt: DESKTOP_PAGE_SIDE_PADDING,
        pb: DESKTOP_PAGE_SIDE_PADDING * 3,
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: `100% calc(100% - 20px)`,
        backgroundRepeat: 'no-repeat',
        minHeight: 550
    },
    mobileContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: `100% calc(100% - 20px)`,
        backgroundRepeat: 'no-repeat',
        gap: mobileGap,
        pt: MOBILE_PAGE_SIDE_PADDING,
        pb: MOBILE_PAGE_SIDE_PADDING
    },
    desktopImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        userSelect: 'none',
        borderRadius: 5
    },
    patternTopImage: {
        userSelect: 'none',
        position: 'absolute',
        translate: '-35% -35%',
        width: '22%',
        top: 0,
        left: 0
    },
    patternBottomImage: {
        userSelect: 'none',
        position: 'absolute',
        bottom: 0,
        right: 0,
        translate: '20% 20%',
        width: '25%'
    },
    mobileInformationContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: textGap
    },
    desktopInformationContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: `calc(${desktopPercentText}% - ${desktopGap / 2}px)`,
        gap: 40
    },
    imageContainer: {
        display: 'flex',
        height: '100%',
        width: `calc(${100 - desktopPercentText}% - ${desktopGap / 2}px)`,
        userSelect: 'none',
        position: 'relative'
    },
    taglineText: {
        fontWeight: BOLD_FONT_WEIGHT,
        lineHeight: 1,
        color: 'white'
    },
    blurbText: {
        lineHeight: 1.3,
        color: LIGHT_BLUE_GRAY
    },
    offerOnDesktopText: {
        fontWeight: BOLD_FONT_WEIGHT,
        userSelect: 'none'
    },
    playButtonContainer: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        translate: '-50% -50%',
        borderRadius: '50%',
        width: '15%',
        aspectRatio: 1,
        backgroundColor: BRIGHT_GREEN,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    playButtonIconFront: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        translate: '-50% -50%',
        color: BRIGHT_GREEN,
        width: '30%',
        height: '30%',
        zIndex: 5
    },
    playButtonIconBack: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        translate: '-50% -50%',
        color: 'white',
        width: '50%',
        height: '50%'
    }
}

export default styles
