import { get, post, put, del, patch, isCancelError, ApiError } from 'aws-amplify/api'
import {
    ClientClosedRequestResponse,
    Message,
    RequestBodySchema,
    RequestQueryParamsSchema,
    ResponseSchema,
    SpeakableAPIGetRequestInput,
    SpeakableAPIResponse
} from './types'
import { isExemptFromResponseBodyValidation, isValidObject, validatePayload } from './validation'
import { InternalServerErrorResponse } from './types'
import { captureSentryMessage, throwSentryException } from '../../utils/sentry'

export const speakableAPIGetRequest = <
    TQueryParams extends RequestQueryParamsSchema,
    TResponse extends ResponseSchema
>({
    validation,
    input
}: Omit<SpeakableAPIGetRequestInput<TQueryParams, TResponse>, 'options'>) => {
    return async ({ options }: Pick<SpeakableAPIGetRequestInput<TQueryParams, TResponse>, 'options'> = {}): Promise<
        SpeakableAPIResponse<TResponse>
    > => {
        // validate the query parameters
        const validationResult = validatePayload({
            queryParams: {
                payload: input.options?.queryParams,
                schema: validation.queryParamsSchema
            }
        })

        if (validationResult !== undefined) {
            // log the error and return a bad request response
            throwSentryException({
                error: validationResult.body.message,
                hint: `Could not validate the payload for method ${input.id}.`,
                includeLocal: false
            })
            return validationResult
        }

        // make the request
        const { response, cancel } = get(input)
        if (options?.abortSignal) {
            options.abortSignal.onabort = () => cancel('Request was cancelled by the user.')
        }

        // add custom error handling to wrap the network request
        const wrappedResponse = await new Promise<
            Awaited<typeof response> | ClientClosedRequestResponse<Message> | InternalServerErrorResponse<Message>
        >(async (resolve) => {
            try {
                resolve(await response)
            } catch (error) {
                if (isCancelError(error)) {
                    // handle cancellation
                    resolve({
                        statusCode: 499,
                        body: { message: error.message }
                    })
                } else if (error instanceof ApiError) {
                    try {
                        if (error.response?.body !== undefined) {
                            // we still want to return the response even if it's considered an error
                            const body = JSON.parse(error.response.body)
                            resolve({ body, statusCode: error.response.statusCode, headers: error.response.headers })
                        }
                    } catch {
                    } finally {
                        // handle unexpected error
                        resolve({
                            statusCode: 500,
                            body: { message: 'Internal Server Error' }
                        })
                    }
                }
            }
        })

        if (isExemptFromResponseBodyValidation(wrappedResponse.statusCode)) {
            throwSentryException({
                error: `Response body is exempt from validation for method ${input.id}. Status code: ${wrappedResponse.statusCode}.`,
                includeLocal: false
            })
            return wrappedResponse
        }

        // validate the response body
        const responseBodyValidationResult = isValidObject(validation.responseBodySchema, {
            body: await (wrappedResponse.body as any).json(),
            statusCode: wrappedResponse.statusCode
        })
        if (!responseBodyValidationResult.success) {
            throwSentryException({
                error: `Invalid response body for method ${input.id}: ${responseBodyValidationResult.error}`,
                includeLocal: false
            })
            return {
                statusCode: 500,
                body: { message: `Invalid response body: ${responseBodyValidationResult.error}` }
            }
        }

        return wrappedResponse
    }
}

export const speakableAPIPostRequest = (input: Parameters<typeof post>[0]): ReturnType<typeof post> => {
    return post(input)
}

export const speakableAPIPutRequest = (input: Parameters<typeof put>[0]): ReturnType<typeof put> => {
    return put(input)
}

export const speakableAPIPatchRequest = (input: Parameters<typeof patch>[0]): ReturnType<typeof patch> => {
    return patch(input)
}

export const speakableAPIDeleteRequest = (input: Parameters<typeof del>[0]): ReturnType<typeof del> => {
    return del(input)
}
