import { ApiError, get, post, put } from 'aws-amplify/api'
import { GetUserResponse, CompleteOnboardingResponse } from '@speakable/speakable-service-contracts/lib/user'

export const getUser = async (): Promise<GetUserResponse> => {
    try {
        const { response: promise } = get({
            apiName: 'user',
            path: '/'
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as GetUserResponse
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as GetUserResponse
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}

export const completeOnboarding = async ({
    selfReportedReferrer
}: {
    selfReportedReferrer: string
}): Promise<CompleteOnboardingResponse> => {
    try {
        const { response: promise } = post({
            apiName: 'user',
            path: '/complete-onboarding',
            options: { body: { selfReportedReferrer } }
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as CompleteOnboardingResponse
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as CompleteOnboardingResponse
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}

// @todo get types for this
export const getUserSettings = async (): Promise<any> => {
    try {
        const { response: promise } = get({
            apiName: 'user',
            path: '/settings'
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as any
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as any
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}

// @todo get types for this
export const updateUserSettings = async (
    data: Partial<{
        questionFrequency: number
        responseLength: number
        responseComplexity: number
        allowConversationAnalysis: boolean
        autoRecordEnabled: boolean
    }>
): Promise<any> => {
    try {
        const { response: promise } = put({
            apiName: 'user',
            path: '/settings',
            options: {
                body: data
            }
        })
        const { body: serializedBody, statusCode } = await promise
        return { body: await serializedBody.json(), statusCode } as any
    } catch (error) {
        try {
            if (error instanceof ApiError) {
                if (error.response) {
                    const { statusCode, body } = error.response
                    return { body, statusCode } as any
                }
            }
            throw new Error()
        } catch (e) {
            return {
                statusCode: 500,
                body: 'Internal Server Error'
            }
        }
    }
}
