import { Theme } from '@emotion/react'
import { SxProps } from '@mui/material'

const styles: { [name: string]: SxProps<Theme> } = {
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20
    },
    form: {}
}

export default styles
