import { Box, Typography } from '@mui/material'
import { BOLD_FONT_WEIGHT } from '../../utils/constants'

export enum BannerType {
    SITE_ISSUE = 'SITE_ISSUE',
    EXPERIMENTAL_CONVERSATION = 'EXPERIMENTAL_MODE'
}

const Banner = ({ bannerType }: { bannerType: BannerType }): JSX.Element => {
    const message =
        bannerType === BannerType.SITE_ISSUE
            ? `We're currently experiencing issues with all conversations. Please try again later!`
            : 'You are in experimental mode. Press "=" to toggle the GUI display.'

    return (
        <Box
            sx={{
                display: 'flex',
                backgroundColor: 'red',
                position: 'fixed',
                left: 0,
                right: 0,
                zIndex: 20,
                p: 5,
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Typography sx={{ color: 'white', fontWeight: BOLD_FONT_WEIGHT }}>{message}</Typography>
        </Box>
    )
}

export default Banner
